/**
 * Resizer Controller
 * 
 * Manages a resizable sidebar with drag handle, toggle functionality, and persistent user preferences.
 * 
 * Example markup:
 * ```html
 * <div class="two-panels-container"
 *   data-controller="resizer"
 *   data-user-preferences="resizer:sidebarWidth resizer:sidebarVisible"
 *   data-resizer-min-width-value="250"
 *   data-resizer-max-width-value="800">
 *   
 *   <!-- Main content -->
 *   <main class="main-content">
 *     <!-- Your main content here -->
 *   </main>
 *   
 *   <!-- Resize handle -->
 *   <div class="resize-handle" 
 *     data-resizer-target="handle" 
 *     data-action="mousedown->resizer#startResize touchstart->resizer#startResize">
 *     
 *     <!-- Toggle icon -->
 *     <div class="toggle-sidebar-icon" 
 *       data-resizer-target="toggleIcon" 
 *       data-action="click->resizer#toggleSidebar">
 *       <!-- SVG icon here -->
 *     </div>
 *   </div>
 *   
 *   <!-- Sidebar -->
 *   <aside class="sidebar-right" data-resizer-target="sidebar">
 *     <!-- Sidebar content here -->
 *   </aside>
 *   
 *   <!-- Restore icon (hidden by default) -->
 *   <div class="restore-sidebar-icon hidden" 
 *     data-resizer-target="restoreIcon" 
 *     data-action="click->resizer#toggleSidebar">
 *     <!-- SVG icon here -->
 *   </div>
 * </div>
 * ```
 * 
 * Required CSS:
 * - `.resize-handle`: Positioned absolutely, handles mouse events
 * - `.toggle-sidebar-icon`: Icon to toggle sidebar visibility
 * - `.restore-sidebar-icon`: Icon to restore sidebar when hidden
 * - `.toggle-hover`: Applied to handle when hovering over toggle icon
 * - `.hide-right-sidebar`: Applied to container when sidebar is hidden
 */
import { Controller } from "@hotwired/stimulus"
import { loadPreferences } from "./user_preferences_controller"

export default class extends Controller {
  static targets = ["handle", "sidebar", "toggleIcon", "restoreIcon"]
  static values = {
    minWidth: { type: Number, default: 250 },
    maxWidth: { type: Number, default: 800 },
    sidebarWidth: { type: Number, default: 300 },
    sidebarVisible: { type: Boolean, default: true }
  }

  /**
   * Initialize the controller and load saved preferences
   */
  initialize() {
    loadPreferences(this)
  }

  /**
   * Set up event listeners and initialize the layout when connected
   */
  connect() {
    // Initialize state
    this.animationFrameId = null
    this.resizing = false
    
    // Bind all handlers at once
    this.boundHandlers = {
      mouseMove: this.handleMouseMove.bind(this),
      mouseUp: this.handleMouseUp.bind(this),
      windowResize: this.handleWindowResize.bind(this),
      keydown: this.handleKeydown.bind(this),
      toggleHover: this.handleToggleIconHover.bind(this),
      toggleLeave: this.handleToggleIconLeave.bind(this),
      forcePosition: this.updateHandlePosition.bind(this)
    }
    
    // Set up event listeners
    window.addEventListener('resize', this.boundHandlers.windowResize)
    document.addEventListener('keydown', this.boundHandlers.keydown)
    
    if (this.hasToggleIconTarget) {
      this.toggleIconTarget.addEventListener('mouseenter', this.boundHandlers.toggleHover)
      this.toggleIconTarget.addEventListener('mouseleave', this.boundHandlers.toggleLeave)
    }
    
    // Initialize layout after DOM is ready
    requestAnimationFrame(() => this.initializeLayout())
  }
  
  /**
   * Initialize the layout with proper dimensions and positioning
   */
  initializeLayout() {
    // Apply saved width preference or use current width
    this.currentWidth = this.hasSidebarWidthValue 
      ? this.sidebarWidthValue 
      : this.sidebarTarget.getBoundingClientRect().width
    
    this.applySidebarWidth(this.currentWidth)
    this.lastSidebarWidth = this.currentWidth
    
    this.setupSidebarObserver()
    this.updateHandlePosition()
    
    // Apply visibility preference
    if (!this.sidebarVisibleValue) {
      this.hideSidebar()
    }
    
    // Handle late layout changes
    window.addEventListener('load', this.boundHandlers.forcePosition)
    setTimeout(this.boundHandlers.forcePosition, 500)
  }
  
  /**
   * Apply sidebar width to DOM elements
   */
  applySidebarWidth(width) {
    document.documentElement.style.setProperty('--sidebar-width-right', `${width}px`)
    this.element.style.gridTemplateColumns = `1fr ${width}px`
    
    // Force a reflow to ensure styles are applied
    void this.element.offsetWidth
  }
  
  updateHandlePosition() {
    if (!this.hasHandleTarget || !this.hasSidebarTarget) return
    
    const sidebarWidth = this.sidebarVisibleValue 
      ? this.sidebarTarget.getBoundingClientRect().width 
      : 0
    
    this.handleTarget.style.right = `${sidebarWidth}px`
  }

  setupSidebarObserver() {
    this.sidebarObserver = new MutationObserver(() => this.updateHandlePosition())
    
    if (this.hasSidebarTarget) {
      this.sidebarObserver.observe(this.sidebarTarget, {
        attributes: true,
        childList: false,
        subtree: false,
        attributeFilter: ['style', 'class']
      })
    }
  }

  disconnect() {
    // Clean up global event listeners
    window.removeEventListener('resize', this.boundHandlers.windowResize)
    document.removeEventListener('keydown', this.boundHandlers.keydown)
    window.removeEventListener('load', this.boundHandlers.forcePosition)
    
    // Clean up resize event listeners
    window.removeEventListener('mousemove', this.boundHandlers.mouseMove)
    window.removeEventListener('mouseup', this.boundHandlers.mouseUp)
    window.removeEventListener('touchmove', this.boundHandlers.mouseMove)
    window.removeEventListener('touchend', this.boundHandlers.mouseUp)
    
    // Clean up toggle icon event listeners
    if (this.hasToggleIconTarget) {
      this.toggleIconTarget.removeEventListener('mouseenter', this.boundHandlers.toggleHover)
      this.toggleIconTarget.removeEventListener('mouseleave', this.boundHandlers.toggleLeave)
    }
    
    if (this.sidebarObserver) {
      this.sidebarObserver.disconnect()
    }
    
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId)
    }
  }

  /**
   * Handles MOD+. (Cmd/Ctrl+.) keyboard shortcut
   */
  handleKeydown(event) {
    const isMod = event.metaKey || event.ctrlKey
    const isPeriod = event.key === '.' || event.keyCode === 190
    
    if (isMod && isPeriod) {
      event.preventDefault()
      this.toggleSidebar()
    }
  }

  startResize(event) {
    event.preventDefault()
    
    this.handleTarget.classList.add('active')
    
    // Store initial state
    this.startX = event.clientX || event.touches[0].clientX
    this.startWidth = this.sidebarTarget.getBoundingClientRect().width
    this.currentWidth = this.startWidth
    this.resizing = true
    
    // Add event listeners
    window.addEventListener('mousemove', this.boundHandlers.mouseMove, { passive: true })
    window.addEventListener('mouseup', this.boundHandlers.mouseUp)
    window.addEventListener('touchmove', this.boundHandlers.mouseMove, { passive: true })
    window.addEventListener('touchend', this.boundHandlers.mouseUp)
    
    // Start animation loop
    this.animationFrameId = requestAnimationFrame(this.updateLayout.bind(this))
    
    // Prevent text selection
    document.body.style.userSelect = 'none'
    document.body.style.cursor = 'col-resize'
  }

  handleMouseMove(event) {
    if (!this.resizing) return
    
    const clientX = event.clientX || (event.touches && event.touches[0].clientX)
    if (!clientX) return
    
    // Calculate new width
    const deltaX = this.startX - clientX
    this.currentWidth = Math.max(
      this.minWidthValue, 
      Math.min(this.maxWidthValue, this.startWidth + deltaX)
    )
  }

  updateLayout() {
    if (!this.resizing) return
    
    this.applySidebarWidth(this.currentWidth)
    this.handleTarget.style.right = `${this.currentWidth}px`
    
    this.animationFrameId = requestAnimationFrame(this.updateLayout.bind(this))
  }

  handleMouseUp() {
    if (!this.resizing) return
    
    // Update state
    this.resizing = false
    this.lastSidebarWidth = this.currentWidth
    this.sidebarWidthValue = this.currentWidth
    
    // Clean up
    cancelAnimationFrame(this.animationFrameId)
    this.animationFrameId = null
    this.handleTarget.classList.remove('active')
    
    // Remove event listeners
    window.removeEventListener('mousemove', this.boundHandlers.mouseMove)
    window.removeEventListener('mouseup', this.boundHandlers.mouseUp)
    window.removeEventListener('touchmove', this.boundHandlers.mouseMove)
    window.removeEventListener('touchend', this.boundHandlers.mouseUp)
    
    // Reset body styles
    document.body.style.userSelect = ''
    document.body.style.cursor = ''
    
    this.updateHandlePosition()
  }

  toggleSidebar() {
    this.sidebarVisibleValue ? this.hideSidebar() : this.showSidebar()
  }

  hideSidebar() {
    // Store current width
    this.lastSidebarWidth = this.sidebarTarget.getBoundingClientRect().width || this.currentWidth
    
    // Update DOM
    this.element.classList.add('hide-right-sidebar')
    this.element.style.gridTemplateColumns = '1fr 0'
    this.sidebarVisibleValue = false
    
    // Update icons
    if (this.hasRestoreIconTarget) this.restoreIconTarget.classList.remove('hidden')
    if (this.hasToggleIconTarget) this.toggleIconTarget.classList.add('sidebar-closed')
    
    this.updateHandlePosition()
  }

  showSidebar() {
    // Update DOM
    this.element.classList.remove('hide-right-sidebar')
    
    // Reset sidebar styles
    this.sidebarTarget.style.width = ''
    this.sidebarTarget.style.minWidth = ''
    this.sidebarTarget.style.maxWidth = ''
    
    // Restore width
    this.applySidebarWidth(this.lastSidebarWidth)
    this.sidebarVisibleValue = true
    
    // Update icons
    if (this.hasRestoreIconTarget) this.restoreIconTarget.classList.add('hidden')
    if (this.hasToggleIconTarget) this.toggleIconTarget.classList.remove('sidebar-closed')
    
    this.updateHandlePosition()
  }

  handleWindowResize() {
    if (!this.resizing && this.sidebarVisibleValue) {
      this.updateHandlePosition()
    }
  }

  handleToggleIconHover() {
    this.handleTarget.classList.add('toggle-hover')
  }

  handleToggleIconLeave() {
    this.handleTarget.classList.remove('toggle-hover')
  }
} 