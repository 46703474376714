import { Controller } from "@hotwired/stimulus"
import { loadPreferences } from "./user_preferences_controller";

/*
  This controller is used to show/hide content.
  How to use:

  You can show or hide the content of the toggle via params.
  Add this data attribute to the element where you define the controller:
    @default-value: true
    @data-attribute: data-toggle-content-opened-value="false"

  Sometimes, you want to update the URL when the toggle is opened or closed.
  Add this data attribute to the element where you define the controller:
    @default-value: false
    @data-attribute: data-toggle-update-url-value="true"

  Add this data attribute to the container to be shown/hidden (allow multiple containers):
    @data-attribute: data-toggle-target="toggleContainer"

  If you want the container to be hidden when you click outside of it you need this:
    @data-attribute: data-action="click@window->toggle#toggleClickOutSide"

  Add this data attribute to the button that you want to trigger the action:
    @data-attribute: data-action="click->toggle#toggle"

  In case we would have multiple containers, you could use the "filter" param
  (along with the data-action) to identify which element is triggering the action:
    @data-attribute: data-toggle-filter-param="cpvs"

  You will need to specify then, which target container is this "filter" param referring to,
  setting the following data-attribute alongside the data-toggle-target="toggleContainer":
    data-filter="cpvs"


  Markup:
  <article
    data-controller="toggle"
    id="sandbox"
  >
    <div
      data-toggle-target="toggleContainer"
      //optional
      data-action="click@window->toggle#toggleClickOutSide"
    >
      <p>Content</p>
    </div>

    //Sometimes you'll need an <a>, it's not right but could you use it.
    <button
      type="button"
      data-action="click->toggle#toggle"
    >
      Click
    </button>
  </article>

You can add a caret-icon to the button

  Markup:
  <article
    data-controller="toggle"
    id="sandbox"
  >
   <span data-action="click->toggle#toggle" class="cursor-pointer hover:font-bold">
     <%= inline_svg_pack_tag('icons/outline/chevron-right.svg', class:'icon-svg w-4 mr-1 text-gray-400 transition-transform') %>
     Mostrar el contenido
   </span>
   <div
     data-toggle-target="toggleContainer"
     class="mt-4 p-2 mb-2"
   >
     <p>Este es el contenido del toggleContainer</p>
   </div>

  </article>

  These examples are available in the sandbox
*/

export default class extends Controller {
  static targets = ["toggleContainer", "button", "focusItem"]

  static values = {
    updateUrl: {
      type: Boolean,
      default: false
    },
    contentOpened: {
      type: Boolean,
      default: true
    }
  }

  initialize() {
    // if there's no toggle action, this controller won't do nothing
    // https://stimulus.hotwired.dev/reference/lifecycle-callbacks#disconnection
    if (!this.element.querySelector("[data-action*=toggle]")) {
      this.element.dataset.controller = `${this.identifier}---disconnected`
      return null
    }

    loadPreferences(this)
  }

  connect() {
    const { search } = window.location;

    // Show a warning when HTMLElement has no id tag
    if (this.hasUpdateUrlValue && !this.element.id) {
      return console.warn("Add an id to this HTMLElement in order to update the URL", this.element);
    }

    // Listen for toggle:show events
    this.boundHandleToggleShow = this._handleToggleShow.bind(this)
    this.element.addEventListener('toggle:show', this.boundHandleToggleShow)

    // if the url queryParams contains the toggle_id or it's enforced to open, display the containers
    // (besides, enforces idValue to be something different from "")
    if ((this.element.id && search.includes(this.element.id)) || this.contentOpenedValue) {
      return this.toggleContainerTargets.forEach((element) => this._open(element));
    }

    // if it's enforced to close, hide the containers
    if (!this.contentOpenedValue) {
      return this.toggleContainerTargets.forEach((element) => this._close(element));
    }
  }

  disconnect() {
    if (this.boundHandleToggleShow) {
      this.element.removeEventListener('toggle:show', this.boundHandleToggleShow)
    }
  }

  /**
   * Handles the toggle:show event to open a specific toggle container
   * @private
   */
  _handleToggleShow(event) {
    const { container, button } = event.detail;

    // Open the specified container
    if (container && this.toggleContainerTargets.includes(container)) {
      this._open(container, button || this.element.querySelector("[data-toggle-target='button']"))

      // Update URL params if needed
      this._updateUrlParams()

      // Focus if needed
      if (this.hasFocusItemTarget) {
        this.focusItemTarget.focus()
      }
    }
  }

  toggle(event) {
    event.preventDefault();

    // if the event.params contains "filter", the targeted containers must match with such value
    const containers = event.params?.filter
      ? this.toggleContainerTargets.filter(element => element.dataset.filter === event.params.filter)
      : this.toggleContainerTargets;

    containers.forEach(element => {
      if (element.style.display === "none") {
        this._open(element, event.currentTarget)
      } else {
        this._close(element, event.currentTarget)
      }
    })

    this._updateUrlParams()

    if (this.hasFocusItemTarget) {
      this.focusItemTarget.focus();
    }
  }

  toggleClickOutSide(event) {
    const { target: { tagName, parentElement }, target, explicitOriginalTarget } = event

    /*Mini-browser:Turbo streams create an <form><input/></form> and place them outside
      of the toggleContainer. To avoid closing it if a user clicks on the input,
      we need to check if the user clicks on it and if its father is a FORM.*/
    const inputTargetTurboStreams = tagName === "INPUT" && parentElement.tagName === "FORM";
    if (
      this.toggleContainerTarget.contains(target) ||
      this.buttonTarget.contains(target) ||
      this.toggleContainerTarget.contains(explicitOriginalTarget) ||
      this.buttonTarget.contains(explicitOriginalTarget) ||
      inputTargetTurboStreams
    ) {
      return;
    }

    this.toggleContainerTargets.forEach(element => this._close(element, event.currentTarget))
  }

  _updateUrlParams() {
    if (!this.updateUrlValue) return

    const { search, pathname } = window.location;
    const params = new URLSearchParams(search)

    if (params.has("toggle-opened")) {
      params.remove("toggle-opened")
    } else {
      params.append("toggle-opened", this.element.id)
    }

    history.replaceState(null, null, `${pathname}?${params.toString()}`);
  }

  _open(element, trigger = this.element.querySelector("[data-action*=toggle]")) {
    element.style.display = null
    element.ariaHidden = false
    trigger.ariaExpanded = true

    this.contentOpenedValue = true
  }

  _close(element, trigger = this.element.querySelector("[data-action*=toggle]")) {
    element.style.display = "none"
    element.ariaHidden = true
    trigger.ariaExpanded = false

    this.contentOpenedValue = false
  }
}
