import { Extension } from '@tiptap/core'
import Suggestion from '@tiptap/suggestion'
import { ReactRenderer } from '@tiptap/react'
import { PluginKey } from '@tiptap/pm/state'
import tippy from 'tippy.js/headless'
import VariableSuggestions from '../components/VariableSuggestions'

const variableSuggestionPluginKey = new PluginKey('variableSuggestion')

export default Extension.create({
  name: 'variableSuggestion',

  addOptions() {
    return {
      variables: [],
      suggestion: {
        char: '{{',
        allowSpaces: true,
        startOfLine: false,
        pluginKey: variableSuggestionPluginKey,
        decorationClass: 'variable-suggestion-active',
        command: ({ editor, range, props }) => {
          editor
            .chain()
            .focus()
            .deleteRange(range)
            .insertContent([
              {
                type: 'variable',
                attrs: {
                  id: props.key,
                  label: props.label,
                  value: props.value
                }
              },
              { type: 'text', text: ' ' }
            ])
            .run()
        },
        allow: ({ editor, range }) => {
          return editor.can().deleteRange(range)
        },
        items: ({ query }) => {
          const cleanQuery = query.trim()
          const variables = this.options.variables || []
          
          return variables.filter(variable => 
            variable.label.toLowerCase().includes(cleanQuery.toLowerCase()) ||
            variable.key.toLowerCase().includes(cleanQuery.toLowerCase())
          )
        },
        render: () => {
          let component
          let popup

          return {
            onStart: props => {
              component = new ReactRenderer(VariableSuggestions, {
                props: {
                  ...props,
                  clientRect: props.clientRect
                },
                editor: props.editor,
              })

              if (!component.element) return
            },
            onUpdate(props) {
              if (!component?.updateProps) return
              component.updateProps({
                ...props,
                clientRect: props.clientRect
              })
            },
            onKeyDown(props) {
              if (!component?.ref?.onKeyDown) return false
              return component.ref.onKeyDown(props)
            },
            onExit() {
              if (component?.destroy) component.destroy()
            },
          }
        },
      }
    }
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ]
  },
}) 