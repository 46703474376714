import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  connect() {
    // Save reference to the controller to use in the event
    const controller = this

    // Listen to the popstate event (when using the back button)
    window.addEventListener("popstate", this._handlePopState.bind(this))
  }

  disconnect() {
    // Clean up the event listener when the controller is disconnected
    window.removeEventListener("popstate", this._handlePopState.bind(this))
  }

  _handlePopState(event) {
    // When the back button is pressed, force a full page reload
    // instead of relying on Turbo navigation
    const currentUrl = window.location.href

    // Temporarily disable Turbo for this navigation
    Turbo.session.drive = false

    // Reload the current page
    window.location.href = currentUrl

    // Reactivate Turbo after a brief delay
    setTimeout(() => {
      Turbo.session.drive = true
    }, 100)
  }
}
