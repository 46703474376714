import { useCallback, useEffect } from 'react'

/**
 * Custom hook for handling general content insertions in the editor
 * 
 * @param {Object} editor - TipTap editor instance
 * @returns {Object} Event handlers for content insertions
 */
export const useContentInsertions = (editor) => {
  const handleContentInsert = useCallback((event) => {
    const { content, isHTML, range, replaceRange } = event.detail;
    
    console.log("Content insertion event received:", {
      content: typeof content === 'string' ? content.slice(0, 100) + "..." : JSON.stringify(content).slice(0, 100) + "...",
      isHTML,
      range,
      replaceRange
    });

    if (!editor || !content) {
      console.error("Missing editor or content");
      return;
    }

    try {
      // If we have a valid range and should replace, delete that content first
      if (replaceRange && range && typeof range.from === 'number' && typeof range.to === 'number') {
        console.log("Replacing content in range:", range);
        
        // Delete the existing content
        editor.commands.deleteRange(range);
        
        // Insert the new content at the range position
        if (isHTML) {
          editor.commands.insertContent(content, {
            parseOptions: {
              preserveWhitespace: true,
              preserveNewlines: true,
              HTMLAttributes: {
                // Preserve any HTML attributes
                class: true,
                style: true
              }
            }
          });
        } else {
          const node = editor.schema.nodeFromJSON(content);
          editor.commands.insertContentAt(range.from, node);
        }
      } else {
        // If no range to replace, just insert at current position
        if (isHTML) {
          editor.commands.insertContent(content, {
            parseOptions: {
              preserveWhitespace: true,
              preserveNewlines: true,
              HTMLAttributes: {
                // Preserve any HTML attributes
                class: true,
                style: true
              }
            }
          });
        } else {
          const node = editor.schema.nodeFromJSON(content);
          editor.commands.insertContent(node);
        }
      }
      
      console.log("Content insertion completed");
    } catch (error) {
      console.error("Error during content insertion:", error, "\nContent was:", content);
    }
  }, [editor])

  useEffect(() => {
    console.log("React Hook: Setting up editor-insert-content listener")
    window.addEventListener('editor-insert-content', handleContentInsert)
    
    return () => {
      console.log("React Hook: Cleaning up editor-insert-content listener")
      window.removeEventListener('editor-insert-content', handleContentInsert)
    }
  }, [handleContentInsert])

  return {
    handleContentInsert
  }
} 