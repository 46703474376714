import React, { useState } from 'react'
import { BubbleMenu } from '@tiptap/react'
import {
  ArrowPathIcon,
  BoltIcon,
  ScissorsIcon,
  XMarkIcon,
  TrashIcon,
  WindowIcon
} from '@heroicons/react/24/outline'

// Custom table operation icons following Heroicons style
const TableRowIcon = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={className}>
    <path strokeLinecap="round" strokeLinejoin="round" d="M3 10h18M3 14h18" />
  </svg>
)

const TableColumnIcon = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={className}>
    <path strokeLinecap="round" strokeLinejoin="round" d="M9 3v18m6-18v18" />
  </svg>
)

const TableIcon = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={className}>
    <path strokeLinecap="round" strokeLinejoin="round" d="M3 10h18M3 14h18M9 3v18m6-18v18" />
  </svg>
)

const AddRowBeforeIcon = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={className}>
    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4V2m0 2v2m0-2h2m-2 0H10M3 10h18M3 14h18" />
  </svg>
)

const AddRowAfterIcon = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={className}>
    <path strokeLinecap="round" strokeLinejoin="round" d="M12 20v-2m0 2v2m0-2h2m-2 0H10M3 10h18M3 14h18" />
  </svg>
)

const AddColumnBeforeIcon = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={className}>
    <path strokeLinecap="round" strokeLinejoin="round" d="M4 12H2m2 0h2m-2 0v2m0-2v-2M9 3v18m6-18v18" />
  </svg>
)

const AddColumnAfterIcon = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={className}>
    <path strokeLinecap="round" strokeLinejoin="round" d="M20 12h2m-2 0h-2m2 0v2m0-2v-2M9 3v18m6-18v18" />
  </svg>
)

const DeleteRowIcon = ({ className }) => (
  <div className="relative">
    <TableRowIcon className={className} />
    <XMarkIcon className={`w-2.5 h-2.5 absolute -top-1 -right-1 ${className?.replace('w-4 h-4', '')}`} />
  </div>
)

const DeleteColumnIcon = ({ className }) => (
  <div className="relative">
    <TableColumnIcon className={className} />
    <XMarkIcon className={`w-2.5 h-2.5 absolute -top-1 -right-1 ${className?.replace('w-4 h-4', '')}`} />
  </div>
)

const DotIcon = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={className}>
    <circle cx="12" cy="12" r="5" />
  </svg>
)

const TableBubbleMenu = ({ editor }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [barHeight, setBarHeight] = useState(0)

  if (!editor) {
    return null
  }

  const getIconComponent = (operation) => {
    const className = `w-4 h-4 ${
      operation.variant === 'danger' 
        ? 'text-gray-400 group-hover:text-red-500' 
        : 'text-gray-500 group-hover:text-blue-500'
    }`

    if ([DeleteRowIcon, DeleteColumnIcon].includes(operation.icon)) {
      const Icon = operation.icon
      return <Icon className={className} />
    }

    const Icon = operation.icon
    return <Icon className={className} />
  }

  const tableOperations = [
    {
      icon: AddRowBeforeIcon,
      title: 'Insertar fila arriba',
      action: () => editor.chain().focus().addRowBefore().run(),
    },
    {
      icon: AddRowAfterIcon,
      title: 'Insertar fila abajo',
      action: () => editor.chain().focus().addRowAfter().run(),
    },
    {
      icon: AddColumnBeforeIcon,
      title: 'Insertar columna izquierda',
      action: () => editor.chain().focus().addColumnBefore().run(),
    },
    {
      icon: AddColumnAfterIcon,
      title: 'Insertar columna derecha',
      action: () => editor.chain().focus().addColumnAfter().run(),
    },
    {
      type: 'separator'
    },
    {
      icon: DeleteRowIcon,
      title: 'Eliminar fila',
      action: () => editor.chain().focus().deleteRow().run(),
      variant: 'danger'
    },
    {
      icon: DeleteColumnIcon,
      title: 'Eliminar columna',
      action: () => editor.chain().focus().deleteColumn().run(),
      variant: 'danger'
    },
    {
      type: 'separator'
    },
    {
      icon: WindowIcon,
      title: 'Alternar cabecera',
      action: () => editor.chain().focus().toggleHeaderRow().run(),
    },
    {
      icon: BoltIcon,
      title: 'Combinar celdas',
      action: () => editor.chain().focus().mergeCells().run(),
      isVisible: () => editor.can().mergeCells(),
    },
    {
      icon: ScissorsIcon,
      title: 'Dividir celda',
      action: () => editor.chain().focus().splitCell().run(),
      isVisible: () => editor.can().splitCell(),
    },
    {
      icon: TrashIcon,
      title: 'Eliminar tabla',
      action: () => editor.chain().focus().deleteTable().run(),
      variant: 'danger'
    },
  ]

  return (
    <BubbleMenu
      className={`flex items-center gap-0.5 rounded-lg ${isExpanded ? 'bg-white shadow-lg border border-gray-200 p-1' : 'bg-transparent'}`}
      tippyOptions={{
        duration: 100,
        placement: 'right',
        offset: [0, -2],
        // interactive: false,
        hideOnClick: true,
        getReferenceClientRect: () => {
          const selection = window.getSelection()
          const node = selection?.anchorNode
          const rowElement = node?.parentElement?.closest('tr')
          
          if (rowElement) {
            const rect = rowElement.getBoundingClientRect()
            setBarHeight(rect.height)
            return {
              width: 0,
              height: rect.height,
              left: rect.left,
              right: rect.left,
              top: rect.top,
              bottom: rect.bottom,
            }
          }
          
          const range = selection?.getRangeAt(0)
          return range?.getBoundingClientRect()
        }
      }}
      editor={editor}
      shouldShow={({ editor }) => editor.isActive('table')}
      onHide={() => setIsExpanded(false)}
    >
      {!isExpanded ? (
        <button
          onClick={() => setIsExpanded(true)}
          style={{ height: `${barHeight}px` }}
          className="w-2 bg-blue-500 hover:bg-blue-600 transition-colors absolute -left-2"
          title="Opciones de tabla"
        />
      ) : (
        <>
          <button
            onClick={() => setIsExpanded(false)}
            className="p-1 hover:bg-gray-100 rounded-md group"
            title="Cerrar menú"
          >
            <XMarkIcon className="w-4 h-4 text-gray-500 group-hover:text-blue-500" />
          </button>
          <div className="w-px h-4 bg-gray-200 mx-0.5" />
          {tableOperations.map((operation, index) => (
            operation.type === 'separator' ? (
              <div key={index} className="w-px h-4 bg-gray-200 mx-0.5" />
            ) : (
              operation.isVisible?.(editor) !== false && (
                <button
                  key={index}
                  onClick={(e) => {
                    operation.action()
                    // Keep menu open after action
                    e.stopPropagation()
                  }}
                  className={`p-1 hover:bg-gray-100 rounded-md group ${
                    operation.variant === 'danger' ? 'hover:bg-red-50' : ''
                  }`}
                  title={operation.title}
                >
                  {getIconComponent(operation)}
                </button>
              )
            )
          ))}
        </>
      )}
    </BubbleMenu>
  )
}

export default TableBubbleMenu 