// This controller is being used in the sandbox for the writer.
// TODO:
//   - review if it's necessary to use this controller
//   - review the name

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "actions", "originalContent", "proposedContent", "editButton", "proposalActions", "cancelButton", "frame"]

  static values = {
    nodeId: String,
    state: { type: String, default: "content" }, // empty, content, editing
    originalCatalogClauseId: String,
    modifiedStatus: { type: Boolean, default: false }
  }

  static classes = {
    editing: "editing",
    modified: "modified",
    empty: "empty",
    actionsVisible: "actions-visible"
  }

  initialize() {
    this._initializeState()
    this._bindAssistantEvents()
  }

  connect() {
    // Set initial state classes
    this._updateUIState()
  }

  // Public actions
  toggleEdit() {
    if (this.stateValue === "editing") {
      this.stateValue = "content"
      this.proposalActionsTarget.classList.add('hidden')
    } else {
      this.stateValue = "editing"
      this._expandAssistantPanel()
      this._scrollIntoView()
      this._loadAssistantConversation()
    }
    this._updateUIState()
  }

  acceptProposal() {
    // Store the original content for undo
    const originalContent = this.contentTarget.innerHTML

    // Update content
    const proposedContent = this.proposedContentTarget.innerHTML
    this.contentTarget.innerHTML = proposedContent
    this.modifiedStatusValue = true

    // Hide proposed content and show content
    this.proposedContentTarget.classList.add('hidden')
    this.contentTarget.classList.remove('hidden')

    // Show undo button in actions
    this._showUndoButton(() => this._undoAcceptedChanges(originalContent))

    this._updateUIState()
    this._showNotification("Cambios aceptados", "success")
  }

  rejectProposal() {
    // Store the proposed content for undo
    const proposedContent = this.proposedContentTarget.innerHTML

    // Restore original content
    this.contentTarget.innerHTML = this.originalContentTarget.innerHTML
    this.modifiedStatusValue = false

    // Hide proposed content and show content
    this.proposedContentTarget.classList.add('hidden')
    this.contentTarget.classList.remove('hidden')

    // Clear and show undo button (this will remove the accept/reject buttons)
    this._showUndoButton(() => this._undoRejectedChanges(proposedContent))

    this._updateUIState()
    this._showNotification("Cambios rechazados", "error")
  }

  cancelEdit() {
    this.stateValue = "content"
    this._updateUIState()
  }

  testProposal() {
    // Hide original content and current content
    this.contentTarget.classList.add('hidden')
    this.originalContentTarget.classList.add('hidden')

    // Show proposed content
    this.proposedContentTarget.classList.remove('hidden')

    // Simulate receiving a proposal from the assistant
    document.dispatchEvent(new CustomEvent("assistant:proposal", {
      detail: {
        nodeId: this.nodeIdValue,
        content: `<p>${this._getRandomContent()}</p>
                 <p>${this._getRandomContent()}</p>
                 <p>${this._getRandomContent()}</p>`
      }
    }))
  }

  // Private methods

  _initializeState() {
    // Check if node is empty
    const content = this.contentTarget.textContent.trim()
    if (!content) {
      this.stateValue = "empty"
    }
  }

  _undoAcceptedChanges(originalContent) {
    this.contentTarget.innerHTML = originalContent
    this.modifiedStatusValue = false
    this._updateUIState()
    this._showNotification("Cambios deshechos", "info")
    this.proposalActionsTarget.classList.add('hidden')
  }

  _showUndoButton(undoCallback) {
    // Clear previous proposal actions
    this.proposalActionsTarget.innerHTML = ''

    // Create undo button
    const undoButton = document.createElement("button")
    undoButton.textContent = "Deshacer cambios"
    undoButton.className = ""
    undoButton.onclick = (e) => {
      e.preventDefault()
      undoCallback()
      this.proposalActionsTarget.classList.add('hidden')
    }

    this.proposalActionsTarget.appendChild(undoButton)
    this.proposalActionsTarget.classList.remove('hidden')
  }

  _bindAssistantEvents() {
    // Listen for proposals from assistant
    document.addEventListener("assistant:proposal", (event) => {
      if (this.stateValue === "editing" && event.detail.nodeId === this.nodeIdValue) {
        this._handleProposal(event.detail.content)
      }
    })
  }

  _handleProposal(proposalContent) {
    // Hide original content and current content
    this.contentTarget.classList.add('hidden')
    this.originalContentTarget.classList.add('hidden')

    // Show and update proposed content
    this.proposedContentTarget.classList.remove('hidden')
    this.proposedContentTarget.innerHTML = proposalContent
    this.modifiedStatusValue = true

    // Update UI and show proposal actions
    this._updateUIState()
    this._showProposalActions()
  }

  _updateUIState() {
    // Remove all state classes
    this.element.classList.remove(
      this.classes.editing,
      this.classes.modified,
      this.classes.empty
    )

    // Add current state class
    this.element.classList.add(this.classes[this.stateValue])

    // Update modified status
    if (this.modifiedStatusValue) {
      this.element.classList.add(this.classes.modified)
    }

    // Keep actions visible when editing
    if (this.stateValue === "editing") {
      this.actionsTarget.classList.add(this.classes.actionsVisible)
    } else {
      this.actionsTarget.classList.remove(this.classes.actionsVisible)
    }

    // Update ARIA attributes
    this.element.setAttribute("aria-busy", this.stateValue === "editing")
    this.element.setAttribute("aria-modified", this.modifiedStatusValue)

    // Update edit button text and state based on editing mode
    if (this.stateValue === "editing") {
      this.editButtonTarget.textContent = "Cerrar"
      this.editButtonTarget.classList.add('text-red-600')
      this._disableOtherNodes()
      // Hide proposal actions until we have a proposal
      this.proposalActionsTarget.classList.add('hidden')
    } else {
      this.editButtonTarget.textContent = "Editar"
      this.editButtonTarget.classList.remove('text-red-600')
      this._enableOtherNodes()
    }

    // Show proposal actions only when we have a proposal
    if (this.modifiedStatusValue && this.stateValue === "editing") {
      this.proposalActionsTarget.classList.remove('hidden')
    }
  }

  _expandAssistantPanel() {
    // Dispatch an event instead of directly calling the layout controller
    const event = new CustomEvent("redactor:expandRightPanel")
    window.dispatchEvent(event)
  }

  _scrollIntoView() {
    // Smooth scroll to the node
    this.element.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  }

  _disableOtherNodes() {
    // First, find the parent editor
    const editor = this.element.closest('.redactor-text-editor')
    if (editor) {
      // Store the original contenteditable state
      editor.dataset.wasEditable = editor.getAttribute('contenteditable')
      // Temporarily disable contenteditable on the editor
      editor.setAttribute('contenteditable', 'false')
    }

    // Then handle the visual disabled state for other nodes
    const allNodes = document.querySelectorAll('.node')
    allNodes.forEach(node => {
      if (node !== this.element) {
        node.classList.add('disabled-node')
      }
    })

    // Re-enable contenteditable just for the active node
    this.element.setAttribute('contenteditable', 'true')
  }

  _enableOtherNodes() {
    // Restore the editor's contenteditable state
    const editor = this.element.closest('.redactor-text-editor')
    if (editor && editor.dataset.wasEditable) {
      editor.setAttribute('contenteditable', editor.dataset.wasEditable)
      delete editor.dataset.wasEditable
    }

    // Remove disabled state from nodes
    const allNodes = document.querySelectorAll('.node')
    allNodes.forEach(node => {
      node.classList.remove('disabled-node')
    })
  }

  _loadAssistantConversation() {
    const url = new URL(window.location.origin + '/sandbox/tus-documentos-v1/sidebar_tools/sidebar_asistente')
    const conversation = this.editButtonTarget.dataset.assistantConversation
    url.searchParams.set('conversation', conversation)

    // Dispatch event for the assistant sidebar to handle
    const event = new CustomEvent("assistant:loadConversation", {
      detail: { url: url.toString() },
    })
    window.dispatchEvent(event)
    console.log(event);
  }

  _showNotification(message, type = "info") {
    const event = new CustomEvent("notification:show", {
      detail: {
        message,
        type, // success, error, info, warning
        duration: 3000 // 3 seconds
      }
    })
    window.dispatchEvent(event)
  }

  _getRandomContent() {
    const contents = [
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris. Duis aute irure dolor in reprehenderit in voluptate velit esse.",
      "Excepteur sint occaecat cupidatat non proident, sunt in culpa. Nulla facilisi. Mauris sollicitudin fermentum libero. Excepteur sint occaecat cupidatat non proident, sunt in culpa. Nulla facilisi. Mauris sollicitudin fermentum libero.",
      "Vestibulum ante ipsum primis in faucibus orci luctus. Fusce vulputate eleifend sapien. Vestibulum purus quam. Vestibulum ante ipsum primis in faucibus orci luctus. Fusce vulputate eleifend sapien. Vestibulum purus quam.",
      "Phasellus dolor. Maecenas vestibulum mollis diam. Pellentesque dapibus hendrerit tortor. Phasellus dolor. Maecenas vestibulum mollis diam. Pellentesque dapibus hendrerit tortor."
    ]

    return contents[Math.floor(Math.random() * contents.length)]
  }

  _undoRejectedChanges(proposedContent) {
    // Show proposed content and hide original content
    this.contentTarget.classList.add('hidden')
    this.proposedContentTarget.classList.remove('hidden')

    // Restore the proposed content
    this.proposedContentTarget.innerHTML = proposedContent
    this.modifiedStatusValue = true

    // Update UI state and show notification
    this._updateUIState()
    this._showNotification("Cambios restaurados", "info")

    // Show accept/reject buttons again
    this._showProposalActions()
  }

  _showProposalActions() {
    // Clear previous actions
    this.proposalActionsTarget.innerHTML = ''

    // Create reject button
    const rejectButton = document.createElement("button")
    rejectButton.textContent = "Rechazar cambios"
    rejectButton.className = "button button-xs"
    rejectButton.onclick = (e) => {
      e.preventDefault()
      this.rejectProposal()
    }

    // Create accept button
    const acceptButton = document.createElement("button")
    acceptButton.textContent = "Aceptar"
    acceptButton.className = "button button-xs"
    acceptButton.onclick = (e) => {
      e.preventDefault()
      this.acceptProposal()
    }

    // Add buttons to actions container
    this.proposalActionsTarget.appendChild(rejectButton)
    this.proposalActionsTarget.appendChild(acceptButton)
    this.proposalActionsTarget.classList.remove('hidden')
  }
}
