import React, { useState } from 'react'
import { NodeViewWrapper, NodeViewContent } from '@tiptap/react'
import { SparklesIcon, TrashIcon } from '@heroicons/react/24/outline'
import Dropdown from './editor/Dropdown'

export default function ClauseComponent({ node, extension, updateAttributes, editor, getPos }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const clauseTypes = extension.options.types
  const currentType = clauseTypes.find(t => t.slug === node.attrs.type)

  const handleTypeChange = (type) => {
    updateAttributes({ type })
  }

  const handleDelete = (e) => {
    e.stopPropagation()
    if (window.confirm('Are you sure you want to delete this clause?')) {
      const pos = typeof getPos === 'function' ? getPos() : null
      if (pos !== null) {
        editor.chain().focus().deleteRange({ from: pos, to: pos + node.nodeSize }).run()
      }
    }
  }

  const options = clauseTypes.map(type => ({
    value: type.slug,
    label: type.literal
  }))

  return (
    <NodeViewWrapper className="node-clause" data-clause-type={node.attrs.type || ''}>
      <div className="node-clause__header">
        <Dropdown
          trigger={
            <button 
              className="node-button node-clause__type-selector"
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              {currentType?.literal || 'Select clause type'}
            </button>
          }
          options={options}
          value={node.attrs.type}
          onSelect={handleTypeChange}
          placeholder="Select clause type"
          showSearch={true}
          maxHeight="300px"
          onOpenChange={setIsDropdownOpen}
        />
        <div className="flex gap-2">
          <button 
            className="node-button"
            onClick={(e) => {
              e.stopPropagation()
              const pos = typeof getPos === 'function' ? getPos() : null
              editor.commands.sendToAssistant(null, { pos })
            }}
          >
            <SparklesIcon className="w-4 h-4" />
          </button>
          <button 
            className="node-button text-red-600 hover:text-red-700"
            onClick={handleDelete}
            title="Delete clause"
          >
            <TrashIcon className="w-4 h-4" />
          </button>
        </div>
      </div>
      <NodeViewContent />
    </NodeViewWrapper>
  )
} 