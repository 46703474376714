import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  static targets = [ "selectField" ]
  static values = {
    allowClear: Boolean,
    useDropdownInput: {
      type: Boolean,
      default: true
    }
  }

  tomSelect = null

  connect() {
    if (!this.hasSelectFieldTarget) {
      console.warn("No selectField target found for local-select controller", {
        element: this.element,
        html: this.element.outerHTML,
        parentHTML: this.element.parentElement?.outerHTML
      })
      return
    }

    const defaultPlugins = this.useDropdownInputValue ? ["dropdown_input"] : []
    const selectPlugins = defaultPlugins.concat(this.allowClearValue ? ["clear_button"] : [])

    try {
      this.tomSelect = new TomSelect(this.selectFieldTarget, {
        plugins: selectPlugins,
        valueField: "value",
      })
    } catch (e) {
      console.warn("Error initializing TomSelect:", e, {
        element: this.element,
        target: this.selectFieldTarget
      })
    }
  }

  selectAll() {
    if (this.tomSelect) {
      const availableOptions = Array.from(this.selectFieldTarget.options).map(option => option.value)
      this.tomSelect.setValue(availableOptions)
    }
  }

  disconnect() {
    if (this.tomSelect) {
      this.tomSelect.destroy()
      this.tomSelect = null
    }
  }
}
