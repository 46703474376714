import { Extension } from '@tiptap/core'
import Suggestion from '@tiptap/suggestion'
import { ReactRenderer } from '@tiptap/react'
import { PluginKey } from '@tiptap/pm/state'
import SlashMenuSuggestions from '../components/SlashMenuSuggestions'

const slashSuggestionPluginKey = new PluginKey('slashMenuSuggestion')

export default Extension.create({
  name: 'slashMenu',

  addOptions() {
    return {
      suggestion: {
        char: '/',
        startOfLine: true,
        pluginKey: slashSuggestionPluginKey,
        command: ({ editor, range, props }) => {
          props.command({ editor, range })
        },
        items: ({ editor, query }) => {
          const items = [
            {
              title: 'Tabla',
              description: 'Inserta una tabla',
              command: ({ editor, range }) => {
                editor
                  .chain()
                  .focus()
                  .deleteRange(range)
                  .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
                  .run()
              },
            },
            {
              title: 'Variable de proyecto',
              description: 'Inserta variable',
              command: ({ editor, range }) => {
                editor
                  .chain()
                  .focus()
                  .deleteRange(range)
                  .insertContent('{{')
                  .run()
              },
            },
            {
              title: 'Cláusula',
              description: 'Inserta cláusula',
              command: ({ editor, range }) => {
                editor
                  .chain()
                  .focus()
                  .deleteRange(range)
                  .insertContent({
                    type: 'clauseBlock',
                    content: [{
                      type: 'paragraph',
                      content: [{ 
                        type: 'text',
                        text: '\u200B' // Zero-width space as placeholder
                      }]
                    }]
                  })
                  .run()
              },
            },
            {
              title: 'h1',
              description: 'h1 - Encabezado 1',
              command: ({ editor, range }) => {
                editor
                  .chain()
                  .focus()
                  .deleteRange(range)
                  .setNode('heading', { level: 1 })
                  .run()
              },
            },
            {
              title: 'h2',
              description: 'h2 - Encabezado 2',
              command: ({ editor, range }) => {
                editor
                  .chain()
                  .focus()
                  .deleteRange(range)
                  .setNode('heading', { level: 2 })
                  .run()
              },
            },
            {
              title: 'h3',
              description: 'h3 - Encabezado 3',
              command: ({ editor, range }) => {
                editor
                  .chain()
                  .focus()
                  .deleteRange(range)
                  .setNode('heading', { level: 3 })
                  .run()
              },
            },
            {
              title: 'h4',
              description: 'h4 - Encabezado 4',
              command: ({ editor, range }) => {
                editor
                  .chain()
                  .focus()
                  .deleteRange(range)
                  .setNode('heading', { level: 3 })
                  .run()
              },
            },
            {
              title: 'Lista',
              description: 'Lista con viñetas',
              command: ({ editor, range }) => {
                editor
                  .chain()
                  .focus()
                  .deleteRange(range)
                  .toggleBulletList()
                  .run()
              },
            },
            {
              title: 'Lista numerada',
              description: 'Lista numerada',
              command: ({ editor, range }) => {
                editor
                  .chain()
                  .focus()
                  .deleteRange(range)
                  .toggleOrderedList()
                  .run()
              },
            },
          ]

          return items.filter(item =>
            item.title.toLowerCase().includes(query.toLowerCase())
          )
        },
        render: () => {
          let component
          let popup

          return {
            onStart: props => {
              component = new ReactRenderer(SlashMenuSuggestions, {
                props,
                editor: props.editor,
              })
            },
            onUpdate(props) {
              component?.updateProps(props)
            },
            onKeyDown(props) {
              if (!component?.ref) return false
              return component.ref.onKeyDown(props)
            },
            onExit() {
              component?.destroy()
            },
          }
        },
      }
    }
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ]
  },
}) 