/**
 * Layout Controller
 * 
 * A Stimulus controller that manages the layout of a three-panel interface with collapsible sidebars.
 * 
 * Features:
 * - Manages left and right sidebar visibility
 * - Handles keyboard shortcuts for toggling sidebars
 * - Configurable sidebar widths via data attributes
 * - Supports expanding sidebars to 50% width
 * 
 * Data Attributes:
 * - data-layout-sidebar-width-value: Sets the width of sidebars (default: "25vw")
 * - data-layout-expanded-width-value: Sets the width for expanded state (default: "50%")
 * 
 * Targets:
 * - container: The main container element that wraps the three-panel layout
 * 
 * Keyboard Shortcuts:
 * - Cmd/Ctrl + . : Toggle right sidebar
 * - Cmd/Ctrl + , : Toggle left sidebar
 * 
 * CSS Dependencies:
 * - Requires --sidebar-width CSS variable
 * - Uses .hide-left-sidebar and .hide-right-sidebar classes for toggling
 */

import { Controller } from "@hotwired/stimulus"
import hotkeys from 'hotkeys-js'
import { loadPreferences } from "./user_preferences_controller"

export default class extends Controller {
  static targets = ["container"]
  static values = {
    sidebarWidth: { type: String, default: "25vw" },
    expandedWidth: { type: String, default: "50%" },
    leftSidebarHidden: { type: Boolean, default: false },
    rightSidebarHidden: { type: Boolean, default: false },
    leftSidebarExpanded: { type: Boolean, default: false },
    rightSidebarExpanded: { type: Boolean, default: false }
  }

  initialize() {
    loadPreferences(this)

    // If no stored preferences exist, set default state
    if (!this.leftSidebarHiddenValue && 
        !this.rightSidebarHiddenValue && 
        !this.leftSidebarExpandedValue && 
        !this.rightSidebarExpandedValue) {
      
      // Set default state - right sidebar expanded, left hidden
      this.rightSidebarExpandedValue = true
      this.leftSidebarHiddenValue = true
      this.leftSidebarExpandedValue = false
      this.rightSidebarHiddenValue = false

      // Apply the classes to match the state
      this.containerTarget.classList.add('expand-right-sidebar')
      this.containerTarget.classList.add('hide-left-sidebar')
    }
  }

  connect() {
    this.initShortcuts()
    
    // Add event listener for expanding right panel
    window.addEventListener("redactor:expandRightPanel", () => {
      this.expandRight()
    })
    
    // Apply saved states on connect
    if (this.leftSidebarHiddenValue) {
      this.containerTarget.classList.add('hide-left-sidebar')
    }
    if (this.rightSidebarHiddenValue) {
      this.containerTarget.classList.add('hide-right-sidebar')
    }
    if (this.leftSidebarExpandedValue) {
      this.containerTarget.classList.add('expand-left-sidebar')
      this.containerTarget.classList.add('hide-right-sidebar')
    }
    if (this.rightSidebarExpandedValue) {
      this.containerTarget.classList.add('expand-right-sidebar')
      this.containerTarget.classList.add('hide-left-sidebar')
    }
  }

  disconnect() {
    hotkeys.unbind('command+.,ctrl+.')
    hotkeys.unbind('command+,,ctrl+,')
    // Remove the event listener
    window.removeEventListener("redactor:expandRightPanel", () => {
      this.expandRight()
    })
  }

  initShortcuts() {
    hotkeys('command+.,ctrl+.', (event) => {
      event.preventDefault()
      this.toggleRight()
    })

    hotkeys('command+,,ctrl+,', (event) => {
      event.preventDefault()
      this.toggleLeft()
    })
  }

  toggleLeft() {
    // If right is expanded, do nothing (maintain expanded state)
    if (this.containerTarget.classList.contains('expand-right-sidebar')) {
      return
    }

    // If left is expanded, return to normal first
    if (this.containerTarget.classList.contains('expand-left-sidebar')) {
      this.containerTarget.classList.remove('expand-left-sidebar')
      return
    }

    this.containerTarget.classList.toggle('hide-left-sidebar')
    this.leftSidebarHiddenValue = this.containerTarget.classList.contains('hide-left-sidebar')
  }

  toggleRight() {
    // If left is expanded, collapse it to normal width first
    if (this.containerTarget.classList.contains('expand-left-sidebar')) {
      this.containerTarget.classList.remove('expand-left-sidebar')
      this.containerTarget.classList.remove('hide-right-sidebar')
      this.rightSidebarHiddenValue = false
      return
    }

    // Three-state toggle logic
    const isExpanded = this.containerTarget.classList.contains('expand-right-sidebar')
    const isHidden = this.containerTarget.classList.contains('hide-right-sidebar')

    if (isExpanded) {
      // State 1 (expanded) -> State 2 (normal)
      this.containerTarget.classList.remove('expand-right-sidebar')
      this.rightSidebarExpandedValue = false
    } else if (!isHidden) {
      // State 2 (normal) -> State 3 (hidden)
      this.containerTarget.classList.add('hide-right-sidebar')
      this.rightSidebarHiddenValue = true
    } else {
      // State 3 (hidden) -> State 1 (expanded)
      this.containerTarget.classList.remove('hide-right-sidebar')
      this.containerTarget.classList.add('expand-right-sidebar')
      this.rightSidebarExpandedValue = true
      this.rightSidebarHiddenValue = false
    }
  }

  expandLeft() {
    // If right is expanded, return to normal state first
    if (this.containerTarget.classList.contains('expand-right-sidebar')) {
      this.containerTarget.classList.remove('expand-right-sidebar')
      this.containerTarget.classList.remove('hide-left-sidebar')
      this.rightSidebarExpandedValue = false
      this.leftSidebarHiddenValue = false
    }
    
    this.containerTarget.classList.add('expand-left-sidebar')
    this.containerTarget.classList.add('hide-right-sidebar')
    this.leftSidebarExpandedValue = true
    this.rightSidebarHiddenValue = true
    this.rightSidebarExpandedValue = false
  }

  expandRight() {
    // If already expanded, do nothing
    if (this.containerTarget.classList.contains('expand-right-sidebar')) {
      return
    }

    // If left is expanded, return to normal state first
    if (this.containerTarget.classList.contains('expand-left-sidebar')) {
      this.containerTarget.classList.remove('expand-left-sidebar')
      this.containerTarget.classList.remove('hide-right-sidebar')
      this.leftSidebarExpandedValue = false
      this.rightSidebarHiddenValue = false
    }

    // Expand right sidebar
    this.containerTarget.classList.add('expand-right-sidebar')
    this.containerTarget.classList.add('hide-left-sidebar')
    this.rightSidebarExpandedValue = true
    this.leftSidebarHiddenValue = true
    this.leftSidebarExpandedValue = false
  }
} 
