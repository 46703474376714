import React, { forwardRef, useImperativeHandle, useState } from 'react'
import Dropdown from './editor/Dropdown'

export default forwardRef(function VariableSuggestions({ items, command, clientRect }, ref) {
  const [selectedIndex, setSelectedIndex] = useState(0)

  const options = items.map(item => ({
    value: item.key,
    label: `${item.label} (${item.key})`
  }))

  // We only need to expose onKeyDown for TipTap's suggestion system
  useImperativeHandle(ref, () => ({
    onKeyDown: () => false // Let Dropdown handle all keyboard interactions
  }))

  if (!items?.length) return null

  return (
    <Dropdown
      trigger={<div style={{ display: 'none' }} />}
      options={options}
      value={options[selectedIndex]?.value}
      onSelect={(value) => {
        const item = items.find(i => i.key === value)
        if (item) command(item)
      }}
      showSearch={false}
      showHeader={false}
      placement="bottom-start"
      maxHeight="300px"
      className="variable-suggestions"
      forceShow={true}
      getReferenceClientRect={clientRect}
    />
  )
}) 