import { Node, mergeAttributes } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import ClauseComponent from '../components/ClauseComponent'

// Temporary mock of clause types until we implement the API
const CLAUSE_TYPES = [
  { slug: 'criterios-adjudicacion', literal: 'Criterios de adjudicación' },
  { slug: 'criterios-solvencia', literal: 'Criterios de solvencia' },
  { slug: 'condiciones-especiales-ejecucion', literal: 'Condiciones especiales de ejecución' },
  { slug: 'especificaciones-tecnicas', literal: 'Especificaciones técnicas' },
  { slug: 'objeto', literal: 'Objeto' },
  { slug: 'requisitos-minimos-licitar', literal: 'Requisitos mínimos para licitar' },
  { slug: 'desempate', literal: 'Desempate' }
]

export default Node.create({
  name: 'clauseBlock',
  group: 'block',
  content: 'block+',
  isolating: true,
  
  addOptions() {
    return {
      HTMLAttributes: {
        class: 'node-clause',
        'data-type': 'clause'
      },
      types: CLAUSE_TYPES
    }
  },

  addAttributes() {
    return {
      content: {
        default: '',
        parseHTML: element => element.innerHTML
      },
      type: {
        default: null,
        parseHTML: element => element.getAttribute('clause')
      }
    }
  },
  
  parseHTML() {
    return [{ 
      tag: 'node[type="clause"]',
      getAttrs: element => ({
        content: element.innerHTML,
        type: element.getAttribute('clause')
      })
    }]
  },
  
  renderHTML({ HTMLAttributes, node }) {
    return [
      'node',
      mergeAttributes(
        this.options.HTMLAttributes,
        { 
          type: 'clause',
          clause: node.attrs.type 
        },
        HTMLAttributes
      ),
      node.attrs.content || 0
    ]
  },
  
  addNodeView() {
    return ReactNodeViewRenderer(ClauseComponent)
  },

  addCommands() {
    return {
      setClauseType: type => ({ commands }) => {
        return commands.updateAttributes('clauseBlock', { type })
      },
      toggleClause: attributes => ({ commands }) => {
        return commands.toggleWrap('clauseBlock', attributes)
      }
    }
  }
}) 