/**
 * CodeMirror Integration
 * ---------------------
 * 
 * Quick Start:
 * ```erb
 * <%= f.text_area :content, 
 *                data: {
 *                  controller: "codemirror",
 *                  codemirror_mode_value: "markdown",
 *                  codemirror_theme_value: "material"
 *                } %>
 * ```
 * 
 * Available Options (data attributes):
 * - mode_value: Language mode ("ruby", "javascript", "htmlmixed", "markdown", etc.)
 * - theme_value: Editor theme ("default", "monokai", "material", "dracula")
 * - line_numbers_value: Show line numbers (true/false)
 * - read_only_value: Make editor read-only (true/false)
 * - tab_size_value: Tab size in spaces (default: 2)
 * - indent_with_tabs_value: Use tabs for indentation (true/false)
 * - line_wrapping_value: Enable line wrapping (true/false)
 * - auto_height_value: Auto-adjust height (true/false)
 * - fold_value: Enable code folding (true/false)
 * 
 * Key Shortcuts:
 * - Tab/Shift+Tab: Indent/unindent line(s)
 * - Ctrl+Q: Fold/unfold code (when folding enabled)
 * - Ctrl/Cmd+[/]: Unindent/indent line(s)
 * 
 * Full Example:
 * ```erb
 * <%= f.text_area :content, 
 *                data: {
 *                  controller: "codemirror",
 *                  codemirror_mode_value: "htmlmixed",
 *                  codemirror_theme_value: "material",
 *                  codemirror_line_numbers_value: true,
 *                  codemirror_line_wrapping_value: true,
 *                  codemirror_fold_value: true,
 *                  codemirror_tab_size_value: 2,
 *                  codemirror_indent_with_tabs_value: false
 *                } %>
 * ```
 */

import { Controller } from "@hotwired/stimulus"
import CodeMirror from "codemirror"

// Import basic CodeMirror styles
import "codemirror/lib/codemirror.css"

// Import themes (you can add more as needed)
import "codemirror/theme/monokai.css"
import "codemirror/theme/material.css"
import "codemirror/theme/dracula.css"

// Import modes (you can add more as needed)
import "codemirror/mode/javascript/javascript"
import "codemirror/mode/ruby/ruby"
import "codemirror/mode/htmlmixed/htmlmixed"
import "codemirror/mode/xml/xml"
import "codemirror/mode/css/css"
import "codemirror/mode/yaml/yaml"
import "codemirror/mode/markdown/markdown"
import "codemirror/mode/sql/sql"
import "codemirror/mode/gfm/gfm" // GitHub Flavored Markdown

// Import code folding addons
import "codemirror/addon/fold/foldcode"
import "codemirror/addon/fold/foldgutter"
import "codemirror/addon/fold/brace-fold"
import "codemirror/addon/fold/xml-fold"
import "codemirror/addon/fold/indent-fold"
import "codemirror/addon/fold/markdown-fold"
import "codemirror/addon/fold/comment-fold"
import "codemirror/addon/fold/foldgutter.css"

// Import indentation helpers
import "codemirror/addon/edit/matchbrackets"
import "codemirror/addon/edit/closebrackets"
import "codemirror/addon/comment/comment"

// Define a custom command for multi-line unindent
CodeMirror.commands.shiftTabIndent = function(cm) {
  if (cm.somethingSelected()) {
    const selections = cm.listSelections();
    for (let i = 0; i < selections.length; i++) {
      const from = selections[i].from();
      const to = selections[i].to();
      for (let j = from.line; j <= to.line; j++) {
        cm.indentLine(j, "subtract");
      }
    }
  } else {
    cm.indentLine(cm.getCursor().line, "subtract");
  }
};

/**
 * CodeMirror Stimulus Controller
 * 
 * This controller transforms a textarea into a CodeMirror editor.
 * 
 * Usage:
 * <textarea data-controller="codemirror" 
 *           data-codemirror-mode-value="javascript"
 *           data-codemirror-theme-value="monokai"
 *           data-codemirror-line-numbers-value="true"
 *           data-codemirror-fold-value="true">
 * </textarea>
 */
export default class extends Controller {
  static values = {
    mode: { type: String, default: "text/plain" },
    theme: { type: String, default: "default" },
    lineNumbers: { type: Boolean, default: true },
    readOnly: { type: Boolean, default: false },
    tabSize: { type: Number, default: 2 },
    indentWithTabs: { type: Boolean, default: false },
    lineWrapping: { type: Boolean, default: true },
    autoHeight: { type: Boolean, default: false },
    fold: { type: Boolean, default: false }
  }

  connect() {
    try {
      // Create CodeMirror instance with basic options
      const options = {
        mode: {
          name: "gfm",
          htmlMode: true,
          xml: true
        },
        theme: this.themeValue,
        lineNumbers: this.lineNumbersValue,
        readOnly: this.readOnlyValue,
        tabSize: this.tabSizeValue,
        indentUnit: this.tabSizeValue,
        indentWithTabs: this.indentWithTabsValue,
        lineWrapping: this.lineWrappingValue,
        matchBrackets: true,
        autoCloseBrackets: true,
        foldGutter: this.foldValue,
        gutters: this.foldValue ? ["CodeMirror-linenumbers", "CodeMirror-foldgutter"] : [],
        extraKeys: {
          "Shift-Tab": "shiftTabIndent",
          "Ctrl-Q": cm => {
            if (this.foldValue) {
              cm.foldCode(cm.getCursor());
            }
          }
        }
      };
      
      // Add code folding options if enabled
      if (this.foldValue) {
        Object.assign(options, {
          foldGutter: true,
          gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
          // Configure folding
          fold: "markdown",
          foldOptions: {
            widget: "...",
            scanUp: false,
            rangeFinder: (cm, pos) => {
              // Try XML folding first
              let range = CodeMirror.fold.xml(cm, pos);
              if (!range) {
                // If no XML fold found, try Markdown folding
                range = CodeMirror.fold.markdown(cm, pos);
              }
              return range;
            }
          }
        });
      }
      
      // Create the editor with all options
      this.editor = CodeMirror.fromTextArea(this.element, options);

      // Set up auto-height if enabled
      if (this.autoHeightValue) {
        this.editor.setSize("100%", "auto")
      }

      // Sync CodeMirror content back to the textarea on change
      this.editor.on("change", () => {
        this.editor.save()
        this.element.dispatchEvent(new Event("input", { bubbles: true }))
      })

      // Apply any additional classes from the textarea to the CodeMirror wrapper
      const classes = this.element.className.split(" ").filter(c => c.trim() !== "")
      classes.forEach(className => {
        this.editor.getWrapperElement().classList.add(className)
      })
      
      // Add ARIA attributes for accessibility
      const wrapper = this.editor.getWrapperElement();
      wrapper.setAttribute("role", "textbox");
      wrapper.setAttribute("aria-multiline", "true");
      wrapper.setAttribute("aria-label", this.element.getAttribute("aria-label") || "Code editor");
    } catch (error) {
      console.error("Error initializing CodeMirror:", error);
      // Fallback to regular textarea if CodeMirror fails to initialize
      this.element.style.display = "block";
    }
  }

  disconnect() {
    // Clean up CodeMirror instance when the controller is disconnected
    if (this.editor) {
      try {
        this.editor.toTextArea()
      } catch (error) {
        console.error("Error cleaning up CodeMirror:", error);
      }
    }
  }

  // Method to refresh the editor (useful when it's in a hidden element that becomes visible)
  refresh() {
    if (this.editor) {
      try {
        this.editor.refresh()
      } catch (error) {
        console.error("Error refreshing CodeMirror:", error);
      }
    }
  }
  
  // Method to programmatically set the editor's value
  setValue(value) {
    if (this.editor) {
      try {
        this.editor.setValue(value);
      } catch (error) {
        console.error("Error setting CodeMirror value:", error);
      }
    }
  }
  
  // Method to get the editor's current value
  getValue() {
    if (this.editor) {
      try {
        return this.editor.getValue();
      } catch (error) {
        console.error("Error getting CodeMirror value:", error);
        return this.element.value;
      }
    }
    return this.element.value;
  }
} 
