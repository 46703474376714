import { Controller } from "@hotwired/stimulus"
import { scrollAndAnimateThread } from "../utils/document_threads_utils"

/**
 * Handle the creation of a new thread
 */
export default class extends Controller {
  static values = {
    createThreadUrl: String,
    commentsCount: { type: Number, default: 0 }
  }

  connect() {
    // Listen for thread/comment-related events
    this.boundHandleNewThread = this._handleNewThread.bind(this)
    window.addEventListener('comments:new', this.boundHandleNewThread)
  }

  disconnect() {
    window.removeEventListener('comments:new', this.boundHandleNewThread)
  }

  commentsCountValueChanged() {
    if (this.threadIdValue) {
      this._updateEditorMark(this.threadIdValue, {
        id: this.threadIdValue,
        commentCount: this.commentsCountValue
      })
    }
  }

  /**
   * Handles the creation of a new thread
   * @param {CustomEvent} event - Contains threadId and selection details
   */
  _handleNewThread(event) {
    // Get the temporary thread ID from the event
    const tempThreadId = event.detail.threadId

    const url = new URL(this.createThreadUrlValue, window.location.origin)

    const headers = {
      Accept: "text/vnd.turbo-stream.html",
      "Content-Type": "application/json",
      "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content,
    };

    const requestOptions = {
      method: "POST",
      headers: headers
    };

    fetch(url, requestOptions)
      .then((response) => response.text())
      .then((html) => {
        return new Promise((resolve) => {
          let timeoutId;

          const observer = new MutationObserver((mutations, obs) => {
            // Look for the thread container with a data-thread-id attribute
            const threadContainer = document.querySelector('[data-writer-document-thread-newly-added-thread]');
            if (threadContainer) {
              clearTimeout(timeoutId);
              obs.disconnect();
              resolve(threadContainer);
            }
          });

          observer.observe(document.body, {
            childList: true,
            subtree: true,
            attributes: true,
            attributeFilter: ['data-thread-id']
          });

          // Render the Turbo Stream message
          Turbo.renderStreamMessage(html);

          // Set a timeout to resolve the promise even if the MutationObserver doesn't trigger
          timeoutId = setTimeout(() => {
            observer.disconnect();

            const threadContainer = document.querySelector('[data-writer-document-thread-newly-added-thread]');
            if (threadContainer) {
              resolve(threadContainer);
            } else {
              resolve(null);
            }
          }, 2000); // 2 second timeout
        });
      })
      .then((threadContainer) => {
        if (threadContainer) {
          const threadId = threadContainer.dataset.threadId;
          const commentsCount = this.commentsCountValue;

          scrollAndAnimateThread(threadContainer);

          this._updateEditorMark(tempThreadId, { id: threadId, commentCount: commentsCount });

          // Make the cursor focus on the new thread form text area
          const newThreadFormTextArea = document.querySelector(`div.comment-thread[data-thread-id="${threadId}"] form textarea`);
          if (newThreadFormTextArea) {
            newThreadFormTextArea.focus();
          }
        }
      })
      .catch(error => {
        console.error('Error in thread creation process:', error);
      });
  }

  /**
  * Updates the comment mark in the Tiptap editor
  * @private
  * @param {string} oldThreadId - The ID to look for (could be temporary or real)
  * @param {Object} thread - The thread data with new ID and comment count
  */
  _updateEditorMark(oldThreadId, thread) {
    window.dispatchEvent(new CustomEvent('editor:update-comment', {
      detail: {
        oldThreadId,
        thread: {
          id: thread.id,
          commentCount: thread.commentCount,
          // If we have a current selection, include it
          ...(this.hasCurrentSelectionValue ? {
            selection: this.currentSelectionValue
          } : {})
        }
      }
    }))

    // Clear the selection value after using it
    if (this.hasCurrentSelectionValue) {
      this.currentSelectionValue = null;
    }
  }
}
