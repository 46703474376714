import { Controller } from "@hotwired/stimulus";
import { visit } from "@hotwired/turbo";

/**
 * This controller handles the interaction between the editor and the assistant.
 * 
 * It manages:
 * 1. Receiving text from the editor (via editor:request-assistant event)
 * 2. Navigating to the assistant view
 * 3. Setting up the conversation form with the received text
 * 
 * Flow:
 * - Editor dispatches 'editor:request-assistant' with text
 * - Controller activates assistant tab
 * - Controller navigates to new conversation if needed
 * - On frame load, controller:
 *   - Selects the appropriate conversation type
 *   - Sets up the form with the received text
 */

export default class extends Controller {
  static targets = ["menu", "menuItem"]
  static values = {
    category: { type: String, default: 'redactor-de-documentos-metaadmin-pending' },
    newConversationPath: { type: String, default: '/asistente/new?context=tools' }
  }

  connect() {
    this._bindEventHandlers();
    this._setupDebugTools();
    this._setupGlobalFunctions();
    // this._setupMutationObserver();
  }

  disconnect() {
    this._removeEventHandlers();
    this._cleanupMutationObserver();
  }

  handleEditorRequest(event) {
    const { text, category, tab, eventId, modalId } = event.detail;
    this._storePendingState(text, category, tab, eventId);
    
    if (this.pendingTab) {
      this.activateTab(this.pendingTab);
    }
    
    this._handleNavigation();
  }

  frameLoaded() {
    if (!this.pendingText) return;
    
    this._preSelectWriterCategory();
    this._setupConversationForm();
    this._clearPendingState();
  }

  _storePendingState(text, category, tab, eventId) {
    this.pendingText = text;
    this.categoryValue = category || this.categoryValue;
    this.pendingTab = tab;
    this.pendingEventId = eventId;
  }

  _clearPendingState() {
    this.pendingText = null;
    this.pendingEventId = null;
  }

  // _handleModalOpening(modalId) {
  //   console.log('🔍 Attempting to open modal:', modalId);
    
  //   // Try opening immediately
  //   if (this._attemptModalOpen(modalId)) return;
    
  //   // If immediate attempt fails, try again after a short delay
  //   setTimeout(() => {
  //     console.log('🔍 Retrying modal open after delay');
  //     this._attemptModalOpen(modalId);
  //   }, 100);
  // }

  // _attemptModalOpen(modalId) {
  //   // First try conversation-menu controller
  //   if (this._tryOpenWithConversationMenu(modalId)) {
  //     console.log('🔍 Successfully opened with conversation-menu');
  //     return true;
  //   }

  //   // Then try modal-simple
  //   if (this._tryOpenWithModalSimple(modalId)) {
  //     console.log('🔍 Successfully opened with modal-simple');
  //     return true;
  //   }

  //   // Finally, try direct click if button exists
  //   const modalButton = document.querySelector(`[data-action="click->conversation-menu#openModal"][data-modal-id="${modalId}"]`);
  //   if (modalButton) {
  //     console.log('🔍 Found modal button, clicking it');
  //     modalButton.click();
  //     return true;
  //   }

  //   console.log('🔍 All modal opening attempts failed');
  //   return false;
  // }

  // _tryOpenWithConversationMenu(modalId) {
  //   const controllers = Array.from(this.application.controllers)
  //     .filter(c => c.identifier === 'conversation-menu');
    
  //   console.log('🔍 Found conversation-menu controllers:', controllers.length);
    
  //   for (const controller of controllers) {
  //     console.log('🔍 Controller methods:', Object.getOwnPropertyNames(controller));
  //     // Try both possible method names
  //     if (typeof controller._openModalByElementId === 'function') {
  //       console.log('🔍 Found _openModalByElementId method, calling it');
  //       controller._openModalByElementId(modalId);
  //       return true;
  //     }
  //     if (typeof controller.openModal === 'function') {
  //       console.log('🔍 Found openModal method, calling it');
  //       controller.openModal(modalId);
  //       return true;
  //     }
  //   }
  //   return false;
  // }

  // _tryOpenWithModalSimple(modalId) {
  //   const modalElement = document.querySelector(`[data-modal-id="${modalId}"]`);
  //   console.log('🔍 Found modal element:', !!modalElement, modalId);
  //   if (!modalElement) return false;
    
  //   const modalController = this.application.getControllerForElementAndIdentifier(
  //     modalElement, 
  //     'modal-simple'
  //   );
  //   console.log('🔍 Found modal controller:', !!modalController);
  //   if (modalController) {
  //     console.log('🔍 Opening modal with controller');
  //     modalController.open();
  //     return true;
  //   }
  //   return false;
  // }

  _handleNavigation() {
    const currentPath = window.location.pathname;
    this._preSelectWriterCategory();
    
    if (currentPath === '/asistente/new') {
      this.frameLoaded();
    } else {
      this._navigateToNewConversation();
    }
  }

  _navigateToNewConversation() {
    const url = new URL(this.newConversationPathValue, window.location.origin);
    if (this.categoryValue) {
      url.searchParams.append('category', this.categoryValue);
    }
    visit(url.toString(), { frame: 'conversation_frame' });
  }

  _setupConversationForm() {
    const conversationFrame = document.getElementById('conversation_frame');
    
    if (!conversationFrame || conversationFrame.dataset.processedText === this.pendingText) {
      return;
    }
    
    conversationFrame.dataset.processedText = this.pendingText;
    
    // First, find the conversation menu controller
    const conversationMenuElement = conversationFrame.querySelector('[data-controller~="conversation-menu"]');
    const conversationMenuController = conversationMenuElement && 
      this.application.getControllerForElementAndIdentifier(conversationMenuElement, 'conversation-menu');
    
    if (conversationMenuController) {
      // Set the text in the conversation menu controller first
      conversationMenuController.lastSetText = this.pendingText;
    }
    
    // Then wait for writer selection and handle input
    this._waitForWriterAndHandleInput();
  }

  _waitForWriterAndHandleInput(attempts = 0, maxAttempts = 10) {
    if (attempts >= maxAttempts) {
      console.log('🔍 Max attempts reached waiting for writer selection');
      return;
    }

    const selectedWriter = document.querySelector('[data-option-id="writer"]');
    if (selectedWriter) {
      console.log('🔍 Writer option selected, handling input');
      this._handleWriterSelected();
    } else {
      setTimeout(() => this._waitForWriterAndHandleInput(attempts + 1, maxAttempts), 100);
    }
  }

  _handleWriterSelected() {
    // First try to find the input
    const input = this._findAssistantInput();
    if (input && this.pendingText) {
      // Only set the text if the input is empty or different
      if (!input.value || input.value !== this.pendingText) {
        console.log('🔍 Setting input value:', this.pendingText);
        this._setAndDispatchEvents(input, this.pendingText);
      } else {
        console.log('🔍 Input already has correct value, skipping');
      }
      
      // Always open modal if we have pendingText, regardless of whether we set the input
      console.log('🔍 Opening modal after handling input');
      const conversationMenuElement = document.querySelector('[data-controller~="conversation-menu"]');
      if (conversationMenuElement) {
        const conversationMenuController = this.application.getControllerForElementAndIdentifier(
          conversationMenuElement, 
          'conversation-menu'
        );
        if (conversationMenuController && typeof conversationMenuController._openModalByElementId === 'function') {
          conversationMenuController._openModalByElementId('modal-writer');
        }
      }
    } else {
      // If input not found, try again after a short delay
      setTimeout(() => this._handleWriterSelected(), 200);
    }
  }

  _findAssistantInput() {
    const conversationFrame = document.getElementById('conversation_frame');
    if (!conversationFrame) return null;

    // Try all possible input selectors
    return conversationFrame.querySelector('[data-conversation-menu-target="input"]') ||
           conversationFrame.querySelector('#message_question') ||
           conversationFrame.querySelector('input[type="text"]') ||
           conversationFrame.querySelector('textarea');
  }

  _bindEventHandlers() {
    this.handleEditorRequest = this.handleEditorRequest.bind(this);
    this.frameLoaded = this.frameLoaded.bind(this);
    window.addEventListener('editor:request-assistant', this.handleEditorRequest);
    window.addEventListener('turbo:frame-load', this.frameLoaded);
  }

  _removeEventHandlers() {
    window.removeEventListener('editor:request-assistant', this.handleEditorRequest);
    window.removeEventListener('turbo:frame-load', this.frameLoaded);
  }

  _setupDebugTools() {
    window.debugAssistantEvents = () => this.debugState();
    window.testSendToAssistant = this._createTestSendFunction();
    window.testSetInputDirectly = this._createTestSetInputFunction();
    window.checkInputValue = () => this._checkInputValue();
  }

  _createTestSendFunction() {
    return (text = "Test text from console") => {
      window.dispatchEvent(new CustomEvent('editor:request-assistant', {
        detail: { 
          text,
          category: 'writer',
          tab: 'sidebar-tabs#assistant'
        }
      }));
    };
  }

  _createTestSetInputFunction() {
    return (text = "Test text set directly") => {
      this._setInputValueDirectly(text);
    };
  }

  activateTab(tabSpec, attempts = 0, maxAttempts = 10) {
    if (!tabSpec || attempts >= maxAttempts) return;
    
    const [tabsId, tabId] = tabSpec.split('#');
    if (!tabsId || !tabId) return;
    
    const tabsElement = document.getElementById(tabsId);
    if (!tabsElement) {
      setTimeout(() => this.activateTab(tabSpec, attempts + 1, maxAttempts), 100);
      return;
    }
    
    const tabsController = this.application.getControllerForElementAndIdentifier(tabsElement, 'tabs');
    if (!tabsController) {
      setTimeout(() => this.activateTab(tabSpec, attempts + 1, maxAttempts), 100);
      return;
    }
    
    const tabItem = tabsController.tabItemTargets.find(item => 
      item.dataset.tabsControlsParam === tabId
    );
    
    if (tabItem) {
      setTimeout(() => tabItem.click(), 50);
    } else {
      setTimeout(() => this.activateTab(tabSpec, attempts + 1, maxAttempts), 100);
    }
  }

  _preSelectWriterCategory() {
    const conversationMenuElement = document.querySelector('[data-controller~="conversation-menu"]');
    if (!conversationMenuElement) {
      return;
    }
    
    const conversationMenuController = this.application.getControllerForElementAndIdentifier(
      conversationMenuElement, 
      'conversation-menu'
    );
    
    if (!conversationMenuController) {
      return;
    }
    
    const possibleCategories = ['redactor-de-documentos-metaadmin-pending', 'writer', 'redactor'];
    let writerMenuItem = null;
    
    for (const category of possibleCategories) {
      const menuItem = conversationMenuElement.querySelector(`[data-category="${category}"]`);
      if (menuItem) {
        writerMenuItem = menuItem;
        break;
      }
    }
    
    if (!writerMenuItem) {
      const menuItems = conversationMenuElement.querySelectorAll('[data-category]');
      
      for (const menuItem of menuItems) {
        const category = menuItem.dataset.category;
        if (category.includes('redactor') || category === 'writer') {
          writerMenuItem = menuItem;
          break;
        }
      }
    }
    
    if (!writerMenuItem) {
      return;
    }
    
    // Click the writer menu item
    writerMenuItem.click();
  }

  _checkInputValue() {
    const conversationFrame = document.getElementById('conversation_frame');
    if (!conversationFrame) {
      return;
    }
    
    const inputElement = conversationFrame.querySelector('[data-conversation-menu-target="input"]');
    if (inputElement) {
      try {
        Object.defineProperty(inputElement, 'value', {
          writable: true,
          value: window.lastPendingText || 'Test value set via Object.defineProperty'
        });
        
        inputElement.dispatchEvent(new InputEvent('input', {
          bubbles: true,
          data: window.lastPendingText || 'Test value set via InputEvent'
        }));
      } catch (error) {
        window.lastError = error;
      }
    }
  }

  // _setupMutationObserver() {
  //   this.processedInputs = new WeakSet();
    
  //   this.observer = new MutationObserver((mutations) => {
  //     if (!window.lastPendingText) {
  //       return;
  //     }
      
  //     for (const mutation of mutations) {
  //       if (mutation.type === 'childList') {
  //         for (const node of mutation.addedNodes) {
  //           if (node.nodeType === Node.ELEMENT_NODE) {
  //             if (node.tagName === 'INPUT' && !this.processedInputs.has(node)) {
  //               this._trySetInputValue(node, window.lastPendingText);
  //             }
              
  //             const inputs = node.querySelectorAll('input[type="text"], textarea');
  //             for (const input of inputs) {
  //               if (!this.processedInputs.has(input)) {
  //                 this._trySetInputValue(input, window.lastPendingText);
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   });
    
  //   this.observer.observe(document.body, {
  //     childList: true,
  //     subtree: true
  //   });
  // }
  
  // _trySetInputValue(input, text) {
  //   if (!text) return;
    
  //   if (input.dataset.conversationMenuTarget === 'input' || 
  //       input.id === 'message_question' || 
  //       input.name === 'message[question]') {
      
  //     this.processedInputs.add(input);
      
  //     if (input.value === text) {
  //       return;
  //     }
      
  //     input.value = text;
      
  //     input.focus();
      
  //     input.dispatchEvent(new Event('input', { bubbles: true }));
  //     input.dispatchEvent(new Event('change', { bubbles: true }));
      
  //     const event = new CustomEvent('assistant:setText', {
  //       detail: { text },
  //       bubbles: true
  //     });
  //     input.dispatchEvent(event);
  //   }
  // }

  debugState() {
    console.log("🔍 AssistantEvents Controller Debug Info:");
    console.log("📝 pendingText:", this.pendingText);
    console.log("🏷️ categoryValue:", this.categoryValue);
    console.log("🔖 pendingTab:", this.pendingTab);
    console.log("🔍 hasMenuTarget:", this.hasMenuTarget);
    if (this.hasMenuTarget) {
      console.log("🔍 menuTarget:", this.menuTarget);
    }
    console.log("🔍 menuItemTargets:", this.menuItemTargets);
    console.log("🔍 menuItemTargets categories:", this.menuItemTargets.map(item => item.dataset.category));
    
    if (this.pendingTab) {
      const [tabsId, tabId] = this.pendingTab.split('#');
      const tabsElement = document.getElementById(tabsId);
      console.log(`🔍 tabsElement with id ${tabsId} exists:`, !!tabsElement);
      
      if (tabsElement) {
        const tabsController = this.application.getControllerForElementAndIdentifier(tabsElement, 'tabs');
        console.log(`🔍 tabsController for element with id ${tabsId} exists:`, !!tabsController);
        
        if (tabsController) {
          console.log("🔍 tabItemTargets:", tabsController.tabItemTargets);
          console.log("🔍 tabItemTargets controls params:", tabsController.tabItemTargets.map(item => item.dataset.tabsControlsParam));
          
          const tabItem = tabsController.tabItemTargets.find(item => 
            item.dataset.tabsControlsParam === tabId
          );
          console.log(`🔍 tabItem for ${tabId} exists:`, !!tabItem);
        }
      }
    }
    
    const conversationFrame = document.getElementById('conversation_frame');
    console.log("🔍 conversationFrame exists:", !!conversationFrame);
    
    if (conversationFrame) {
      const inputElement = conversationFrame.querySelector('[data-conversation-menu-target="input"]');
      console.log("🔍 inputElement exists:", !!inputElement);
      
      if (inputElement) {
        console.log("🔍 inputElement id:", inputElement.id);
        console.log("🔍 inputElement value:", inputElement.value);
      }
      
      const conversationMenuElement = conversationFrame.querySelector('[data-controller~="conversation-menu"]');
      console.log("🔍 conversationMenuElement exists:", !!conversationMenuElement);
      
      if (conversationMenuElement) {
        const conversationMenuController = this.application.getControllerForElementAndIdentifier(
          conversationMenuElement, 
          'conversation-menu'
        );
        console.log("🔍 conversationMenuController exists:", !!conversationMenuController);
      }
    }
  }

  _setupGlobalFunctions() {
    window.setAssistantInputValue = (text) => {
      window.lastPendingText = text;
      this._setInputValueDirectly(text);
      window.dispatchEvent(new CustomEvent('assistant:setText', {
        detail: { text }
      }));
    };
  }

  _cleanupMutationObserver() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  _setInputValueDirectly(text) {
    if (!text) return;
    
    const conversationFrame = document.getElementById('conversation_frame');
    if (!conversationFrame) return;
    
    // Try multiple approaches to find the input
    
    // 1. Look for the input with data-conversation-menu-target="input"
    const inputElement = conversationFrame.querySelector('[data-conversation-menu-target="input"]');
    if (inputElement) {
      this._setAndDispatchEvents(inputElement, text);
      return;
    }
    
    // 2. Try to find by id
    const messageQuestion = conversationFrame.querySelector('#message_question');
    if (messageQuestion) {
      this._setAndDispatchEvents(messageQuestion, text);
      return;
    }
    
    // 3. Try to find any text input in the frame
    const textInputs = conversationFrame.querySelectorAll('input[type="text"]');
    if (textInputs.length > 0) {
      this._setAndDispatchEvents(textInputs[0], text);
      return;
    }
    
    // 4. Try to find any textarea in the frame
    const textareas = conversationFrame.querySelectorAll('textarea');
    if (textareas.length > 0) {
      this._setAndDispatchEvents(textareas[0], text);
    }
  }

  _setAndDispatchEvents(element, text) {
    element.value = text;
    element.focus();
    element.dispatchEvent(new Event('input', { bubbles: true }));
    element.dispatchEvent(new Event('change', { bubbles: true }));
  }
}
