import React, { useContext } from 'react'
import { NodeViewWrapper, NodeViewContent } from '@tiptap/react'
import { EditorContext } from '../context/EditorContext'

export default function HelpComponent() {
  const { showHelp } = useContext(EditorContext)

  if (!showHelp) {
    return null
  }

  return (
    <NodeViewWrapper className="node-help" contentEditable={false}>
      <div className="help-block-header">
        <span className="text-xxs text-blue">Ayuda</span>
      </div>
      <div className="help-block-content" contentEditable={false}>
        <NodeViewContent contentEditable={false} />
      </div>
    </NodeViewWrapper>
  )
} 