import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import tippy from 'tippy.js/headless'
import ReactDOM from 'react-dom/client'

function DropdownContent({ 
  items, 
  selectedValue,
  onSelect,
  showSearch,
  searchQuery,
  onSearchChange,
  maxHeight = '40vh',
  getInstance
}) {
  const [highlightedIndex, setHighlightedIndex] = useState(0)
  const searchInputRef = useRef(null)
  const itemsRef = useRef([])

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, items.length)
    if (items.length > 0) {
      itemsRef.current[0]?.focus()
    }
  }, [items])

  const handleKeyDown = (e) => {
    if (e.target.tagName === 'INPUT') {
      if (e.key === 'Escape') {
        e.preventDefault()
        getInstance()?.hide()
      }
      e.stopPropagation()
      return
    }

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault()
        setHighlightedIndex((current) => {
          const next = current < items.length - 1 ? current + 1 : current
          itemsRef.current[next]?.focus()
          return next
        })
        break
      case 'ArrowUp':
        e.preventDefault()
        setHighlightedIndex((current) => {
          const prev = current > 0 ? current - 1 : current
          itemsRef.current[prev]?.focus()
          return prev
        })
        break
      case 'Enter':
        e.preventDefault()
        if (items[highlightedIndex]) {
          onSelect(items[highlightedIndex].value)
          getInstance()?.hide()
        }
        break
      case 'Escape':
        e.preventDefault()
        getInstance()?.hide()
        break
    }
  }

  return (
    <div 
      className="editor-dropdown dropdown-menu dropdown-container top-2 dropdown-container-left open active"
      onKeyDown={handleKeyDown}
      role="listbox"
    >
      {showSearch && (
        <div className="filter-values-input">
          <input
            ref={searchInputRef}
            type="search"
            className="input-text-tiny w-full"
            placeholder="Filtra..."
            value={searchQuery}
            onChange={(e) => onSearchChange(e.target.value)}
            onKeyDown={(e) => e.stopPropagation()}
          />
        </div>
      )}

      <div style={{ maxHeight }}>
        {items.length === 0 ? (
          <div className="filter-option">No options found</div>
        ) : (
          items.map((item, index) => (
            <button
              key={item.value}
              ref={el => itemsRef.current[index] = el}
              className={`filter-option ${selectedValue === item.value ? 'font-medium' : ''}`}
              onClick={() => {
                onSelect(item.value)
                getInstance()?.hide()
              }}
              onMouseEnter={() => {
                setHighlightedIndex(index)
              }}
              onFocus={() => {
                setHighlightedIndex(index)
              }}
              role="option"
              id={item.value}
              aria-selected={selectedValue === item.value}
              tabIndex={0}
            >
              {item.label}
            </button>
          ))
        )}
      </div>
    </div>
  )
}

export default function Dropdown({
  trigger,
  options,
  value,
  onSelect,
  showSearch = false,
  placement = 'bottom-start',
  maxHeight = '40vh',
  forceShow = false,
  getReferenceClientRect = null,
  onOpenChange
}) {
  const [searchQuery, setSearchQuery] = useState('')
  const triggerRef = useRef()
  const box = useRef(document.createElement('div'))
  const root = useRef(null)
  const [mounted, setMounted] = useState(false)

  const filteredOptions = options.filter(option => 
    option.label.toLowerCase().includes(searchQuery.toLowerCase())
  )

  // Initialize root once
  useEffect(() => {
    root.current = ReactDOM.createRoot(box.current)
    setMounted(true)
    return () => {
      setMounted(false)
      setTimeout(() => {
        root.current?.unmount()
      }, 0)
    }
  }, [])

  // Initialize Tippy
  useEffect(() => {
    if (!mounted) return

    const element = triggerRef.current
    if (!element || !root.current) return

    // Find the editor-content container
    const editorContent = document.querySelector('.editor-content')
    if (!editorContent) return

    const instance = tippy(element, {
      render(tippyInstance) {
        root.current.render(
          <DropdownContent
            items={filteredOptions}
            selectedValue={value}
            onSelect={onSelect}
            showSearch={showSearch}
            searchQuery={searchQuery}
            onSearchChange={setSearchQuery}
            maxHeight={maxHeight}
            getInstance={() => tippyInstance}
          />
        )
        return { popper: box.current }
      },
      trigger: forceShow ? 'manual' : 'click',
      interactive: true,
      placement,
      appendTo: editorContent,
      animation: false,
      hideOnClick: false,
      theme: null,
      arrow: false,
      offset: [0, 4],
      popperOptions: {
        strategy: 'absolute',
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              mainAxis: true,
              altAxis: true,
              padding: 8,
              rootBoundary: 'document'
            }
          },
          {
            name: 'flip',
            options: {
              padding: 8,
              fallbackPlacements: ['top-start', 'bottom-start', 'right-start', 'left-start']
            }
          }
        ]
      },
      ...(getReferenceClientRect ? { getReferenceClientRect } : {}),
      onShow(instance) {
        setSearchQuery('')
        onOpenChange?.(true)
        // Prevent editor from handling events
        const handleEditorEvents = (e) => {
          if (e.key === 'Escape') {
            e.stopPropagation()
          }
        }
        document.addEventListener('keydown', handleEditorEvents, true)

        // Give time for the content to render
        requestAnimationFrame(() => {
          const firstItem = box.current.querySelector('[role="option"]')
          if (firstItem) {
            firstItem.focus()
          }
        })

        return () => {
          document.removeEventListener('keydown', handleEditorEvents, true)
        }
      },
      onHide() {
        setSearchQuery('')
        onOpenChange?.(false)
        // Return focus to trigger when closing
        triggerRef.current?.focus()
      },
      onClickOutside(instance, event) {
        // Prevent clicks from reaching the editor
        event.stopPropagation()
        instance.hide()
      }
    })

    if (forceShow) {
      instance.show()
    }

    return () => {
      instance.destroy()
    }
  }, [mounted, forceShow, placement, getReferenceClientRect, onOpenChange])

  const triggerElement = React.cloneElement(trigger, {
    ref: triggerRef,
    onClick: (e) => {
      e.stopPropagation()
      trigger.props.onClick?.(e)
    }
  })

  return triggerElement
}

Dropdown.propTypes = {
  trigger: PropTypes.element.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  })).isRequired,
  value: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  showSearch: PropTypes.bool,
  placement: PropTypes.string,
  maxHeight: PropTypes.string,
  forceShow: PropTypes.bool,
  getReferenceClientRect: PropTypes.func,
  onOpenChange: PropTypes.func
} 