import { Extension } from '@tiptap/core'

// Helper function to get non-editable block shortcuts
const getNonEditableShortcuts = (editor) => {
  const preventWhenInNonEditable = () => editor.isActive('nonEditableBlock')
  
  return {
    // Prevent deletion
    Backspace: preventWhenInNonEditable,
    Delete: preventWhenInNonEditable,
    
    // Prevent navigation
    Enter: preventWhenInNonEditable,
    'Shift-Enter': preventWhenInNonEditable,
    Space: preventWhenInNonEditable,
    Tab: preventWhenInNonEditable,
    'Shift-Tab': preventWhenInNonEditable,
    ArrowLeft: preventWhenInNonEditable,
    ArrowRight: preventWhenInNonEditable,
    ArrowUp: preventWhenInNonEditable,
    ArrowDown: preventWhenInNonEditable,
    
    // Prevent common shortcuts
    'Mod-a': preventWhenInNonEditable,
    'Mod-c': preventWhenInNonEditable,
    'Mod-x': preventWhenInNonEditable,
    'Mod-v': preventWhenInNonEditable,
    'Mod-z': preventWhenInNonEditable,
    'Mod-y': preventWhenInNonEditable,
    'Shift-Mod-z': preventWhenInNonEditable
  }
}

export default Extension.create({
  name: 'editorCommands',

  addCommands() {
    return {
      sendToAssistant: (text = null, options = {}) => ({ editor }) => {
        const { category = 'writer', tab = 'sidebar-tabs#assistant', modalId = null, pos = null } = options
        
        // Generate a unique event ID
        const eventId = Date.now().toString()
        
        // Helper to extract text from a clause node
        const getClauseText = (node) => {
          return node && node.type.name === 'clauseBlock' ? node.textContent : null
        }
        
        // Get the text to send
        let textToSend = text
        
        if (!textToSend) {
          const { from, to } = editor.state.selection
          
          // Try different strategies to get text in order of priority:
          textToSend = (
            // 1. If position is explicitly provided (from clause component)
            (pos !== null && getClauseText(editor.state.doc.nodeAt(pos))) ||
            // 2. If there's a text selection
            (from !== to && editor.state.doc.textBetween(from, to, ' ')) ||
            // 3. If cursor is in a clause node
            getClauseText(editor.state.doc.nodeAt(from)) ||
            // 4. No text found
            null
          )
        }
        
        if (!textToSend) {
          console.log('No text found to send to assistant')
          return false
        }
        
        // Dispatch the event
        window.dispatchEvent(new CustomEvent('editor:request-assistant', {
          detail: { 
            text: textToSend,
            category,
            tab,
            modalId,
            eventId
          }
        }))
        console.log('Sending to assistant', { textToSend, category, tab, modalId, eventId })
        
        // Also try to use the global function if available
        if (typeof window.setAssistantInputValue === 'function') {
          window.setAssistantInputValue(textToSend)
        }
        
        return true
      },
      
      review: () => () => {
        // Dispatch event to activate review tab
        window.dispatchEvent(new CustomEvent('sidebar-tabs:activate', {
          detail: { tab: 'review' }
        }))
        return true
      },
      
      convertToClause: (type = null) => ({ commands }) => {
        return commands.wrapIn('clauseBlock', { type })
      },
      
      toggleComment: () => ({ commands, state }) => {
        const { from, to, empty } = state.selection
        
        if (empty) return false
        
        // Check if selection already has a comment
        const marks = state.doc.resolve(from).marks()
        const existingComment = marks.find(mark => mark.type.name === 'comment')
        
        if (existingComment) {
          window.dispatchEvent(new CustomEvent('editor:show-comment', {
            detail: { threadId: existingComment.attrs.threadId }
          }))
          return true
        }
        
        // Create new comment
        window.dispatchEvent(new CustomEvent('editor:create-comment', {
          detail: { 
            selection: {
              from,
              to,
              text: state.doc.textBetween(from, to, ' ')
            }
          }
        }))
        
        return true
      }
    }
  },

  addKeyboardShortcuts() {
    return {
      // Assistant and clause shortcuts
      'Mod-Alt-a': () => {
        console.log('🔍 Sending to assistant with modalId: modal-writer');
        return this.editor.commands.sendToAssistant(null, { 
          tab: 'sidebar-tabs#assistant',
          category: 'writer',
          modalId: 'modal-writer'
        })
      },
      'Mod-Alt-c': () => this.editor.commands.convertToClause(),

      // Non-editable block shortcuts
      ...getNonEditableShortcuts(this.editor),

      'Mod-Alt-m': () => this.editor.commands.toggleComment()
    }
  }
}) 