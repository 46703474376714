import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["heading"]

  connect() {
    console.log("ReviewSync controller connected")
    this.currentHeadingText = null
    window.addEventListener('editor-content-updated', this.handleEditorUpdate.bind(this))
    window.addEventListener('editor-insert-content', this.handleContentInsert.bind(this))
    window.addEventListener('editor-ready', this.setupEditorHandlers.bind(this))
  }

  disconnect() {
    this.currentHeadingText = null
    window.removeEventListener('editor-content-updated', this.handleEditorUpdate.bind(this))
    window.removeEventListener('editor-insert-content', this.handleContentInsert.bind(this))
    window.removeEventListener('editor-ready', this.setupEditorHandlers.bind(this))
    
    // Clean up editor handlers if editor exists
    const editor = this.getEditor()
    if (editor) {
      editor.view.dom.removeEventListener('keydown', this.handleKeydown.bind(this))
      editor.off('focus', this.handleContentInsert.bind(this))
    }
  }

  setupEditorHandlers() {
    console.log("Setting up editor handlers")
    const editor = this.getEditor()
    if (editor) {
      // Set up all editor-related event handlers here
      editor.view.dom.addEventListener('keydown', this.handleKeydown.bind(this))
      editor.on('focus', this.handleContentInsert.bind(this))
    } else {
      console.warn("Editor not ready yet, will retry in 500ms")
      // Retry after a short delay if editor isn't ready
      setTimeout(() => this.setupEditorHandlers(), 500)
    }
  }

  getEditor() {
    const editorContainer = document.querySelector('[data-controller="editor"]')
    if (!editorContainer) {
      console.error("Could not find editor container")
      return null
    }

    const editorView = editorContainer.querySelector('.ProseMirror')
    if (!editorView) {
      console.error("Could not find ProseMirror editor")
      return null
    }

    const editor = editorView.editor
    if (!editor) {
      console.error("Could not find editor instance")
      return null
    }

    return editor
  }

  getHeadings() {
    const editor = this.getEditor()
    if (!editor) return []

    const headings = []
    editor.state.doc.descendants((node, pos) => {
      if (node.type.name === 'heading') {
        headings.push({
          text: node.textContent,
          pos: pos,
          level: node.attrs.level
        })
      }
    })
    return headings
  }

  findNextHeadingPos(startPos, currentLevel) {
    const editor = this.getEditor()
    if (!editor) return null

    let nextHeadingPos = null
    let currentClauseBlockEnd = null

    // First find the current clause block we're in
    editor.state.doc.nodesBetween(startPos, editor.state.doc.content.size, (node, pos) => {
      if (node.type.name === 'clauseBlock' && pos <= startPos) {
        currentClauseBlockEnd = pos + node.nodeSize
        return true // Keep searching for headings
      }
      return true
    })

    // Then find the next heading of same or higher level
    editor.state.doc.nodesBetween(startPos, editor.state.doc.content.size, (node, pos) => {
      if (node.type.name === 'heading' && pos > startPos) {
        if (node.attrs.level <= currentLevel && nextHeadingPos === null) {
          nextHeadingPos = pos
          return false // Stop searching once we find a heading
        }
      }
      return true
    })

    // Return the earlier of: next heading position or end of current clause block
    if (currentClauseBlockEnd && nextHeadingPos) {
      console.log("Found both clause end and next heading:", {
        clauseEnd: currentClauseBlockEnd,
        nextHeading: nextHeadingPos
      })
      return Math.min(currentClauseBlockEnd, nextHeadingPos)
    }
    
    // Return whichever limit we found, or null if neither
    return nextHeadingPos || currentClauseBlockEnd || null
  }

  fuzzyMatch(str1, str2) {
    const normalize = (s) => s.toLowerCase().replace(/^\d+\.\s*/, '')
    const norm1 = normalize(str1)
    const norm2 = normalize(str2)

    if (norm1 === norm2) return 1

    const words1 = norm1.split(/\s+/)
    const words2 = norm2.split(/\s+/)

    let exactWordMatches = 0
    let partialWordMatches = 0
    let sequenceMatches = 0
    
    // Count exact and partial word matches
    for (const word1 of words1) {
      let hasExactMatch = false
      
      for (const word2 of words2) {
        if (word1 === word2) {
          exactWordMatches++
          hasExactMatch = true
          break
        } else if (!hasExactMatch && (word2.includes(word1) || word1.includes(word2))) {
          partialWordMatches++
        }
      }
    }

    // Enhanced sequence matching using normalized strings
    // Find the longest common sequence of words
    let maxSequence = 0
    for (let i = 0; i < words1.length; i++) {
      for (let j = 0; j < words2.length; j++) {
        let currentSequence = 0
        let k = 0
        while (i + k < words1.length && 
               j + k < words2.length && 
               words1[i + k] === words2[j + k]) {
          currentSequence++
          k++
        }
        if (currentSequence > maxSequence) {
          maxSequence = currentSequence
        }
      }
    }
    
    sequenceMatches = maxSequence
    // Add a bonus for longer sequence matches (3+ words)
    if (maxSequence >= 3) {
      sequenceMatches *= 1.5 // 50% bonus for longer sequences
    }

    const exactMatchScore = exactWordMatches / Math.max(words1.length, words2.length) * 0.4
    const partialMatchScore = partialWordMatches / Math.max(words1.length, words2.length) * 0.1
    const sequenceScore = sequenceMatches / Math.max(words1.length, words2.length) * 0.5

    return exactMatchScore + partialMatchScore + sequenceScore
  }

  findBestMatchingHeading(searchText) {
    const headings = this.getHeadings()
    if (headings.length === 0) {
      console.debug("No headings found in document")
      return null
    }

    let bestMatch = null
    let bestScore = 0

    for (const heading of headings) {
      const score = this.fuzzyMatch(searchText, heading.text)
      if (score > bestScore) {
        bestScore = score
        bestMatch = heading
      }
    }

    console.debug("Best match found:", bestMatch ? bestMatch.text : "none", "with score:", bestScore)
    return bestScore > 0.4 ? bestMatch : null
  }

  handleEditorUpdate(event) {
    if (this.currentHeadingText) {
      const bestMatch = this.findBestMatchingHeading(this.currentHeadingText)
      if (bestMatch) {
        this.scrollToHeading(bestMatch)
      }
    }
  }

  scrollToHeading(heading) {
    const editor = this.getEditor()
    if (!editor) return

    console.debug("Scrolling to heading:", {
      text: heading.text,
      pos: heading.pos,
      level: heading.level
    })

    this.currentHeadingText = heading.text

    const domNode = editor.view.nodeDOM(heading.pos)
    if (domNode) {
      domNode.scrollIntoView({ 
        behavior: 'smooth', 
        block: 'start',
        inline: 'nearest'
      })

      const nextHeadingPos = this.findNextHeadingPos(heading.pos, heading.level) || editor.state.doc.content.size
      const headingNode = editor.state.doc.nodeAt(heading.pos)
      const startPos = heading.pos + headingNode.nodeSize

      // Set selection
      const tr = editor.state.tr.setSelection(
        editor.state.selection.constructor.create(
          editor.state.doc,
          startPos,
          nextHeadingPos
        )
      )
      editor.view.dispatch(tr)
      editor.view.focus()
    } else {
      console.error("Could not find DOM node for heading")
    }
  }

  scrollToClause(event) {
    const searchText = event.currentTarget.dataset.heading
    console.debug("Clause clicked:", {
      searchText,
      element: event.currentTarget,
      allDataset: event.currentTarget.dataset
    })

    const bestMatch = this.findBestMatchingHeading(searchText)
    if (bestMatch) {
      this.scrollToHeading(bestMatch)
    } else {
      console.debug("No matching heading found for:", searchText)
    }
  }

  getCurrentRange() {
    const editor = this.getEditor()
    if (!editor) return null;
    
    const { from, to } = editor.state.selection
    
    if (typeof from === 'number' && typeof to === 'number' && 
        from >= 0 && to <= editor.state.doc.content.size && 
        from < to) {
      console.log("Returning current selection:", { from, to })
      return {
        from,
        to,
        headingText: this.currentHeadingText
      }
    }
    
    console.log("No valid selection found")
    return null;
  }

  // Clear selection and current heading after content insertion
  handleContentInsert(event) {
    // Only clear the current heading text tracking
    this.currentHeadingText = null
  }

  handleKeydown(event) {
    // Only handle character keys, Enter, Backspace, Delete
    if (event.key.length === 1 || event.key === 'Enter' || event.key === 'Backspace' || event.key === 'Delete') {
      const editor = this.getEditor()
      if (editor && this.currentHeadingText) {
        // Clear the heading reference and selection
        this.currentHeadingText = null
        const pos = editor.state.selection.to
        const tr = editor.state.tr.setSelection(
          editor.state.selection.constructor.create(
            editor.state.doc,
            pos,
            pos
          )
        )
        editor.view.dispatch(tr)
      }
    }
  }

  smoothScroll(event) {
    event.preventDefault()
    const targetId = event.currentTarget.getAttribute('data-review-sync-target-param')
    const targetElement = document.getElementById(`clause-${targetId}`)
    
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      })
    }
  }

  smoothScrollAndSync(event) {
    event.preventDefault()
    
    // Handle smooth scroll to the review section
    const targetId = event.currentTarget.getAttribute('data-review-sync-target-param')
    const targetElement = document.getElementById(`clause-${targetId}`)
    
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      })
    }

    // Handle editor sync
    const heading = event.currentTarget.getAttribute('data-review-sync-heading')
    const bestMatch = this.findBestMatchingHeading(heading)
    if (bestMatch) {
      this.scrollToHeading(bestMatch)
    }
  }

  scrollToIndex(event) {
    event.preventDefault()
    const indexElement = document.getElementById('review-index')
    if (indexElement) {
      indexElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      })
    }
  }
} 