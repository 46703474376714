import React, { createContext, useState } from 'react'

export const EditorContext = createContext({
  showHelp: true,
  setShowHelp: () => {},
})

export function EditorProvider({ children }) {
  const [showHelp, setShowHelp] = useState(true)

  return (
    <EditorContext.Provider value={{ showHelp, setShowHelp }}>
      {children}
    </EditorContext.Provider>
  )
} 