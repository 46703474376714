import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // Listen for the download event from the React editor
    document.addEventListener("editor:request-download", this.handleDownload)
  }

  disconnect() {
    document.removeEventListener("editor:request-download", this.handleDownload)
  }

  // Action to request HTML from the editor
  requestDownload() {
    document.dispatchEvent(new CustomEvent("editor:request-download"))
  }
} 