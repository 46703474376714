import { Node, mergeAttributes } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import NonEditableComponent from '../components/NonEditableComponent'

export default Node.create({
  name: 'nonEditableBlock',
  group: 'block',
  content: 'block+',
  isolating: true,
  selectable: false,
  draggable: false,
  
  addOptions() {
    return {
      HTMLAttributes: {
        class: 'node-non-editable',
        contenteditable: false,
        'data-user-select': 'none'
      }
    }
  },

  addAttributes() {
    return {
      content: {
        default: '',
        parseHTML: element => element.innerHTML
      }
    }
  },
  
  parseHTML() {
    return [{ tag: 'node[type="non-editable"]' }]
  },
  
  renderHTML({ HTMLAttributes, node }) {
    return [
      'node',
      mergeAttributes(
        this.options.HTMLAttributes,
        { type: 'non-editable' },
        HTMLAttributes
      ),
      node.attrs.content || 0
    ]
  },
  
  addNodeView() {
    return ReactNodeViewRenderer(NonEditableComponent)
  }
}) 