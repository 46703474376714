import { Extension } from '@tiptap/core'
import { Plugin, PluginKey } from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'

// Mock function to simulate API call to LLM
const mockLlmApiCall = async (text, prompt, timeout = 2000) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      // 10% chance of error for testing
      if (Math.random() < 0.1) {
        reject(new Error('Failed to process request'))
        return
      }
      
      // Mock response based on prompt type
      let response
      switch (prompt) {
        case 'extend':
          response = `${text} [Extended with additional relevant information that elaborates on the original text and provides more context and details.]`
          break
        case 'reduce':
          response = text.split(' ').slice(0, Math.max(3, Math.floor(text.split(' ').length / 2))).join(' ') + '...'
          break
        case 'simplify':
          response = `${text} [Simplified version with clearer language]`
          break
        case 'grammar':
          response = text.replace(/\b(a|the|in|on|with)\b/g, match => `<span class="correction">${match}</span>`)
          break
        case 'translate':
          response = `${text} [Translated version]`
          break
        default:
          response = `${text} [Processed by LLM]`
      }
      
      resolve(response)
    }, timeout)
  })
}

// Plugin key for managing LLM suggestion state
export const llmSuggestionPluginKey = new PluginKey('llmSuggestion')

export default Extension.create({
  name: 'llmCommands',

  addOptions() {
    return {
      // Default options
      suggestionClass: 'llm-suggestion',
      loadingClass: 'llm-loading',
      errorClass: 'llm-error'
    }
  },

  addProseMirrorPlugins() {
    const { suggestionClass, loadingClass, errorClass } = this.options
    
    return [
      new Plugin({
        key: llmSuggestionPluginKey,
        state: {
          init() {
            return {
              decorations: DecorationSet.empty,
              activeSuggestions: {},
              loading: {}
            }
          },
          apply(tr, state) {
            // Update decorations based on transaction
            let { decorations, activeSuggestions, loading } = state
            
            // Handle meta information from transactions
            if (tr.getMeta(llmSuggestionPluginKey)) {
              const meta = tr.getMeta(llmSuggestionPluginKey)
              
              if (meta.type === 'startLoading') {
                const { from, to, id } = meta
                loading = { ...loading, [id]: { from, to } }
                
                // Create loading decoration
                const loadingDecoration = Decoration.inline(from, to, {
                  class: loadingClass,
                  'data-suggestion-id': id
                })
                
                decorations = decorations.add(tr.doc, [loadingDecoration])
              }
              
              if (meta.type === 'setSuggestion') {
                const { from, to, id, suggestion } = meta
                
                // Remove loading state
                if (loading[id]) {
                  delete loading[id]
                }
                
                // Store suggestion
                activeSuggestions = { 
                  ...activeSuggestions, 
                  [id]: { from, to, suggestion, originalText: tr.doc.textBetween(from, to) } 
                }
                
                // Create suggestion decoration
                const suggestionDecoration = Decoration.inline(from, to, {
                  class: suggestionClass,
                  'data-suggestion-id': id
                })
                
                decorations = decorations.add(tr.doc, [suggestionDecoration])
              }
              
              if (meta.type === 'removeSuggestion') {
                const { id } = meta
                
                // Remove suggestion
                if (activeSuggestions[id]) {
                  delete activeSuggestions[id]
                }
                
                // Remove decoration
                decorations = decorations.remove(
                  decorations.find(null, null, spec => spec.attrs['data-suggestion-id'] === id)
                )
              }
              
              if (meta.type === 'setError') {
                const { from, to, id } = meta
                
                // Remove loading state
                if (loading[id]) {
                  delete loading[id]
                }
                
                // Create error decoration
                const errorDecoration = Decoration.inline(from, to, {
                  class: errorClass,
                  'data-suggestion-id': id
                })
                
                decorations = decorations.add(tr.doc, [errorDecoration])
              }
              
              return { decorations, activeSuggestions, loading }
            }
            
            // Map decorations through document changes
            return {
              decorations: decorations.map(tr.mapping, tr.doc),
              activeSuggestions,
              loading
            }
          }
        },
        props: {
          decorations(state) {
            return this.getState(state).decorations
          }
        }
      })
    ]
  },

  addCommands() {
    return {
      // Process text with LLM
      processWithLlm: (options = {}) => ({ editor, tr, dispatch }) => {
        const { from, to } = editor.state.selection
        
        // Require a text selection
        if (from === to) {
          console.log('No text selected for LLM processing')
          return false
        }
        
        const selectedText = editor.state.doc.textBetween(from, to)
        const { promptType = 'default' } = options
        const suggestionId = `llm-${Date.now()}`
        
        // Set loading state
        if (dispatch) {
          // Create a transaction to set the loading state
          const loadingTr = tr.setMeta(llmSuggestionPluginKey, {
            type: 'startLoading',
            from,
            to,
            id: suggestionId
          })
          
          // Dispatch the transaction
          dispatch(loadingTr)
          
          // Dispatch loading event for UI
          window.dispatchEvent(new CustomEvent('editor:llm-loading-start', {
            detail: { 
              id: suggestionId,
              from,
              to
            }
          }))
          
          // Log for debugging
          console.log('Loading state set:', suggestionId, from, to)
        }
        
        // Call LLM API (mock for now)
        mockLlmApiCall(selectedText, promptType)
          .then(suggestion => {
            // Set suggestion
            const newTr = editor.state.tr.setMeta(llmSuggestionPluginKey, {
              type: 'setSuggestion',
              from,
              to,
              id: suggestionId,
              suggestion
            })
            editor.view.dispatch(newTr)
            
            // Dispatch event for UI to handle
            window.dispatchEvent(new CustomEvent('editor:llm-suggestion-ready', {
              detail: { 
                id: suggestionId,
                from,
                to,
                originalText: selectedText,
                suggestion,
                promptType
              }
            }))
          })
          .catch(error => {
            console.error('LLM processing error:', error)
            
            // Set error state
            const newTr = editor.state.tr.setMeta(llmSuggestionPluginKey, {
              type: 'setError',
              from,
              to,
              id: suggestionId,
              error: error.message
            })
            editor.view.dispatch(newTr)
            
            // Dispatch error event
            window.dispatchEvent(new CustomEvent('editor:llm-suggestion-error', {
              detail: { 
                id: suggestionId,
                from,
                to,
                originalText: selectedText,
                error: error.message,
                promptType
              }
            }))
          })
        
        return true
      },
      
      // Accept suggestion
      acceptSuggestion: (suggestionId) => ({ editor }) => {
        const pluginState = llmSuggestionPluginKey.getState(editor.state)
        const suggestion = pluginState.activeSuggestions[suggestionId]
        
        if (!suggestion) {
          console.log('Suggestion not found:', suggestionId)
          return false
        }
        
        // Replace text with suggestion
        editor.chain()
          .focus()
          .deleteRange({ from: suggestion.from, to: suggestion.to })
          .insertContentAt(suggestion.from, suggestion.suggestion)
          .run()
        
        // Remove suggestion state
        const tr = editor.state.tr.setMeta(llmSuggestionPluginKey, {
          type: 'removeSuggestion',
          id: suggestionId
        })
        editor.view.dispatch(tr)
        
        return true
      },
      
      // Discard suggestion
      discardSuggestion: (suggestionId) => ({ editor }) => {
        const pluginState = llmSuggestionPluginKey.getState(editor.state)
        const suggestion = pluginState.activeSuggestions[suggestionId]
        
        if (!suggestion) {
          console.log('Suggestion not found:', suggestionId)
          return false
        }
        
        // Remove suggestion state
        const tr = editor.state.tr.setMeta(llmSuggestionPluginKey, {
          type: 'removeSuggestion',
          id: suggestionId
        })
        editor.view.dispatch(tr)
        
        return true
      },
      
      // Retry failed suggestion
      retrySuggestion: (suggestionId) => ({ editor }) => {
        const pluginState = llmSuggestionPluginKey.getState(editor.state)
        const suggestion = pluginState.activeSuggestions[suggestionId]
        
        if (!suggestion) {
          console.log('Suggestion not found:', suggestionId)
          return false
        }
        
        // Remove error state
        const tr = editor.state.tr.setMeta(llmSuggestionPluginKey, {
          type: 'removeSuggestion',
          id: suggestionId
        })
        editor.view.dispatch(tr)
        
        // Retry processing
        return editor.commands.processWithLlm({
          promptType: suggestion.promptType
        })
      }
    }
  }
}) 