import { Controller } from "@hotwired/stimulus"

/**
 * A simple modal controller that handles animations and responsive positioning
 * 
 * Example markup:
 * ```html
 * <div data-controller="modal-simple" data-modal-id="unique-modal-id">
 *   <!-- Trigger button -->
 *   <button data-action="modal-simple#open">Open Modal</button>
 * </div>
 * 
 * <!-- Modal container (will be moved to body) -->
 * <div class="modal-simple-container" data-modal-simple-target="container" data-modal-simple-id="unique-modal-id" data-show="false">
 *   <!-- Overlay with click to close -->
 *   <div class="modal-simple-overlay" data-action="click->modal-simple#close"></div>
 * 
 *   <!-- Modal content -->
 *   <div class="modal-simple-content">
 *     <!-- Header with title and close button -->
 *     <div class="modal-simple-header">
 *       <h3 class="modal-simple-title">Modal Title</h3>
 *       <button class="modal-simple-close" data-action="modal-simple#close">×</button>
 *     </div>
 * 
 *     <!-- Body with scrollable content -->
 *     <div class="modal-simple-body">
 *       Modal content goes here...
 *     </div>
 *   </div>
 * </div>
 * ```
 * 
 * The controller will:
 * 1. Handle modal visibility
 * 2. Manage smooth animations for opening/closing
 * 3. Handle overlay and click-outside behavior
 * 4. Manage focus and keyboard accessibility
 */
export default class extends Controller {
  static targets = ["container"]
  static values = {
    preventDuplicates: { type: Boolean, default: true }
  }
  
  connect() {
    // Add event listener for keyboard events
    this.keyDownHandler = this.handleKeyDown.bind(this)
    document.addEventListener("keydown", this.keyDownHandler)
    
    // Store the trigger element for focus management
    this.triggerElement = null
    
    // Add a global click handler for close actions from moved containers
    this.closeHandler = this.handleGlobalClose.bind(this)
    document.addEventListener("click", this.closeHandler)
  }
  
  disconnect() {
    // Clean up event listeners
    document.removeEventListener("keydown", this.keyDownHandler)
    document.removeEventListener("click", this.closeHandler)
    
    // If we moved the container back to its original parent, move it back
    const container = this.findContainer()
    if (this.containerWasMoved && this.originalParent && container) {
      this.originalParent.appendChild(container)
    }
  }

  /**
   * Handle global click events for close actions from moved containers
   * @param {Event} event - The click event
   */
  handleGlobalClose(event) {
    const modalId = this.element.dataset.modalId
    if (!modalId) return

    // Check if the clicked element is within a modal with matching ID
    const clickedContainer = event.target.closest('[data-modal-simple-id]')
    if (!clickedContainer || clickedContainer.dataset.modalSimpleId !== modalId) return

    // Check if the click was on a close trigger
    const isCloseTrigger = event.target.closest('[data-action*="modal-simple#close"]')
    if (isCloseTrigger) {
      this.close(event)
    }
  }

  /**
   * Opens the modal
   * @param {Event} event - The triggering event (optional)
   */
  open(event) {
    // Store the element that triggered the modal for focus restoration
    if (event && event.currentTarget) {
      this.triggerElement = event.currentTarget
    }
    
    // Get the modal ID from the controller element
    const modalId = this.element.dataset.modalId
    
    // If we're preventing duplicates and a modal with this ID already exists in the body
    if (this.preventDuplicatesValue && modalId) {
      const existingModal = document.body.querySelector(`#${modalId}[data-show="true"]`)
      if (existingModal) {
        console.log(`Modal with ID ${modalId} is already open, focusing it instead of creating a duplicate`)
        
        // Focus the existing modal
        const focusableElements = this.getFocusableElements(existingModal)
        if (focusableElements.length > 0) {
          focusableElements[0].focus()
        }
        
        return
      }
    }
    
    // Find the container within the controller's element if it's not already a target
    let container = this.hasContainerTarget ? this.containerTarget : this.findContainer()
    
    if (!container) {
      console.error("Modal container not found")
      return
    }
    
    // Move the modal container to the body to avoid stacking context issues
    if (container.parentElement !== document.body) {
      this.originalParent = container.parentElement
      document.body.appendChild(container)
      this.containerWasMoved = true
    }
    
    // Show the modal
    container.setAttribute("data-show", "true")
    
    // Set aria-expanded to true on the trigger
    if (this.triggerElement) {
      this.triggerElement.setAttribute("aria-expanded", "true")
    }
    
    // Find the first focusable element and focus it
    setTimeout(() => {
      const focusableElements = this.getFocusableElements(container)
      if (focusableElements.length > 0) {
        focusableElements[0].focus()
      }
    }, 100)
    
    // Prevent scrolling on the body
    document.body.style.overflow = "hidden"

    // Make other content inert
    this.makeOtherContentInert(container)
  }

  /**
   * Closes the modal
   * @param {Event} event - The triggering event
   */
  close(event) {
    // Stop event from bubbling to prevent double-closing
    event?.stopPropagation()
    
    // Find the container
    let container = this.hasContainerTarget ? this.containerTarget : this.findContainer()
    
    if (!container) {
      console.error("Modal container not found")
      return
    }
    
    // Hide the modal
    container.setAttribute("data-show", "false")
    
    // Set aria-expanded to false on the trigger
    if (this.triggerElement) {
      this.triggerElement.setAttribute("aria-expanded", "false")
      
      // Return focus to the trigger element
      setTimeout(() => {
        this.triggerElement.focus()
      }, 100)
    }
    
    // Restore scrolling on the body
    document.body.style.overflow = ""

    // Restore other content from being inert
    this.restoreOtherContent()
  }

  /**
   * Makes other content inert while modal is open
   * @param {HTMLElement} modalContainer - The modal container element
   */
  makeOtherContentInert(modalContainer) {
    // Store elements that were already inert
    this.previouslyInert = Array.from(document.querySelectorAll('[inert]'))
    
    // Make all direct body children except the modal inert
    Array.from(document.body.children).forEach(child => {
      if (child !== modalContainer && !child.hasAttribute('inert')) {
        child.setAttribute('inert', '')
      }
    })
  }

  /**
   * Restores content from being inert
   */
  restoreOtherContent() {
    // Remove inert from all elements except those that were previously inert
    Array.from(document.querySelectorAll('[inert]')).forEach(element => {
      if (!this.previouslyInert?.includes(element)) {
        element.removeAttribute('inert')
      }
    })
  }
  
  /**
   * Find the modal container if it's not already a target
   * @returns {HTMLElement} The modal container
   */
  findContainer() {
    // First try to find it within the controller's element
    let container = this.element.querySelector(".modal-simple-container")
    
    // If not found, try to find it in the document body (it might have been moved)
    if (!container) {
      const id = this.element.getAttribute("data-modal-id")
      if (id) {
        // Check if a modal with this ID already exists in the body
        const existingModal = document.body.querySelector(`#${id}`)
        if (existingModal) {
          // If we're preventing duplicates, use the existing modal
          if (this.preventDuplicatesValue) {
            return existingModal
          }
        }
        
        container = document.getElementById(id)
      }
    }
    
    // If still not found, try to find it by data attribute in the document body
    if (!container) {
      container = document.querySelector(`[data-modal-simple-target="container"]`)
    }
    
    return container
  }
  
  /**
   * Handle keyboard events for accessibility
   * @param {KeyboardEvent} event - The keyboard event
   */
  handleKeyDown(event) {
    // Find the container
    let container = this.hasContainerTarget ? this.containerTarget : this.findContainer()
    
    if (!container) return
    
    // Only process if modal is open
    if (container.getAttribute("data-show") !== "true") {
      return
    }
    
    // Close on Escape key
    if (event.key === "Escape") {
      this.close(event)
      return
    }
    
    // Trap focus within modal with Tab key
    if (event.key === "Tab") {
      this.handleTabKey(event, container)
    }
  }
  
  /**
   * Handle Tab key to trap focus within the modal
   * @param {KeyboardEvent} event - The keyboard event
   * @param {HTMLElement} container - The modal container
   */
  handleTabKey(event, container) {
    const focusableElements = this.getFocusableElements(container)
    
    if (focusableElements.length === 0) return
    
    const firstElement = focusableElements[0]
    const lastElement = focusableElements[focusableElements.length - 1]
    
    // If shift+tab on first element, move to last element
    if (event.shiftKey && document.activeElement === firstElement) {
      lastElement.focus()
      event.preventDefault()
    } 
    // If tab on last element, move to first element
    else if (!event.shiftKey && document.activeElement === lastElement) {
      firstElement.focus()
      event.preventDefault()
    }
  }
  
  /**
   * Get all focusable elements within the modal
   * @param {HTMLElement} container - The modal container
   * @returns {Array} Array of focusable elements
   */
  getFocusableElements(container) {
    const modalContainer = container || (this.hasContainerTarget ? this.containerTarget : this.findContainer())
    
    if (!modalContainer) return []
    
    return Array.from(
      modalContainer.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      )
    )
  }
} 