import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container"]
  static values = {
    documentId: String,
    userName: String,
    userAvatar: String
  }

  connect() {
    this.users = []
    this.setupPresenceChannel()
  }

  setupPresenceChannel() {
    // We'll receive presence updates from the editor through a custom event
    document.addEventListener("editor:presence-update", (event) => {
      this.users = event.detail.users
      this.updateUI()
    })
  }

  updateUI() {
    if (!this.hasContainerTarget) return

    const usersHtml = this.users.map(user => this.userTemplate(user)).join('')
    
    this.containerTarget.innerHTML = `
      <div class="users-list-container relative flex items-center space-x-2">
        <div class="flex -space-x-2">
          ${usersHtml}
        </div>
        
        <div class="users-list hidden absolute top-full right-0 mt-2 w-48 bg-white rounded-lg shadow-lg border border-gray-200 z-50 py-1">
          ${this.users.map(user => this.userListItemTemplate(user)).join('')}
        </div>
      </div>
    `

    // Setup hover events
    const container = this.containerTarget.querySelector('.users-list-container')
    const usersList = container.querySelector('.users-list')
    
    container.addEventListener('mouseenter', () => usersList.classList.remove('hidden'))
    container.addEventListener('mouseleave', () => usersList.classList.add('hidden'))
    
    // Setup click events for user focus
    this.containerTarget.querySelectorAll('[data-user-focus]').forEach(element => {
      element.addEventListener('click', (e) => {
        const userName = e.currentTarget.dataset.userFocus
        document.dispatchEvent(new CustomEvent("presence:focus-user", {
          detail: { userName }
        }))
        usersList.classList.add('hidden')
      })
    })
  }

  userTemplate(user) {
    return `
      <div class="user-avatar relative inline-block" title="${user.name}">
        <div
          class="w-8 h-8 rounded-full border-2 border-white flex items-center justify-center text-white text-sm font-medium"
          style="background-color: ${user.color}"
        >
          ${user.name.charAt(0).toUpperCase()}
        </div>
        ${user.cursor 
          ? `<div 
              class="absolute -bottom-1 -right-1 w-3 h-3 rounded-full border-2 border-white"
              style="background-color: ${user.color}"
            ></div>`
          : ''
        }
      </div>
    `
  }

  userListItemTemplate(user) {
    const cursorClass = user.cursor ? 'text-gray-900 cursor-pointer' : 'text-gray-400'
    return `
      <button
        class="w-full px-4 py-2 hover:bg-gray-50 flex items-center space-x-2 ${cursorClass}"
        ${user.cursor ? `data-user-focus="${user.name}"` : 'disabled'}
        title="${user.cursor ? 'Click to jump to user' : 'User not currently editing'}"
      >
        <div class="relative">
          <div
            class="w-6 h-6 rounded-full flex items-center justify-center text-white text-xs font-medium"
            style="background-color: ${user.color}"
          >
            ${user.name.charAt(0).toUpperCase()}
          </div>
          ${user.cursor
            ? `<div 
                class="absolute -bottom-0.5 -right-0.5 w-2 h-2 rounded-full border border-white"
                style="background-color: ${user.color}"
              ></div>`
            : ''
          }
        </div>
        <span class="${cursorClass}">
          ${user.name}
        </span>
      </button>
    `
  }

  disconnect() {
    document.removeEventListener("editor:presence-update", this.updateUI)
  }
} 