import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static targets = ["modalOpenButton", "modalCloseButton", "modalContainer", "modalContent", "modalForm", "modalEdit"]
  static values = {
    activeClass: String,
    noActiveClass: String,
    closeUrl: String,
    openUrl: String,
    openOnConnect: Boolean,
    openEventName: String
  }

  connect(){
    // Handle browser back/forward buttons
    this._boundHandlePopState = this._handlePopState.bind(this)
    window.addEventListener('popstate', this._boundHandlePopState)

    if (this.openOnConnectValue) {
      this.openModal();
    }
  }

  disconnect() {
    this._bodyElement.classList.remove('form-modal')
    window.removeEventListener('popstate', this._boundHandlePopState)
  }

  _handlePopState(event) {
    if (this.isModalOpen) {
      const currentContainer = this.modalContainerTargets.find( element =>
        element.classList.contains(this._activeClass())
      )

      const currentContent = this.modalContentTargets.find( element =>
        element.classList.contains(this._activeClass())
      )

      currentContent?.classList.add(this._noActiveClass())
      setTimeout(() => {
        currentContainer?.classList.add(this._noActiveClass())
        setTimeout(() => {
          currentContainer?.classList.remove(this._activeClass())
          currentContent?.classList.remove(this._activeClass())
        }, 150)
      }, 150)
      this._bodyElement.classList.remove('overflow-y-hidden', 'sticky', 'form-modal')
      window.scrollTo({
        top: this.scrollPosition,
        behavior: 'smooth'
      });
      this._bodyElement.style.top = 0;

      const displayOnCloseElement = document.querySelector("[data-display-on-close-modal]")
      if (displayOnCloseElement) {
        displayOnCloseElement.style.display='';
      }

      if (this.hasCloseUrlValue) {
        this._updateTurboState(this.closeUrlValue)
      }
    }
  }

  closeModal(event) {
    const currentContainer = this.modalContainerTargets.find( element =>
      element.classList.contains(this._activeClass())
    )

    const currentContent = this.modalContentTargets.find( element =>
      element.classList.contains(this._activeClass())
    )

    currentContent?.classList.add(this._noActiveClass())
    setTimeout(() => {
      currentContainer?.classList.add(this._noActiveClass())
      setTimeout(() => {
        currentContainer?.classList.remove(this._activeClass())
        currentContent?.classList.remove(this._activeClass())
      }, 150)
    }, 150)
    this._bodyElement.classList.remove('overflow-y-hidden', 'sticky', 'form-modal')
    window.scrollTo({
      top: this.scrollPosition,
      behavior: 'smooth'
    });
    this._bodyElement.style.top = 0;
    if (this.hasOpenUrlValue && this.hasCloseUrlValue){
      this._maybeUpdateUrl(this.closeUrlValue)
    }

    const displayOnCloseElement = document.querySelector("[data-display-on-close-modal]")
    if (displayOnCloseElement) {
      displayOnCloseElement.style.display='';
    }
  }

  openModal(){
    this.scrollPosition = window.pageYOffset;
    this.modalContainerTarget.classList.remove(this._noActiveClass())
    this.modalContainerTarget.classList.add(this._activeClass())
    this.modalContentTarget.classList.add(this._activeClass())
    this.modalContentTarget.classList.remove(this._noActiveClass())

    this._bodyElement.classList.add('overflow-y-hidden', 'sticky', 'form-modal')
    this._bodyElement.style.top = `${-this.scrollPosition}px`;

    if (this.hasOpenUrlValue && this.hasCloseUrlValue){
      this._maybeUpdateUrl(this.openUrlValue)
    }
  }

  openModalThroughEvent(event){
    if (this.hasOpenEventNameValue && this.openEventNameValue === event.detail.openEventName) {
      this.openModal()
    }
  }

  submitModal() {
    if(this.hasModalFormTarget) {
      this.modalFormTarget.requestSubmit()
    }
  }

  _maybeUpdateUrl(newUrl){
    if (!window.location.href.endsWith(newUrl)){
      if (newUrl === this.closeUrlValue) {
        this._updateTurboState(newUrl)
      } else {
        history.pushState({ modalUrl: newUrl }, '', newUrl)
      }
    }
  }

  _updateTurboState(newUrl) {
    Turbo.navigator.currentLocation = new URL(newUrl, window.location.origin)
    Turbo.navigator.view.lastRenderedLocation = Turbo.navigator.currentLocation
    history.replaceState(
      { turbo: { restorationIdentifier: Turbo.navigator.currentLocation.toString() } },
      '',
      newUrl
    )
  }

  get isModalOpen() {
    return this.modalContainerTargets.some(container =>
      container.classList.contains(this._activeClass())
    )
  }

  _activeClass(){
    return this.activeClassValue || 'active-animated'
  }

  _noActiveClass(){
    return this.noActiveClassValue || 'no-active-animated'
  }

  get _bodyElement() {
    return document.body;
  }
}
