import React, { useState, useRef, useEffect, useMemo } from 'react'
import { BubbleMenu } from '@tiptap/react'
import {
  SparklesIcon,
  DocumentTextIcon,
  PencilIcon,
  DocumentPlusIcon,
  ChevronDownIcon,
  PencilSquareIcon,
  LanguageIcon,
  DocumentDuplicateIcon,
  DocumentMinusIcon,
  DocumentMagnifyingGlassIcon,
  CheckBadgeIcon,
  ClipboardDocumentCheckIcon,
  ChatBubbleLeftIcon,
  TrashIcon
} from '@heroicons/react/24/outline'

// Animation durations - keep these in sync with CSS
const ANIMATION = {
  ENTER_DURATION: 150,
  EXIT_DURATION: 150
}

// Environment check utility
const isProduction = process.env.NODE_ENV === 'production'

// Add CSS for animations
const dropdownAnimationCSS = `
  .dropdown-enter {
    opacity: 0;
    transform: translateY(-5px);
  }
  .dropdown-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity ${ANIMATION.ENTER_DURATION}ms, transform ${ANIMATION.ENTER_DURATION}ms;
  }
  .dropdown-exit {
    opacity: 1;
    transform: translateY(0);
  }
  .dropdown-exit-active {
    opacity: 0;
    transform: translateY(-5px);
    transition: opacity ${ANIMATION.EXIT_DURATION}ms, transform ${ANIMATION.EXIT_DURATION}ms;
  }
  
  .submenu-enter {
    opacity: 0;
    transform: translateX(-5px);
  }
  .submenu-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity ${ANIMATION.ENTER_DURATION}ms, transform ${ANIMATION.ENTER_DURATION}ms;
  }
  .submenu-exit {
    opacity: 1;
    transform: translateX(0);
  }
  .submenu-exit-active {
    opacity: 0;
    transform: translateX(-5px);
    transition: opacity ${ANIMATION.EXIT_DURATION}ms, transform ${ANIMATION.EXIT_DURATION}ms;
  }
`

const TextBubbleMenu = ({ editor }) => {
  // Track active dropdowns as an array to maintain parent-child relationships
  const [activeDropdowns, setActiveDropdowns] = useState([])
  // Track visible dropdowns for animation (may include dropdowns that are animating out)
  const [visibleDropdowns, setVisibleDropdowns] = useState([])
  const menuRef = useRef(null)
  const closeTimeoutRef = useRef({})
  const animationTimeoutRef = useRef({})
  
  // Get comment state from editor
  const hasComment = editor?.isActive('comment') || false
  
  // Inject the animation CSS once
  useEffect(() => {
    const styleId = 'dropdown-animation-styles'
    if (!document.getElementById(styleId)) {
      const styleEl = document.createElement('style')
      styleEl.id = styleId
      styleEl.innerHTML = dropdownAnimationCSS
      document.head.appendChild(styleEl)
      
      return () => {
        const existingStyle = document.getElementById(styleId)
        if (existingStyle) {
          document.head.removeChild(existingStyle)
        }
      }
    }
  }, [])

  // Handle click outside to close dropdowns
  useEffect(() => {
    if (!editor) return;
    
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setActiveDropdowns([])
        // Keep visible dropdowns for animation
        setTimeout(() => {
          setVisibleDropdowns([])
        }, ANIMATION.EXIT_DURATION)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    
    // Cleanup function
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      // Clear all timeouts
      clearAllTimeouts()
    }
  }, [editor])
  
  // Clear all timeouts to prevent memory leaks
  const clearAllTimeouts = () => {
    Object.values(closeTimeoutRef.current).forEach(timeout => {
      if (timeout) clearTimeout(timeout)
    })
    Object.values(animationTimeoutRef.current).forEach(timeout => {
      if (timeout) clearTimeout(timeout)
    })
    closeTimeoutRef.current = {}
    animationTimeoutRef.current = {}
  }

  const closeAllDropdowns = () => {
    setActiveDropdowns([])
    // Keep visible dropdowns for animation
    setTimeout(() => {
      setVisibleDropdowns([])
    }, ANIMATION.EXIT_DURATION)
  }

  const isDropdownActive = (key) => {
    return activeDropdowns.includes(key)
  }

  const isDropdownVisible = (key) => {
    return visibleDropdowns.includes(key)
  }

  const isParentActive = (key) => {
    // Check if any parent of this dropdown is active
    const parts = key.split('-')
    if (parts.length <= 1) return true // Top-level items are always considered to have active parents
    
    // Create parent key by removing the last part
    const parentKey = parts.slice(0, parts.length - 1).join('-')
    return isDropdownActive(parentKey)
  }

  const toggleDropdown = (key, event) => {
    if (event) {
      event.stopPropagation()
    }
    
    // Clear any pending close operations for this key and its children
    clearCloseTimeout(key)
    
    if (isDropdownActive(key)) {
      // If active, schedule to close
      setActiveDropdowns(prev => prev.filter(k => !k.startsWith(key)))
      
      // Keep in visible list for animation
      setTimeout(() => {
        setVisibleDropdowns(prev => prev.filter(k => !k.startsWith(key)))
      }, ANIMATION.EXIT_DURATION)
    } else {
      // If not active, open it
      
      // First, remove any siblings at the same level
      const keyParts = key.split('-')
      const parentKey = keyParts.slice(0, keyParts.length - 1).join('-')
      const level = keyParts.length
      
      // Update both states in one batch to avoid unnecessary re-renders
      const updateDropdowns = (prev) => {
        const filtered = prev.filter(k => {
          const kParts = k.split('-')
          // Keep if it's not at the same level or not under the same parent
          return kParts.length !== level || !k.startsWith(parentKey)
        })
        
        return [...filtered, key]
      }
      
      setActiveDropdowns(updateDropdowns)
      setVisibleDropdowns(updateDropdowns)
    }
  }

  const clearCloseTimeout = (key) => {
    // Clear timeout for this key and all its children
    Object.keys(closeTimeoutRef.current).forEach(timeoutKey => {
      if (timeoutKey === key || timeoutKey.startsWith(`${key}-`)) {
        if (closeTimeoutRef.current[timeoutKey]) {
          clearTimeout(closeTimeoutRef.current[timeoutKey])
          closeTimeoutRef.current[timeoutKey] = null
        }
      }
    })
    
    // Also clear animation timeouts
    Object.keys(animationTimeoutRef.current).forEach(timeoutKey => {
      if (timeoutKey === key || timeoutKey.startsWith(`${key}-`)) {
        if (animationTimeoutRef.current[timeoutKey]) {
          clearTimeout(animationTimeoutRef.current[timeoutKey])
          animationTimeoutRef.current[timeoutKey] = null
        }
      }
    })
  }

  const scheduleClose = (key, delay = 150) => {
    clearCloseTimeout(key)
    
    closeTimeoutRef.current[key] = setTimeout(() => {
      // Remove from active list
      setActiveDropdowns(prev => prev.filter(k => k !== key && !k.startsWith(`${key}-`)))
      
      // Keep in visible list for animation
      animationTimeoutRef.current[key] = setTimeout(() => {
        setVisibleDropdowns(prev => prev.filter(k => k !== key && !k.startsWith(`${key}-`)))
        animationTimeoutRef.current[key] = null
      }, ANIMATION.EXIT_DURATION)
      
      closeTimeoutRef.current[key] = null
    }, delay)
  }

  const handleMouseEnter = (key) => {
    // Clear any pending close operations for this key and its children
    clearCloseTimeout(key)
    
    // If parent is active, activate this dropdown immediately
    if (isParentActive(key)) {
      // Add this key to active dropdowns if not already active
      if (!isDropdownActive(key)) {
        setActiveDropdowns(prev => [...prev, key])
        setVisibleDropdowns(prev => [...prev, key])
      }
    }
  }

  const handleMouseLeave = (key) => {
    // Schedule this dropdown to close after a delay
    scheduleClose(key)
  }
  
  // Handle keyboard navigation
  const handleKeyDown = (e, key, hasSubItems) => {
    switch (e.key) {
      case 'Enter':
      case ' ':
        e.preventDefault()
        if (hasSubItems) {
          toggleDropdown(key, e)
        } else {
          // Will be implemented later
          console.log(`Selected: ${key}`)
          closeAllDropdowns()
        }
        break
      case 'Escape':
        e.preventDefault()
        closeAllDropdowns()
        break
      default:
        break
    }
  }

  // Handle menu item selection
  const handleMenuItemSelect = (item) => {
    if (!editor) return
    
    closeAllDropdowns()
    
    // Process the selected item
    switch (item.action) {
      case 'processWithLlm':
        // Store current selection
        const { from, to } = editor.state.selection
        
        editor.commands.processWithLlm({ promptType: item.promptType })
        
        // Force the BubbleMenu to close without changing cursor position
        // This works by temporarily deselecting and then restoring the selection
        setTimeout(() => {
          // Create a temporary transaction that doesn't change the document
          const tr = editor.state.tr
          
          // First clear the selection to hide the bubble menu
          tr.setSelection(editor.state.selection.constructor.near(tr.doc.resolve(from), 0))
          editor.view.dispatch(tr)
          
          // Then immediately restore the original selection
          setTimeout(() => {
            const restoreTr = editor.state.tr
            restoreTr.setSelection(editor.state.selection.constructor.create(
              restoreTr.doc,
              from,
              to
            ))
            editor.view.dispatch(restoreTr)
          }, 10)
        }, 50)
        break
      case 'convertToClause':
        editor.commands.convertToClause()
        break
      case 'sendToAssistant':
        editor.commands.sendToAssistant()
        break
      case 'toggleComment':
        editor.commands.toggleComment()
        break
      case 'review':
        editor.commands.review()
        break
      default:
        console.log(`Action not implemented: ${item.action}`)
    }
  }

  const renderDropdownContent = (items, parentKey = '', level = 0) => {
    const isSubmenu = level > 0
    const animationClass = isSubmenu ? 'submenu' : 'dropdown'
    
    return (
      <div 
        className={`absolute z-50 bg-white rounded-md shadow-lg border border-gray-200 py-1 min-w-48 ${
          isSubmenu ? 'left-full top-0 -mt-1' : 'top-full left-0 mt-1'
        } ${isDropdownActive(parentKey) ? `${animationClass}-enter ${animationClass}-enter-active` : `${animationClass}-exit ${animationClass}-exit-active`}`}
        onMouseEnter={() => clearCloseTimeout(parentKey)}
        onMouseLeave={() => scheduleClose(parentKey)}
        role="menu"
        aria-orientation={isSubmenu ? "vertical" : "vertical"}
      >
        {items.map((item, index) => {
          const key = parentKey ? `${parentKey}-${index}` : `${index}`
          const hasSubItems = item.subItems && item.subItems.length > 0
          
          return (
            <div 
              key={key} 
              className="relative"
              onMouseEnter={() => handleMouseEnter(key)}
              onMouseLeave={() => handleMouseLeave(key)}
            >
              <button 
                className={`w-full text-left px-3 py-1.5 flex items-center ${
                  hasSubItems ? 'justify-between' : ''
                } hover:bg-gray-100 whitespace-nowrap`}
                onClick={(e) => {
                  e.stopPropagation()
                  if (hasSubItems) {
                    toggleDropdown(key, e)
                  } else {
                    handleMenuItemSelect(item)
                  }
                }}
                onKeyDown={(e) => handleKeyDown(e, key, hasSubItems)}
                role="menuitem"
                aria-haspopup={hasSubItems ? "true" : "false"}
                aria-expanded={hasSubItems && isDropdownActive(key) ? "true" : "false"}
                tabIndex={0}
              >
                <div className="flex items-center">
                  {item.icon && <item.icon className="w-4 h-4 mr-2 text-gray-500" />}
                  <span>{item.label}</span>
                </div>
                {hasSubItems && (
                  <ChevronDownIcon className="w-3 h-3 text-gray-500 rotate-[-90deg] ml-2" aria-hidden="true" />
                )}
              </button>
              
              {hasSubItems && isDropdownVisible(key) && (
                renderDropdownContent(item.subItems, key, level + 1)
              )}
            </div>
          )
        })}
      </div>
    )
  }

  const menuOptions = useMemo(() => {
    // Base options that are always available
    const baseOptions = [
      {
        key: 'sendToAssistant',
        label: 'Asistente',
        icon: SparklesIcon,
        action: 'sendToAssistant'
      },
      { 
        key: 'comment',
        label: hasComment ? 'Borrar comentario' : 'Comentar',
        icon: hasComment ? TrashIcon : ChatBubbleLeftIcon,
        action: 'toggleComment'
      },
      { 
        key: 'review',
        label: 'Revisar', 
        icon: ClipboardDocumentCheckIcon,
        action: 'review'
      },
      { 
        key: 'convertToClause',
        label: 'Convertir a cláusula', 
        icon: DocumentTextIcon,
        action: 'convertToClause'
      }
    ]

    // Options only available in non-production environments
    const devOptions = [
      {
        key: 'modify',
        label: 'Modificar',
        icon: PencilIcon,
        subItems: [
          { 
            label: 'Extender', 
            icon: DocumentDuplicateIcon,
            action: 'processWithLlm',
            promptType: 'extend'
          },
          { 
            label: 'Reducir', 
            icon: DocumentMinusIcon,
            action: 'processWithLlm',
            promptType: 'reduce'
          },
          { 
            label: 'Simplificar', 
            icon: DocumentMagnifyingGlassIcon,
            action: 'processWithLlm',
            promptType: 'simplify'
          },
          { 
            label: 'Revisar gramática', 
            icon: CheckBadgeIcon,
            action: 'processWithLlm',
            promptType: 'grammar'
          },
          { 
            label: 'Traducir', 
            icon: LanguageIcon,
            subItems: [
              { 
                label: 'Castellano',
                action: 'processWithLlm',
                promptType: 'translate-es'
              },
              { 
                label: 'Catalán',
                action: 'processWithLlm',
                promptType: 'translate-ca'
              },
              { 
                label: 'Euskera',
                action: 'processWithLlm',
                promptType: 'translate-eu'
              },
              { 
                label: 'Gallego',
                action: 'processWithLlm',
                promptType: 'translate-gl'
              },
              { 
                label: 'Inglés',
                action: 'processWithLlm',
                promptType: 'translate-en'
              }
            ]
          }
        ]
      },
      {
        key: 'draft',
        label: 'Redactar',
        icon: DocumentPlusIcon,
        subItems: [
          { 
            label: 'Licitación',
            subItems: [
              { 
                label: 'Memoria/PCAP',
                subItems: [
                  { 
                    label: 'Necesidad',
                    action: 'processWithLlm',
                    promptType: 'draft-necesidad'
                  },
                  { 
                    label: 'Elección de procedimiento',
                    action: 'processWithLlm',
                    promptType: 'draft-procedimiento'
                  },
                  { 
                    label: 'Objeto del contrato',
                    action: 'processWithLlm',
                    promptType: 'draft-objeto'
                  },
                  { 
                    label: 'CPVs',
                    action: 'processWithLlm',
                    promptType: 'draft-cpvs'
                  },
                  { 
                    label: 'División en lotes',
                    action: 'processWithLlm',
                    promptType: 'draft-lotes'
                  },
                  { 
                    label: 'Solvencias',
                    action: 'processWithLlm',
                    promptType: 'draft-solvencias'
                  },
                  { 
                    label: 'PBL',
                    action: 'processWithLlm',
                    promptType: 'draft-pbl'
                  },
                  { 
                    label: 'Criterios adjudicación',
                    action: 'processWithLlm',
                    promptType: 'draft-criterios'
                  },
                  { 
                    label: 'CEE',
                    action: 'processWithLlm',
                    promptType: 'draft-cee'
                  },
                  { 
                    label: 'Modificaciones',
                    action: 'processWithLlm',
                    promptType: 'draft-modificaciones'
                  },
                  { 
                    label: 'Penalidades',
                    action: 'processWithLlm',
                    promptType: 'draft-penalidades'
                  },
                  { 
                    label: 'Responsable del contrato',
                    action: 'processWithLlm',
                    promptType: 'draft-responsable'
                  },
                  { 
                    label: 'Otras cláusulas',
                    action: 'processWithLlm',
                    promptType: 'draft-otras'
                  }
                ]
              },
              { 
                label: 'PPT',
                action: 'processWithLlm',
                promptType: 'draft-ppt'
              }
            ]
          },
          { 
            label: 'Adjudicación',
            subItems: [
              { 
                label: 'Comprobación documentación',
                action: 'processWithLlm',
                promptType: 'draft-comprobacion'
              },
              { 
                label: 'Evaluación ofertas',
                action: 'processWithLlm',
                promptType: 'draft-evaluacion'
              },
              { 
                label: 'Actas de mesas',
                action: 'processWithLlm',
                promptType: 'draft-actas'
              }
            ]
          },
          { 
            label: 'Ejecución',
            subItems: [
              { 
                label: 'Informes de prórrogas',
                action: 'processWithLlm',
                promptType: 'draft-prorrogas'
              }
            ]
          }
        ]
      }  
    ]

    return isProduction ? baseOptions : [...baseOptions, ...devOptions]
  }, [hasComment]);

  // Early return if no editor
  if (!editor) {
    return null
  }

  return (
    <BubbleMenu
      className="text-tiny flex items-center gap-1 rounded-lg bg-white shadow-lg border border-gray-200 p-1 leading-tight"
      tippyOptions={{
        duration: 100,
        maxWidth: 'auto',
        width: 'auto',
        placement: 'top',
        offset: [0, 6],
        zIndex: 1000
      }}
      editor={editor}
      shouldShow={({ editor, state }) => {
        // Only show when there's text selected and we're not in a clause block
        const { empty, from, to } = state.selection
        const isTextSelected = !empty && from !== to
        // const isInClauseBlock = editor.isActive('clauseBlock')
        return isTextSelected // && !isInClauseBlock
      }}
    >
      <div 
        ref={menuRef} 
        className="flex items-center gap-1"
        role="menubar"
        aria-label="Text formatting options"
      >
        {menuOptions.map((option, index) => {
          const key = `${index}`
          const hasSubItems = option.subItems && option.subItems.length > 0;
          
          return (
            <div 
              key={key} 
              className="relative text-tiny not-last:border-r not-last:border-gray-200"
              onMouseEnter={() => handleMouseEnter(key)}
              onMouseLeave={() => handleMouseLeave(key)}
              role="none"
            >
              <button
                onClick={(e) => {
                  e.stopPropagation()
                  if (hasSubItems) {
                    toggleDropdown(key, e)
                  } else if (option.action) {
                    handleMenuItemSelect(option)
                  }
                }}
                onKeyDown={(e) => handleKeyDown(e, key, hasSubItems)}
                className="flex items-center p-1.5 leading-tight hover:bg-gray-100 rounded-md group whitespace-nowrap mr-1"
                title={option.label}
                role="menuitem"
                aria-haspopup={hasSubItems ? "true" : "false"}
                aria-expanded={hasSubItems && isDropdownActive(key) ? "true" : "false"}
                tabIndex={0}
              >
                <option.icon className="w-4 h-4 text-gray-500 group-hover:text-blue-500 mr-1.5" aria-hidden="true" />
                <span className="text-gray-700 group-hover:text-blue-500">{option.label}</span>
                {hasSubItems && (
                  <ChevronDownIcon className="w-3 h-3 text-gray-500 ml-1" aria-hidden="true" />
                )}
              </button>
              
              {hasSubItems && isDropdownVisible(key) && renderDropdownContent(option.subItems, key)}
            </div>
          );
        })}
      </div>
    </BubbleMenu>
  )
}

export default TextBubbleMenu
