import { useState, useEffect } from 'react'

/**
 * Custom hook for tracking connected users in collaborative editing
 * 
 * @param {HocuspocusProvider} provider - Hocuspocus provider instance
 * @param {Object} editor - TipTap editor instance
 * @returns {Object} Connected users information
 */
export const useCollaborationPresence = (provider, editor) => {
  const [connectedUsers, setConnectedUsers] = useState([])

  useEffect(() => {
    if (!provider) return

    const handleUsersChange = () => {
      // Get all connected users from the provider's awareness
      const users = Array.from(provider.awareness.getStates().values())
        .filter(state => state.user && state.cursor) // Filter out entries without user info or cursor
        .map(state => ({
          name: state.user.name,
          color: state.user.color,
          avatar: state.user.avatar,
          cursor: state.cursor // Include cursor position
        }))
      
      setConnectedUsers(users)
    }

    // Initial users
    handleUsersChange()

    // Subscribe to awareness changes
    provider.awareness.on('change', handleUsersChange)

    return () => {
      provider.awareness.off('change', handleUsersChange)
    }
  }, [provider])

  const scrollToUser = (userName) => {
    const user = connectedUsers.find(u => u.name === userName)
    if (user?.cursor?.pos && editor) {
      const pos = editor.view.state.doc.resolve(user.cursor.pos)
      editor.commands.setTextSelection(pos.pos)
      editor.commands.scrollIntoView()
    }
  }

  return {
    connectedUsers,
    connectedUsersCount: connectedUsers.length,
    scrollToUser
  }
} 