import React from 'react'
import { NodeViewWrapper, NodeViewContent } from '@tiptap/react'

export default function NonEditableComponent({ node }) {
  return (
    <NodeViewWrapper className="node-non-editable" contentEditable={false}>
      <div 
        dangerouslySetInnerHTML={{ __html: node.attrs.content }} 
      />
    </NodeViewWrapper>
  )
} 