import React from 'react'
import PropTypes from 'prop-types'
import { BUTTON_VARIANTS } from '../../constants/editor-config'

/**
 * Component for displaying diff control buttons
 * 
 * @param {Object} props - Component props
 * @param {Function} props.onConfirm - Handler for confirming version restore
 * @param {Function} props.onCancel - Handler for canceling version restore
 */
export default function DiffControls({ onConfirm, onCancel }) {
  return (
    <>
      <button
        onClick={onConfirm}
        className={BUTTON_VARIANTS.primary}
      >
        Restore Version
      </button>
      <button
        onClick={onCancel}
        className={BUTTON_VARIANTS.secondary}
      >
        Cancel
      </button>
    </>
  )
}

DiffControls.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
} 