import { Node, mergeAttributes } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import { InputRule } from '@tiptap/core'
import VariableComponent from '../components/VariableComponent'

/**
 * Custom TipTap extension for handling variables in the editor.
 * Variables are non-editable nodes that display dynamic content.
 * 
 * Features:
 * - Inline display of variables as styled pills
 * - Non-editable content (atomic nodes)
 * - Dynamic updates when variable values change
 * - Support for id, label, and value attributes
 * - Automatic conversion of typed {{ key }} to variable nodes
 * - Support for unavailable variables with warning indicator
 * 
 * Usage:
 * ```js
 * Variable.configure({
 *   variables: [
 *     { id: 'user-name', label: 'User Name', value: 'John Doe' }
 *   ]
 * })
 * ```
 */
export default Node.create({
  name: 'variable',
  group: 'inline',
  inline: true,
  atom: true,
  
  addOptions() {
    return {
      variables: [],
      HTMLAttributes: {
        class: 'variable-node'
      }
    }
  },
  
  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: element => element.getAttribute('data-id')
      },
      label: {
        default: null,
        parseHTML: element => element.getAttribute('data-label')
      },
      value: {
        default: null,
        parseHTML: element => element.getAttribute('data-value')
      },
      isUnavailable: {
        default: false,
        parseHTML: element => element.hasAttribute('data-unavailable')
      }
    }
  },
  
  parseHTML() {
    return [
      {
        tag: 'span[data-variable]',
        getAttrs: element => ({
          id: element.getAttribute('data-id'),
          label: element.getAttribute('data-label'),
          value: element.getAttribute('data-value'),
          isUnavailable: element.hasAttribute('data-unavailable')
        })
      }
    ]
  },
  
  renderHTML({ HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(
        this.options.HTMLAttributes,
        { 'data-variable': '' },
        HTMLAttributes.isUnavailable ? { 'data-unavailable': '' } : {},
        HTMLAttributes
      )
    ]
  },
  
  addNodeView() {
    return ReactNodeViewRenderer(VariableComponent)
  },

  addInputRules() {
    return [
      new InputRule({
        find: /\{\{\s*([^}]+)\s*\}\}/g,
        handler: ({ state, match, range }) => {
          const key = match[1].trim()
          const variable = this.options.variables.find(v => v.key === key)
          
          if (variable) {
            const { tr } = state
            const start = range.from
            const end = range.to
            
            tr.replaceRangeWith(start, end, this.type.create({
              id: variable.key,
              label: variable.label,
              value: variable.value,
              isUnavailable: false
            }))
          } else {
            // Handle unavailable variable
            const { tr } = state
            const start = range.from
            const end = range.to
            
            tr.replaceRangeWith(start, end, this.type.create({
              id: key,
              label: key,
              value: key,
              isUnavailable: true
            }))
          }
        }
      })
    ]
  },
  
  addCommands() {
    return {
      insertVariable: attributes => ({ commands }) => {
        return commands.insertContent({
          type: this.name,
          attrs: {
            ...attributes,
            isUnavailable: !this.options.variables.some(v => v.key === attributes.id)
          }
        })
      },
      
      updateVariables: variables => ({ tr, state }) => {
        let hasUpdated = false
        
        state.doc.descendants((node, pos) => {
          if (node.type.name === this.name) {
            const variable = variables.find(v => v.key === node.attrs.id)
            
            if (variable) {
              tr.setNodeMarkup(pos, undefined, {
                ...node.attrs,
                label: variable.label,
                value: variable.value,
                isUnavailable: false
              })
              hasUpdated = true
            } else {
              // Mark as unavailable if variable no longer exists
              tr.setNodeMarkup(pos, undefined, {
                ...node.attrs,
                isUnavailable: true
              })
              hasUpdated = true
            }
          }
        })
        
        return hasUpdated
      }
    }
  }
}) 