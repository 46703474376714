/**
 * Conversation Menu Controller
 *
 * This controller manages the conversation menu interface, including
 * menu navigation, panel switching, and item selection. It works in
 * conjunction with the input-menu controller but focuses on the
 * conversation-specific behaviors.
 *
 * Usage:
 * ```html
 * <div data-controller="conversation-menu" data-conversation-menu-hidden-class="hidden">
 *   <div role="menu" data-conversation-menu-target="menu">
 *     <div
 *       aria-role="menuitem"
 *       data-conversation-menu-target="menuItem"
 *       data-category="category-id"
 *       data-action="
 *         mouseover->conversation-menu#handleMouseover
 *         click->conversation-menu#handleMenuItemClick
 *       "
 *     >
 *       Menu Item
 *     </div>
 *   </div>
 *
 *   <div
 *     id="option-category-name"
 *     data-conversation-menu-target="optionPanel"
 *   >
 *     <button data-action="conversation-menu#backToMenu">
 *       Back
 *     </button>
 *
 *     <div aria-role="menuitem" data-action="click->conversation-menu#handleSubItemClick">
 *       Sub Item
 *     </div>
 *   </div>
 * </div>
 * ```
 *
 * Targets:
 * - menu: The main menu container
 * - menuItem: Main menu items
 * - optionPanel: Category-specific panels
 * - input: The input field for the conversation
 * - form: The form for the conversation data input
 * - loadingIndicator: Loading indicator for category selection
 *
 * Classes:
 * - hidden: Class used to hide/show elements
 */

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["menu", "menuItem", "optionPanel", "input", "category", "form", "loadingIndicator"]
  static classes = ["hidden"]
  static values = {
    hiddenClass: String
  }

  initialize() {
    // Track processed event IDs to prevent duplicate processing
    this.processedEventIds = new Set()
    // Track if we're currently processing an event
    this.isProcessingEvent = false
  }

  connect() {
    // Prevent duplicate initialization
    if (this.element.dataset.initialized === 'true') {
      console.log('🔍 Controller already initialized, skipping');
      return;
    }
    this.element.dataset.initialized = 'true';

    console.log('🔍 Conversation Menu Controller connecting');
    this._initializePanels()
    this._setupInputBlurHandler()
    this._setDefaultOptionAndLabel()

    // Show loading indicator
    this._showLoadingIndicator()

    // Check for category in URL and preload the Writer option
    this._preloadWriterOption()

    // Check for modal-simple parameter in URL and open the modal if present
    this._checkForModalSimpleParam()

    // Bind the event handlers
    this.handleEditorText = this.handleEditorText.bind(this)
    this.handleSetText = this.handleSetText.bind(this)

    // Only listen on the element itself, not on window
    const frame = this.element.closest('#conversation_frame')
    console.log('🔍 Found conversation frame:', !!frame);
    
    if (frame) {
      console.log('🔍 Adding event listeners to frame');
      frame.addEventListener('editor:send-to-assistant', this.handleEditorText)
      frame.addEventListener('assistant:setText', this.handleSetText)
    } else {
      // Fallback to window only if frame not found
      console.log('🔍 Adding event listeners to window (fallback)');
      window.addEventListener('editor:send-to-assistant', this.handleEditorText)
      window.addEventListener('assistant:setText', this.handleSetText)
    }

    // Add debug method to the window
    window.debugConversationMenu = () => this.debugState();
  }

  disconnect() {
    // Remove event listeners
    const frame = this.element.closest('#conversation_frame')
    if (frame) {
      frame.removeEventListener('editor:send-to-assistant', this.handleEditorText)
      frame.removeEventListener('assistant:setText', this.handleSetText)
    } else {
      window.removeEventListener('editor:send-to-assistant', this.handleEditorText)
      window.removeEventListener('assistant:setText', this.handleSetText)
    }
  }

  // Public methods - called directly by Stimulus actions
  handleInputFocus() {
    this._updateInputContainer('add')
  }

  handleInputBlur() {
    this._updateInputContainer('remove')
  }

  handleMenuItemClick(event) {
    const category = event.currentTarget.dataset.category
    if (!category) return

    const featureName = event.currentTarget.dataset.featureName
    this._handleFormChange(featureName)

    const panel = this.optionPanelTargets.find(p => p.id === `option-${category}`)
    if (!panel) return

    this._handlePanelChange(panel)
  }

  handleSubItemClick(event) {
    // Skip if we're processing an event or have pending text
    if (this.isProcessingEvent || this.lastSetText || window.lastPendingText) {
      console.log('🔍 Skipping sub item click due to pending text or ongoing event');
      return;
    }

    console.log('🔍 handleSubItemClick called');
    console.log('🔍 Current lastSetText:', this.lastSetText);
    
    if (this.hasInputTarget) {
      console.log('🔍 Updating from sub item');
      this._updateFromSubItem(event);
    }
  }

  backToMenu() {
    this.optionPanelTargets.forEach(panel => panel.classList.add(this.hiddenClass))
    this.optionPanelTargets.forEach(panel => panel.querySelector('[aria-role="menuitem"]').setAttribute('aria-selected', 'false'))
    this.menuTarget.classList.remove(this.hiddenClass)
  }

  handleMainMenuMouseover(event) {
    this._updateAriaSelected(this.menuItemTargets, event.currentTarget)
  }

  handleSubMenuMouseover(event) {
    const panel = event.currentTarget.closest('[role="menu"]')
    if (!panel) return

    const menuItems = Array.from(panel.querySelectorAll('[aria-role="menuitem"]'))
    this._updateAriaSelected(menuItems, event.currentTarget)
  }

  handleSubMenuMouseout(event) {
    const panel = event.currentTarget.closest('[role="menu"]')
    if (!panel) return

    const menuItems = Array.from(panel.querySelectorAll('[aria-role="menuitem"]'))
    this._updateAriaSelected(menuItems, event.currentTarget, false)
  }

  handleEnter(event) {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()

      const selectedPanel = this.optionPanelTargets.find(panel => panel.querySelectorAll('[aria-role="menuitem"][aria-selected="true"]').length)
      if (selectedPanel) {
        const selectedItem = selectedPanel.querySelector('[aria-role="menuitem"][aria-selected="true"]')

        if (!this.inputTarget.value) {
          selectedItem.click()
        }
      }
      event.target.form.requestSubmit()
    }
  }

  handleEditorText(event) {
    console.log('🔍 handleEditorText called');
    const { text, category, modalId, eventId } = event.detail;
    
    // Skip if we've already processed this event or are currently processing one
    if ((eventId && this.processedEventIds.has(eventId)) || this.isProcessingEvent) {
      console.log('🔍 Skipping event processing:', { eventId, isProcessing: this.isProcessingEvent });
      return;
    }

    this.isProcessingEvent = true;

    // Store the text first so other operations know about it
    if (text) {
      console.log('🔍 Setting lastSetText:', text);
      this.lastSetText = text;
      window.lastPendingText = text; // Also store globally for mutation observer
    }

    if (eventId) {
      this.processedEventIds.add(eventId);
    }

    // Handle operations in sequence
    Promise.resolve()
      .then(() => {
        // First, set the category if provided
        if (category) {
          return this._setCategoryAndSelectMenuItem(category, 0, 10, false);
        }
      })
      .then(() => {
        // Then set the text if provided
        if (text) {
          return this._setInputText(text);
        }
      })
      .then(() => {
        // Try to open the modal - first try the provided modalId, then fallback to modal-writer
        const targetModalId = modalId || 'modal-writer';
        console.log('🔍 Attempting to open modal:', targetModalId);
        return this._openModalByElementId(targetModalId);
      })
      .finally(() => {
        this.isProcessingEvent = false;
      });
  }

  // Handle the custom setText event
  handleSetText(event) {
    const { text } = event.detail

    if (this.hasInputTarget && text) {
      // Add a timestamp to track when this text was last set
      const timestamp = Date.now()
      this.lastTextSetTimestamp = timestamp

      // Set the value
      this.inputTarget.value = text

      // Focus the input
      this.inputTarget.focus()

      // Dispatch events
      this.inputTarget.dispatchEvent(new Event('input', { bubbles: true }))
      this.inputTarget.dispatchEvent(new Event('change', { bubbles: true }))

      // Store the text that was set for comparison
      this.lastSetText = text
    }
  }

  // Private methods
  _handleFormChange(featureName) {
    this.formTargets.forEach(form => form.classList.add(this.hiddenClass))

    const form = this.formTargets.find(form => form.dataset.conversationType === featureName)
    if (form) {
      form.classList.remove(this.hiddenClass)
    }
  }

  _initializePanels() {
    this.optionPanelTargets.forEach(panel => panel.classList.add(this.hiddenClass))
  }

  _setupInputBlurHandler() {
    if (this.hasInputTarget) {
      this.inputTarget.addEventListener('blur-sm', () => this.handleInputBlur())
    }
  }

  _setDefaultOptionAndLabel() {
    const firstPanel = this.optionPanelTargets[0]
    if (firstPanel) {
      document.getElementById('assistant-router-option').value = firstPanel.dataset.optionId
      document.getElementById('assistant-router-label').textContent = firstPanel.dataset.optionLabel
    }
  }

  // Check for modal-simple parameter in URL and open the modal if present
  _checkForModalSimpleParam() {
    const urlParams = new URLSearchParams(window.location.search)
    const modalSimpleParam = urlParams.get('modal-simple')

    if (modalSimpleParam) {
      // Wait for DOM to be fully loaded
      setTimeout(() => {
        this._openModalByElementId(modalSimpleParam)
      }, 300)
    }
  }

  _setCaretAtEnd() {
    const range = document.createRange()
    const selection = window.getSelection()
    range.selectNodeContents(this.inputTarget)
    range.collapse(false)
    selection.removeAllRanges()
    selection.addRange(range)
  }

  _updateAriaSelected(items, selectedItem, selected = true) {
    items.forEach(item => item.setAttribute('aria-selected', 'false'))
    selectedItem.setAttribute('aria-selected', selected ? 'true' : 'false')
  }

  hasSubpanelOpen() {
    return this.optionPanelTargets.some(panel =>
      !panel.classList.contains(this.hiddenClass)
    )
  }

  // Debug method to show the current state of the controller
  debugState() {
    console.log("🔍 Conversation Menu Controller Debug Info:");
    console.log("📝 processedEventIds:", Array.from(this.processedEventIds));
    console.log("🔍 hasInputTarget:", this.hasInputTarget);
    if (this.hasInputTarget) {
      console.log("🔍 inputTarget:", this.inputTarget);
      console.log("🔍 inputTarget value:", this.inputTarget.value);
    }
    console.log("🔍 hasCategoryTarget:", this.hasCategoryTarget);
    if (this.hasCategoryTarget) {
      console.log("🔍 categoryTarget:", this.categoryTarget);
      console.log("🔍 categoryTarget value:", this.categoryTarget.value);
    }
    console.log("🔍 menuItemTargets:", this.menuItemTargets);
    console.log("🔍 menuItemTargets categories:", this.menuItemTargets.map(item => item.dataset.category));
  }

  // Open a modal by element ID
  _openModalByElementId(elementId) {
    console.log('🔍 _openModalByElementId called for:', elementId);
    return new Promise((resolve) => {
      // First check if a modal with this ID is already open in the body
      const existingOpenModal = document.body.querySelector(`#${elementId}[data-show="true"]`);
      if (existingOpenModal) {
        console.log('🔍 Modal already open:', elementId);
        // Force a refresh of the modal state
        existingOpenModal.setAttribute("data-show", "false");
        setTimeout(() => {
          existingOpenModal.setAttribute("data-show", "true");
          document.body.style.overflow = "hidden";
          resolve();
        }, 50);
        return;
      }

      // Check if the modal is already in the body but not shown
      const existingModalInBody = document.body.querySelector(`#${elementId}`);
      if (existingModalInBody) {
        console.log('🔍 Found existing modal in body:', elementId);
        // Reset modal state before showing
        existingModalInBody.setAttribute("data-show", "false");
        setTimeout(() => {
          existingModalInBody.setAttribute("data-show", "true");
          document.body.style.overflow = "hidden";
          resolve();
        }, 50);
        return;
      }

      // Find the modal element in the DOM
      const modalElement = document.getElementById(elementId);
      console.log('🔍 Found modal element:', !!modalElement);

      if (modalElement) {
        // First try to find the controller on the modal element itself
        let modalController = this.application.getControllerForElementAndIdentifier(
          modalElement,
          'modal-simple'
        );

        // If not found, look for a parent element with the controller
        if (!modalController) {
          let parentWithController = modalElement.closest('[data-controller~="modal-simple"]');
          if (parentWithController) {
            modalController = this.application.getControllerForElementAndIdentifier(
              parentWithController,
              'modal-simple'
            );
          }
        }

        if (modalController) {
          console.log('🔍 Opening modal with controller');
          // Reset state before opening
          modalElement.setAttribute("data-show", "false");
          setTimeout(() => {
            modalController.open();
            resolve();
          }, 50);
        } else {
          console.log('🔍 No controller found, trying direct manipulation');
          // Move to body if not already there
          if (modalElement.parentElement !== document.body) {
            document.body.appendChild(modalElement);
          }
          // Reset state before showing
          modalElement.setAttribute("data-show", "false");
          setTimeout(() => {
            modalElement.setAttribute("data-show", "true");
            document.body.style.overflow = "hidden";
            resolve();
          }, 50);
        }
      } else {
        console.log('🔍 Modal element not found:', elementId);
        resolve();
      }
    });
  }

  _setInputText(text) {
    if (!text || !this.hasInputTarget) return;

    console.log('🔍 Setting input text:', text);
    this.inputTarget.value = text;
    this.inputTarget.focus();
    this.inputTarget.dispatchEvent(new Event('input', { bubbles: true }));
    this.inputTarget.dispatchEvent(new Event('change', { bubbles: true }));

    // Open the modal after setting the text
    console.log('🔍 Opening modal after setting text');
    this._openModalByElementId('modal-writer');
  }

  _handlePanelChange(panel) {
    console.log('🔍 _handlePanelChange called');
    console.log('🔍 Current lastSetText:', this.lastSetText);
    console.log('🔍 Current isProcessingEvent:', this.isProcessingEvent);

    if (this.hasInputTarget) {
      if (this.lastSetText) {
        // If we have pending text, set it only if input is empty
        if (!this.inputTarget.value) {
          this._setInputText(this.lastSetText);
        } else {
          console.log('🔍 Input already has value, skipping text set');
        }
        // Always open modal if we have lastSetText, regardless of whether we set the input
        console.log('🔍 Opening modal after panel change');
        this._openModalByElementId('modal-writer');
      } else if (!this.inputTarget.value && !window.lastPendingText) {
        // Only clear if empty and no pending text anywhere
        this.inputTarget.value = '';
      }
      this.inputTarget.focus();
    }

    this._updateRouterValues(panel);
    this._showPanel(panel);

    // Only select first sample if we have no text pending anywhere
    if (!this.lastSetText && !window.lastPendingText && !this.isProcessingEvent) {
      this._maybeSelectFirstSample(panel);
    }
  }

  _updateRouterValues(panel) {
    document.getElementById('assistant-router-option').value = panel.dataset.optionId
    document.getElementById('assistant-router-label').textContent = panel.dataset.optionLabel
  }

  _showPanel(panel) {
    this.menuTarget.classList.add(this.hiddenClass)
    this.optionPanelTargets.forEach(p => p.classList.add(this.hiddenClass))
    panel.classList.remove(this.hiddenClass)
  }

  _updateFromSubItem(event) {
    // Skip if we're processing an event or have pending text
    if (this.isProcessingEvent || this.lastSetText || window.lastPendingText) {
      console.log('🔍 Skipping sub item update due to pending text or ongoing event');
      return;
    }

    console.log('🔍 _updateFromSubItem called');
    console.log('🔍 Current lastSetText:', this.lastSetText);
    
    const panel = event.currentTarget.closest('[data-conversation-menu-target="optionPanel"]');
    this._updateRouterValues(panel);

    // Only update text if we don't have pending text
    if (!this.lastSetText && !window.lastPendingText) {
      this._setInputText(event.currentTarget.textContent.trim());
    }
    
    this._setCaretAtEnd();
  }

  _setCategoryAndSelectMenuItem(category, attempts = 0, maxAttempts = 10, selectDefaultOption = false) {
    return new Promise((resolve) => {
      const trySelect = () => {
        // If we've tried too many times, give up
        if (attempts >= maxAttempts) {
          this._hideLoadingIndicator();
          resolve();
          return;
        }

        // Set category value if target exists
        if (this.hasCategoryTarget) {
          this.categoryTarget.value = category;
          this.categoryTarget.dispatchEvent(new Event('change'));
        }

        // Try to find and click the menu item
        if (this.hasMenuItemTarget && this._findAndClickMenuItem(category, selectDefaultOption)) {
          resolve();
          return;
        }

        // If we couldn't find the target or menu item, try again
        setTimeout(() => {
          trySelect(attempts + 1);
        }, 100);
      };

      trySelect();
    });
  }

  // New method to preload the Writer option
  _preloadWriterOption() {
    // Check if there's a category parameter in the URL
    const urlParams = new URLSearchParams(window.location.search)
    const categoryFromUrl = urlParams.get('category')

    // If we have a category from URL, prioritize it
    if (categoryFromUrl) {
      this._setCategoryAndSelectMenuItem(categoryFromUrl, 0, 10, true)
    }
    // Otherwise check if we have a category target with a value
    else if (this.hasCategoryTarget && this.categoryTarget.value) {
      this._setCategoryAndSelectMenuItem(this.categoryTarget.value, 0, 10, true)
    }
    // Otherwise hide the loading indicator after a short delay
    else {
      setTimeout(() => {
        this._hideLoadingIndicator()
      }, 500)
    }
  }

  // Simplified method to find and click a menu item by category
  _findAndClickMenuItem(category, selectDefaultOption = false) {
    console.log('🔍 _findAndClickMenuItem called with:', { category, selectDefaultOption });
    console.log('🔍 Current lastSetText:', this.lastSetText);
    
    // Try to find the exact match first
    let menuItem = this.menuItemTargets.find(item =>
      item.dataset.category === category
    );
    console.log('🔍 Found exact match:', !!menuItem);

    // If not found, try to find a partial match
    if (!menuItem && (category === 'writer' || category.includes('redactor'))) {
      menuItem = this.menuItemTargets.find(item =>
        item.dataset.category.includes('redactor') ||
        item.dataset.category === 'writer'
      );
      console.log('🔍 Found partial match:', !!menuItem);
    }

    if (menuItem) {
      this._hideLoadingIndicator();
      console.log('🔍 About to click menu item:', menuItem.dataset.category);

      // Use setTimeout to ensure we're not interrupting any other processes
      setTimeout(() => {
        console.log('🔍 Clicking menu item after delay');
        menuItem.click();
      }, 50);

      if (selectDefaultOption) {
        let defaultOption = document.querySelector(`[data-option-id="${category}"] div[aria-role="menuitem"]`);
        if (defaultOption) {
          console.log('🔍 Found default option, will click after delay');
          setTimeout(() => {
            console.log('🔍 Clicking default option');
            defaultOption.click();
          }, 50);
        }
      }
      return true;
    }

    console.log('🔍 No menu item found');
    return false;
  }

  _showLoadingIndicator() {
    if (this.hasLoadingIndicatorTarget) {
      this.loadingIndicatorTarget.classList.remove(this.hiddenClass)
    }
  }

  _hideLoadingIndicator() {
    if (this.hasLoadingIndicatorTarget) {
      this.loadingIndicatorTarget.classList.add(this.hiddenClass)
    }
  }

  _updateInputContainer(action) {
    const container = document.getElementById('assistant-input-container')
    container.classList[action]('focus-ring')
    container.setAttribute('aria-expanded', action === 'add' ? 'true' : 'false')
  }

  _maybeSelectFirstSample(panel) {
    // Don't select first sample if we're processing an event or have pending text
    if (this.isProcessingEvent || this.lastSetText || window.lastPendingText) {
      console.log('🔍 Skipping first sample selection due to pending operation');
      return;
    }

    const selectFirstSample = panel.dataset.selectFirstSample;
    if (selectFirstSample) {
      const firstOption = panel.querySelector('div[aria-role="menuitem"]');
      if (firstOption) {
        setTimeout(() => {
          // Double check we're still not processing anything
          if (!this.isProcessingEvent && !this.lastSetText && !window.lastPendingText) {
            console.log('🔍 Selecting first sample');
            firstOption.click();
          } else {
            console.log('🔍 Skipping first sample selection - text pending');
          }
        }, 50);
      }
    }
  }
}
