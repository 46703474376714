import { Mark } from '@tiptap/core'

export const DiffMark = Mark.create({
  name: 'diffMark',

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  addAttributes() {
    return {
      type: {
        default: null,
        parseHTML: element => element.getAttribute('data-diff-type'),
        renderHTML: attributes => {
          if (!attributes.type) {
            return {}
          }

          const colors = {
            addition: {
              background: '#dcfce7',
              color: '#166534'
            },
            deletion: {
              background: '#fee2e2',
              color: '#991b1b'
            }
          }

          return {
            'data-diff-type': attributes.type,
            style: `background-color: ${colors[attributes.type].background}; color: ${colors[attributes.type].color};`
          }
        }
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: 'span[data-diff-type]',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', HTMLAttributes, 0]
  }
}) 