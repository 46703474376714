import React, { useEffect, useContext } from 'react'
import { EditorContent as TiptapEditorContent } from '@tiptap/react'

import { useEditorSetup } from './hooks/useEditorSetup'
import { useVersionControl } from './hooks/useVersionControl'
import { useVariableHandlers } from './hooks/useVariableHandlers'
import { useContentInsertions } from './hooks/useContentInsertions'
import { useCollaborationPresence } from './hooks/useCollaborationPresence'
import { useTableOfContents } from './hooks/useTableOfContents'
import { downloadHtml } from './utils/downloadHtml'

import EditorHeader from './components/editor/EditorHeader'
import TableOfContents from './components/editor/TableOfContents'
import TableBubbleMenu from './components/TableBubbleMenu'
import TextBubbleMenu from './components/TextBubbleMenu'
import LlmSuggestionView from './components/LlmSuggestionView'
import { EditorProvider, EditorContext } from './context/EditorContext'

// import './../assets/tailwind/application.css'
// import './Editor.css'

// Import LLM suggestion styles
import './styles/llm-suggestions.css'

/**
 * Main Editor component that wraps TipTap functionality.
 * Handles variable replacement, updates, and insertions.
 * 
 * @param {string|Object} content - The initial content (HTML string or TipTap JSON)
 * @param {Array} variables - Array of variables available for insertion
 * @param {string} documentId - Unique identifier for the document
 * @param {Object} currentUser - Current user information for collaboration
 * @param {Function} onUpdate - Callback function to handle updates to the editor
 * @param {Function} onEditorReady - Callback function called when editor is ready
 */
function EditorMain({
  content, 
  variables = [], 
  documentId, 
  currentUser, 
  onUpdate,
  onEditorReady 
}) {
  const { showHelp, setShowHelp } = useContext(EditorContext)
  
  const { editor, provider } = useEditorSetup({ 
    content, 
    variables, 
    isPreviewingDiff: false,
    documentId,
    user: currentUser
  })

  const {
    versions,
    currentVersion,
    selectedVersion,
    isPreviewingDiff,
    saveVersion,
    handleVersionSelect,
    handleConfirmRestore,
    handleCancelRestore
  } = useVersionControl(editor)

  const { connectedUsers, connectedUsersCount, scrollToUser } = useCollaborationPresence(provider, editor)
  const { toc, scrollToHeading, visibleHeadingPos } = useTableOfContents(editor)

  // Handle variables and content insertions separately
  useVariableHandlers(editor)
  useContentInsertions(editor)

  // Notify when editor is ready
  useEffect(() => {
    if (editor && onEditorReady) {
      onEditorReady(editor)
    }
  }, [editor, onEditorReady])

  // Handle editor updates
  useEffect(() => {
    if (editor && onUpdate) {
      const updateHandler = () => onUpdate(editor)
      editor.on('update', updateHandler)
      
      // Initial update
      onUpdate(editor)

      return () => editor.off('update', updateHandler)
    }
  }, [editor, onUpdate])

  // Handle download request from Stimulus
  useEffect(() => {
    const handleDownloadRequest = () => {
      if (!editor) return
      downloadHtml(editor)
    }

    document.addEventListener("editor:request-download", handleDownloadRequest)
    return () => document.removeEventListener("editor:request-download", handleDownloadRequest)
  }, [editor])

  if (!editor) {
    return null
  }

  return (
    <div className="editor">
      <EditorHeader
        editor={editor}
        variables={variables}
        versions={versions}
        currentVersion={currentVersion}
        isPreviewingDiff={isPreviewingDiff}
        showHelp={showHelp}
        onSaveVersion={saveVersion}
        onVersionSelect={handleVersionSelect}
        onConfirmRestore={handleConfirmRestore}
        onCancelRestore={handleCancelRestore}
        onHelpToggle={setShowHelp}
      />
      <div className="editor-content">
        <TiptapEditorContent editor={editor} />
        <TableBubbleMenu editor={editor} />
        <TextBubbleMenu editor={editor} />
        <LlmSuggestionView editor={editor} />
        <TableOfContents 
          toc={toc} 
          onHeadingClick={scrollToHeading} 
          visibleHeadingPos={visibleHeadingPos} 
        />
      </div>
    </div>
  )
}

// Main Editor component wrapped with context provider
export default function Editor(props) {
  return (
    <EditorProvider>
      <EditorMain {...props} />
    </EditorProvider>
  )
} 