import React from 'react'
import PropTypes from 'prop-types'
import Toolbar from './Toolbar'
import DiffControls from './DiffControls'
import { BUTTON_VARIANTS } from '../../constants/editor-config'

const formatDate = (date) => {
  return new Intl.DateTimeFormat('default', {
    dateStyle: 'medium',
    timeStyle: 'short'
  }).format(new Date(date))
}

/**
 * Component for the editor header section
 * 
 * @param {Object} props - Component props
 * @param {Object} props.editor - TipTap editor instance
 * @param {Array} props.variables - Available variables
 * @param {Array} props.versions - Version history
 * @param {string|number} props.currentVersion - Current version ID
 * @param {boolean} props.isPreviewingDiff - Whether diff preview is active
 * @param {boolean} props.showHelp - Whether help is visible
 * @param {Function} props.onSaveVersion - Handler for saving versions
 * @param {Function} props.onVersionSelect - Handler for selecting versions
 * @param {Function} props.onConfirmRestore - Handler for confirming version restore
 * @param {Function} props.onCancelRestore - Handler for canceling version restore
 * @param {Function} props.onHelpToggle - Handler for toggling help visibility
 * @param {Function} props.downloadHtml - Handler for downloading HTML
 */
export default function EditorHeader({
  editor,
  variables,
  versions,
  currentVersion,
  isPreviewingDiff,
  onSaveVersion,
  onVersionSelect,
  onConfirmRestore,
  onCancelRestore
}) {
  return (
    <div className="editor-header">
      <Toolbar
        editor={editor}
        variables={variables}
        onSaveVersion={onSaveVersion}
        onVersionSelect={onVersionSelect}
        versions={versions}
        currentVersion={currentVersion}
      />
      {isPreviewingDiff && (
        <div className="bg-yellow-50 p-2 border-b">
          <div className="flex items-center justify-between">
            <p className="text-sm text-yellow-800">
              Previewing version from {formatDate(selectedVersion.timestamp)}
            </p>
            <div className="space-x-2">
              <button
                onClick={onConfirmRestore}
                className={BUTTON_VARIANTS.primary}
              >
                Restore this version
              </button>
              <button
                onClick={onCancelRestore}
                className={BUTTON_VARIANTS.secondary}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

EditorHeader.propTypes = {
  editor: PropTypes.object.isRequired,
  variables: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })).isRequired,
  versions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    content: PropTypes.object.isRequired,
    timestamp: PropTypes.instanceOf(Date).isRequired,
    label: PropTypes.string.isRequired
  })),
  currentVersion: PropTypes.number,
  isPreviewingDiff: PropTypes.bool.isRequired,
  onSaveVersion: PropTypes.func.isRequired,
  onVersionSelect: PropTypes.func.isRequired,
  onConfirmRestore: PropTypes.func.isRequired,
  onCancelRestore: PropTypes.func.isRequired
} 