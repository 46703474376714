import React, { useEffect, useRef } from 'react'
import { NodeViewWrapper } from '@tiptap/react'
import tippy from 'tippy.js/headless'

const WarningIcon = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 20 20" 
    fill="currentColor" 
    className="inline-block w-4 h-4 ml-1 text-red-600"
  >
    <path 
      fillRule="evenodd" 
      d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" 
      clipRule="evenodd" 
    />
  </svg>
)

/**
 * React component that renders a variable node in the editor
 * Variables are displayed as pills with a special style
 * @param {Object} node - The TipTap node containing variable data
 */
export default function VariableComponent({ node }) {
  const { isUnavailable, id, label, value } = node.attrs
  const spanRef = useRef()
  
  useEffect(() => {
    if (!spanRef.current) return

    const tooltip = tippy(spanRef.current, {
      content: isUnavailable 
        ? 'Esta variable no existe, o el documento todavía no tiene contexto asociado '
        : `${label}: ${value}`,
      placement: 'top',
      delay: [100, 0],
      render(instance) {
        const popper = document.createElement('div')
        const box = document.createElement('div')

        popper.appendChild(box)
        box.className = 'bg-white px-2 py-1 rounded shadow-lg border text-sm'
        box.textContent = instance.props.content

        return {
          popper,
          onUpdate: () => {
            box.textContent = instance.props.content
          }
        }
      }
    })

    return () => tooltip.destroy()
  }, [isUnavailable, label, value])
  
  return (
    <NodeViewWrapper as="span" className="inline">
      <span 
        ref={spanRef}
        className={`variable-node ${isUnavailable ? 'bg-red-100 text-red-700' : ''}`}
        data-variable 
      >
        {isUnavailable ? id : value}
        {isUnavailable && <WarningIcon />}
      </span>
    </NodeViewWrapper>
  )
} 