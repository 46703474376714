import { useState, useEffect, useMemo } from 'react'

const OBSERVER_CONFIG = {
  rootMargin: '-100px 0px -100px 0px',
  threshold: [0, 0.5, 1]
}

const DEBOUNCE_DELAY = 100

const SCROLL_OPTIONS = {
  behavior: 'smooth',
  block: 'start'
}

// Simple debounce utility
const debounce = (fn, delay) => {
  let timeoutId
  return (...args) => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => fn(...args), delay)
  }
}

const createHeadingItem = (node, pos) => ({
  id: `heading-${pos}`,
  type: 'heading',
  level: node.attrs.level,
  text: node.textContent,
  pos
})

const createClauseItem = (pos, clauseType, clauseLabel) => ({
  id: `clause-${pos}`,
  type: 'clauseBlock',
  clauseType,
  clauseLabel,
  pos,
  headings: []
})

export const useTableOfContents = (editor) => {
  const [toc, setToc] = useState([])
  const [visibleHeadingPos, setVisibleHeadingPos] = useState(null)

  const debouncedSetVisibleHeading = useMemo(
    () => debounce((pos) => setVisibleHeadingPos(pos), DEBOUNCE_DELAY),
    []
  )

  // TOC generation effect
  useEffect(() => {
    if (!editor) return

    const isNodeInsideClause = (nodePos, clausePos) => {
      const clauseNode = editor.state.doc.nodeAt(clausePos)
      if (!clauseNode) return false
      const clauseEnd = clausePos + clauseNode.nodeSize
      return nodePos > clausePos && nodePos < clauseEnd
    }

    const updateToc = () => {
      const items = []
      let currentClause = null

      editor.state.doc.descendants((node, pos) => {
        if (node.type.name === 'clauseBlock') {
          const clauseType = node.attrs.type
          const clauseTypeInfo = editor.extensionManager.extensions
            .find(ext => ext.name === 'clauseBlock')
            ?.options?.types
            ?.find(type => type.slug === clauseType)

          currentClause = createClauseItem(
            pos,
            clauseType,
            clauseTypeInfo?.literal || 'Untitled Clause'
          )
          items.push(currentClause)
        } else if (node.type.name === 'heading') {
          const headingItem = createHeadingItem(node, pos)

          if (currentClause && isNodeInsideClause(pos, currentClause.pos)) {
            currentClause.headings.push(headingItem)
          } else {
            currentClause = null
            items.push(headingItem)
          }
        }
      })

      const processedItems = items.map(item => {
        if (item.type === 'clauseBlock') {
          const [firstHeading, ...restHeadings] = item.headings
          if (firstHeading) {
            return {
              ...firstHeading,
              id: item.id,
              clauseLabel: item.clauseLabel,
              clauseType: item.clauseType,
              isClauseHeading: true,
              children: restHeadings
            }
          }
          return { ...item, children: item.headings }
        }
        return item
      })

      setToc(processedItems)
    }

    updateToc()
    editor.on('update', updateToc)
    return () => editor.off('update', updateToc)
  }, [editor])

  // Heading observer effect
  useEffect(() => {
    if (!editor?.isEditable) return

    const setupObserver = () => {
      const observer = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting && entry.intersectionRatio >= 0.5) {
              const pos = editor.view.posAtDOM(entry.target, 0)
              debouncedSetVisibleHeading(pos)
            }
          })
        },
        OBSERVER_CONFIG
      )

      editor.state.doc.descendants((node, pos) => {
        if (node.type.name === 'heading') {
          const domNode = editor.view.nodeDOM(pos)
          if (domNode) observer.observe(domNode)
        }
      })

      return () => observer.disconnect()
    }

    const timeoutId = setTimeout(setupObserver, 500)
    return () => {
      clearTimeout(timeoutId)
      debouncedSetVisibleHeading.cancel?.()
    }
  }, [editor?.isEditable, debouncedSetVisibleHeading])

  const scrollToHeading = (pos) => {
    if (!editor) return
    const domNode = editor.view.nodeDOM(pos)
    if (domNode) domNode.scrollIntoView(SCROLL_OPTIONS)
  }

  return { toc, scrollToHeading, visibleHeadingPos }
} 