import { useEffect, useMemo, useRef } from 'react'
import { useEditor } from '@tiptap/react'

import * as Y from 'yjs'
import { HocuspocusProvider } from '@hocuspocus/provider'

import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import { Color } from '@tiptap/extension-color'
import Collaboration from '@tiptap/extension-collaboration'
import CollaborationCursor from '@tiptap/extension-collaboration-cursor'
import Table from '@tiptap/extension-table'
import TableRow from '@tiptap/extension-table-row'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import Placeholder from '@tiptap/extension-placeholder'

import Variable from '../extensions/variable'
import VariableSuggestion from '../extensions/variable-suggestion'
import NonEditableBlock from '../extensions/non-editable-block'
import ClauseBlock from '../extensions/clause-block'
import HelpBlock from '../extensions/help-block'
import { DiffMark } from '../extensions/diff-mark'
import SlashMenu from '../extensions/slash-menu'
import EditorCommands from '../extensions/editor-commands'
import { LlmCommands } from '../extensions'
import { CommentMark } from '../extensions/comment-mark'

import { prepareContent, reprocessVariables } from '../utils/content'
import { useCommentHandlers } from './useCommentHandlers'
import {
  HEADING_LEVELS,
  LINK_CONFIG,
  TEXT_ALIGN_TYPES,
  EDITOR_CLASSES
} from '../constants/editor-config'

// Constants
const WEBSOCKET_URL = process.env.COLLABORATION_WEBSOCKET_URL
const PASTEL_COLORS = [
  '#E57373', // darker red
  '#81C784', // darker green
  '#64B5F6', // darker blue
  '#FFB74D', // darker orange
  '#BA68C8', // darker purple
  '#4DB6AC', // darker teal
  '#F06292', // darker pink
  '#7986CB', // darker indigo
  '#FF8A65', // darker coral
  '#A1887F'  // darker brown
]

// Function to get a consistent color for a user based on their name
const getColorForUser = (userName) => {
  // Simple hash function to get a consistent number from a string
  const hash = userName.split('').reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc)
  }, 0)
  
  // Use the hash to get a consistent index into our color array
  const index = Math.abs(hash) % PASTEL_COLORS.length
  return PASTEL_COLORS[index]
}

/**
 * Creates the base TipTap extensions array
 * 
 * @param {Array} variables - Available variables for the editor
 * @param {Y.Doc} ydoc - Yjs document instance
 * @returns {Array} Array of configured TipTap extensions
 */
const createExtensions = (variables, ydoc) => {
  // Map variables once to reuse in both extensions
  const mappedVariables = variables.map(v => ({
    key: v.key,
    label: v.label,
    value: v.value
  }))

  return [
    StarterKit.configure({
      heading: { levels: HEADING_LEVELS },
      history: false, // Disabled because we use Yjs for history
      bold: true,
      italic: true,
      bulletList: true,
      orderedList: true,
      code: true,
      codeBlock: true,
      blockquote: true,
      strike: true
    }),
    TextStyle,
    Color,
    Link.configure(LINK_CONFIG),
    TextAlign.configure({ types: TEXT_ALIGN_TYPES }),
    Table.configure({
      resizable: true,
      HTMLAttributes: {
        class: 'border-collapse table-auto w-full'
      }
    }),
    TableRow.configure({
      HTMLAttributes: {
        class: 'border border-gray-300'
      }
    }),
    TableCell.configure({
      HTMLAttributes: {
        class: 'border border-gray-300 px-2 py-1.5'
      }
    }),
    TableHeader.configure({
      HTMLAttributes: {
        class: 'border border-gray-300 px-2 py-1.5 bg-gray-100 font-bold'
      }
    }),
    Placeholder.configure({
      placeholder: ({ node }) => {
        if (node.type.name === 'heading') {
          return "Escribe el título del encabezado"
        }
        return 'Presiona / para insertar contenido, {{ para variables, o comienza a escribir...'
      },
      includeChildren: true,
      showOnlyCurrent: true,
      showOnlyWhenEditable: true,
      emptyNodeClass: 'is-empty',
      emptyEditorClass: 'is-editor-empty',
      types: ['paragraph', 'heading']
    }),
    Variable.configure({ 
      variables: mappedVariables
    }),
    VariableSuggestion.configure({
      variables: mappedVariables,
      suggestion: {
        items: ({ query }) => {
          const cleanQuery = query.trim()
          return mappedVariables.filter(variable => 
            variable.label.toLowerCase().includes(cleanQuery.toLowerCase()) ||
            variable.key.toLowerCase().includes(cleanQuery.toLowerCase())
          )
        }
      }
    }),
    NonEditableBlock,
    HelpBlock,
    ClauseBlock,
    DiffMark,
    SlashMenu,
    EditorCommands,
    LlmCommands,
    CommentMark,
    Collaboration.configure({ document: ydoc })
  ]
}

/**
 * Sets initial content in both editor and Yjs document
 * 
 * @param {Object} params - Parameters for setting content
 * @param {Object} params.editor - TipTap editor instance
 * @param {string} params.content - Content to set
 * @param {Array} params.variables - Available variables
 * @param {Array} params.extensions - TipTap extensions
 * @param {Y.Doc} params.ydoc - Yjs document instance
 */
const setInitialContent = ({ editor, content, variables, extensions, ydoc }) => {
  if (!editor || !content || !ydoc) {
    console.error('Missing required parameters for setting initial content')
    return
  }

  try {
    const processedContent = prepareContent(content, variables, extensions)
    console.log('Processed content to set:', processedContent)
    
    // First set the content in the editor
    editor.commands.setContent(processedContent)
    
    // Then ensure it's synced to Yjs
    const yxml = ydoc.get('prosemirror', Y.XmlFragment)
    ydoc.transact(() => {
      if (yxml.length > 0) {
        yxml.delete(0, yxml.length)
      }
      yxml.push([processedContent])
    })
    
    // After setting content, check for any variables that are marked as unavailable
    // but actually have data now
    reprocessVariables(editor, variables)
    
    console.log('Content set in both editor and Yjs document')
  } catch (error) {
    console.error('Error setting initial content:', error)
    throw error // Re-throw to handle in the effect
  }
}

/**
 * Custom hook for editor initialization and configuration with real-time collaboration
 * 
 * @param {Object} props - Editor configuration props
 * @param {string|Object} props.content - Initial content
 * @param {Array} props.variables - Available variables
 * @param {boolean} props.isPreviewingDiff - Whether diff preview is active
 * @param {string} props.documentId - Unique identifier for the document
 * @param {Object} props.user - Current user information for collaboration
 * @returns {Object} - TipTap editor instance
 */
export const useEditorSetup = ({ content, variables, isPreviewingDiff, documentId, user }) => {
  // Refs to track document state
  const isNewDocumentRef = useRef(true)
  const isConnectedRef = useRef(false)
  const hasSetInitialContent = useRef(false)
  
  // Initialize Yjs document
  const ydoc = useMemo(() => new Y.Doc(), [documentId])

  // Create base extensions
  const extensions = useMemo(
    () => createExtensions(variables, ydoc), 
    [variables, ydoc]
  )

  // Initialize Hocuspocus provider for real-time collaboration
  const provider = useMemo(() => {
    console.log('Creating provider for:', documentId)
    
    // Create a map to store user colors
    const userColors = new Map()
    
    return new HocuspocusProvider({
      url: WEBSOCKET_URL,
      name: documentId,
      document: ydoc,
      token: user?.token,
      onConnect: () => {
        console.log('Connected to server')
        isConnectedRef.current = true
      },
      onAwarenessUpdate: ({ states }) => {
        // Emit presence update event for the Stimulus controller
        const users = states.map(state => {
          const isCurrentUser = state.user?.name === user?.name
          const userName = state.user?.name

          const userColor = userColors.get(userName) || getColorForUser(userName)
          if (!userColors.has(userName)) {
            userColors.set(userName, userColor)
          }
          return {
            name: userName,
            color: userColor,
            avatar: state.user?.avatar,
            cursor: state.cursor
          }
        }).filter(user => user.name)

        document.dispatchEvent(new CustomEvent("editor:presence-update", {
          detail: { users }
        }))
      },
      onSynced: () => {
        console.log('Document synced with server')
        
        // Get the shared document type
        const yxml = ydoc.get('prosemirror', Y.XmlFragment)
        
        try {
          // Check document state only once
          if (!hasSetInitialContent.current) {
            const docState = yxml.toArray()
            console.log('Current document state:', docState)
            
            const documentIsEmpty = !(docState && docState.length > 0)
            isNewDocumentRef.current = documentIsEmpty
            console.log(
              documentIsEmpty
                ? 'Document is empty - will use provided initial content if available'
                : 'Document exists in server with content'
            )

            // Only try to set initial content if document is truly empty
            if (documentIsEmpty && editor && content) {
              console.log('Setting initial content for empty document')
              Promise.resolve().then(() => {
                try {
                  setInitialContent({ editor, content, variables, extensions, ydoc })
                  hasSetInitialContent.current = true
                  console.log('Successfully set initial content after sync')
                } catch (error) {
                  console.error('Failed to set initial content after sync:', error)
                  hasSetInitialContent.current = false
                }
              })
            } else if (editor && variables?.length) {
              // If document exists and we have variables, reprocess to check for availability
              reprocessVariables(editor, variables)
            }
          }
        } catch (error) {
          console.error('Error checking document state:', error)
        }
      },
      onDisconnect: () => {
        console.log('Disconnected from server')
        isConnectedRef.current = false
      }
    })
  }, [documentId, user?.token])

  // Create editor with collaboration features
  const editor = useEditor({
    extensions: [
      ...extensions,
      CollaborationCursor.configure({
        provider,
        user: {
          name: user?.name,
          color: getColorForUser(user?.name || 'anonymous'),
          avatar: user?.avatar
        },
      })
    ],
    editorProps: {
      attributes: {
        class: EDITOR_CLASSES
      }
    }
  })

  // Set up comment handlers
  useCommentHandlers(editor)

  // Cleanup provider on unmount
  useEffect(() => {
    return () => provider.destroy()
  }, [provider])

  // Add effect to process variables on initial load
  useEffect(() => {
    if (editor && variables?.length) {
      // Process variables once editor is ready and variables are available
      reprocessVariables(editor, variables)
    }
  }, [editor, variables])

  return {
    editor,
    provider
  }
}
