/**
 * Generates and downloads the editor content as an HTML file
 * @param {Object} editor - TipTap editor instance
 * @returns {void}
 */
export const downloadHtml = (editor) => {
  console.log('Generate and download HTML triggered')
  
  if (!editor) {
    console.error('Editor instance not available')
    return
  }
  
  try {
    // Get JSON content to properly handle special nodes
    const content = editor.getJSON()
    
    // Function to process special nodes
    const processNode = (node) => {
      if (node.type === 'doc') {
        return node.content.map(processNode).join('')
      }
      
      if (node.type === 'paragraph') {
        const content = node.content ? node.content.map(processNode).join('') : ''
        return `<p>${content}</p>`
      }
      
      if (node.type === 'text') {
        return node.text
      }
      
      if (node.type === 'heading') {
        const content = node.content ? node.content.map(processNode).join('') : ''
        return `<h${node.attrs.level}>${content}</h${node.attrs.level}>`
      }
      
      // Handle special blocks by extracting their content
      if (node.type === 'nonEditableBlock' || node.type === 'helpBlock') {
        const content = node.content ? node.content.map(processNode).join('') : ''
        return content
      }
      
      // For any other node type, just return its text content
      if (node.content) {
        return node.content.map(processNode).join('')
      }
      
      return ''
    }
    
    // Process the content
    const html = processNode(content)
    console.log('Processed content:', html)
    
    // Add basic HTML structure with editor-text styles
    const fullHtml = `<!DOCTYPE html>
<html>
<head>
    <meta charset="UTF-8">
    <title>Document</title>
    <style>
      :root {
        --text-sm: 0.875rem;
      }
      
      .editor-text {
        font-size: var(--text-sm);
        max-width: 800px;
        margin: 2rem auto;
        padding: 0 1rem;
      }
      
      .editor-text *:first-child {
        margin-top: 0;
      }
      
      .editor-text li p,
      .editor-text td p {
        margin: 0;
      }
      
      .editor-text p:not(:last-child),
      .editor-text ul:not(:last-child),
      .editor-text ol:not(:last-child),
      .editor-text blockquote:not(:last-child) {
        margin-bottom: 1rem;
      }
      
      .editor-text video,
      .editor-text img {
        margin: 2rem 0;
        width: 100%;
      }
      
      .editor-text img {
        border: 2px solid #e5e7eb;
        border-radius: 0.125rem;
      }
      
      .editor-text h1 {
        color: #1e3a8a;
        font-size: 1.875rem;
        margin-bottom: 2rem;
      }
      
      .editor-text h2,
      .editor-text h3 {
        color: #2563eb;
        margin-bottom: 0.5rem;
      }
      
      .editor-text h2 {
        font-size: 1.5rem;
      }
      
      .editor-text h3 {
        font-size: 1.25rem;
      }
      
      .editor-text blockquote {
        border-left: 4px solid #e5e7eb;
        padding-left: 1rem;
        color: #6b7280;
      }
      
      .editor-text code {
        background-color: #e5e7eb;
        border-radius: 0.125rem;
        padding: 0.125rem 0.375rem;
        font-size: 0.875rem;
      }
      
      .editor-text ul,
      .editor-text ol {
        padding-left: 2rem;
        margin-bottom: 1rem;
      }
      
      .editor-text ul {
        list-style-type: disc;
      }
      
      .editor-text ol {
        list-style-type: decimal;
      }
      
      .editor-text table {
        margin-bottom: 2rem;
        width: 100%;
        border-collapse: collapse;
      }
      
      .editor-text th {
        color: #2563eb;
      }
      
      .editor-text th,
      .editor-text td {
        font-size: 0.875rem;
        padding-right: 2rem;
        text-align: left;
        border-bottom: 1px solid #e5e7eb;
        padding: 0.25rem;
      }
      
      .editor-text hr {
        width: 50%;
        margin: 2rem auto;
        border-bottom: 1px solid #d1d5db;
        border-top: 0;
      }
    </style>
</head>
<body>
    <div class="editor-text">
      ${html}
    </div>
</body>
</html>`

    // Create blob and trigger download
    const blob = new Blob([fullHtml], { type: 'text/html;charset=utf-8' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = `document-${new Date().toISOString().split('T')[0]}.html`
    
    // Trigger download
    document.body.appendChild(a)
    a.click()
    
    // Cleanup
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
    
    console.log('Download completed')
  } catch (error) {
    console.error('Error downloading HTML:', error)
  }
} 