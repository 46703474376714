import { Controller } from "@hotwired/stimulus"
import { createRoot } from "react-dom/client"
import React from 'react'
import Editor from "../react/editor/Editor"

/**
 * Stimulus controller that manages the document editor with variable and external content support.
 * This controller acts as a bridge between the Rails view and the React editor component.
 *
 * CONTENT INSERTION FEATURE
 * ------------------------
 * The editor supports inserting external content (like proposals, comments, or any other text)
 * directly into the editor. This is implemented through Stimulus targets and actions.
 *
 * Required markup structure:
 * ```html
 * <div data-editor-target="container">
 *   <!-- Button to trigger insertion -->
 *   <button data-editor-target="button"
 *           data-action="click->editor#insertContent">
 *     Insert Content
 *   </button>
 *
 *   <!-- The actual content to insert -->
 *   <div data-editor-target="externalContent">
 *     Content to be inserted into the editor...
 *   </div>
 * </div>
 * ```
 *
 * The controller maintains a 1:1:1 relationship between containers, buttons, and content elements
 * through array indices. When a button is clicked, it finds its corresponding content using the
 * button's index in the buttonTargets array.
 *
 * Flow:
 * 1. User clicks a button with data-action="click->editor#insertContent"
 * 2. The insertContent action finds the corresponding content using button index
 * 3. Dispatches 'editor-insert-content' event with the content
 * 4. React editor component receives the event and inserts the content
 *
 * Targets:
 * - content: The container where the editor will be mounted
 * - variables: The container for all variable elements
 * - variable: Individual variable container elements
 * - variableLabel: Display label for each variable
 * - variableKey: Unique key for each variable (used in placeholders)
 * - variableValue: Current value of each variable
 * - button: The button element for inserting external content
 * - externalContent: The container for external content to be inserted
 * - container: The container that groups related button and content elements
 * - commentsPanel: The container for the comments panel
 */
export default class extends Controller {
  static targets = [
    "content",
    "variables",
    "variable",
    "variableLabel",
    "variableKey",
    "variableValue",
    "button",
    "externalContent",
    "container",
    "commentsPanel"
  ]

  connect() {
    // Store the element reference for external access
    this.element.editorInstance = null

    this._initializeEditor()
    this._setupVariableObserver()
    this._setupVariableClickHandlers()
    this._setupCommentHandlers()
  }

  disconnect() {
    if (this.root) {
      window.removeEventListener('editor:create-comment', this._handleCreateComment)
      window.removeEventListener('editor:show-comment', this._handleShowComment)
      window.removeEventListener('editor:scroll-to-comment', this._handleScrollToComment)
      this.root.unmount()
    }
  }

  // Actions

  insertContent(event) {
    // Get the index of the clicked button
    const buttonIndex = this.buttonTargets.indexOf(event.currentTarget)
    const rawContent = this.externalContentTargets[buttonIndex].innerHTML.trim()

    // Prevent any default behavior or bubbling
    event.preventDefault()
    event.stopPropagation()

    // Get the current selection from the editor
    const editorView = document.querySelector('.ProseMirror')?.editor?.view
    if (!editorView) {
      console.error("Could not find editor view")
      return
    }

    const { from, to } = editorView.state.selection
    const hasSelection = from !== to

    console.log("Editor: Content insertion details:", {
      buttonIndex,
      content: rawContent.substring(0, 100) + "...",
      hasSelection,
      selectionRange: { from, to }
    })

    // Dispatch the content insertion event with selection info
    window.dispatchEvent(
      new CustomEvent('editor-insert-content', {
        detail: {
          content: rawContent,
          isHTML: true,
          replaceRange: hasSelection,
          range: hasSelection ? { from, to } : null,
          timestamp: new Date().getTime()
        }
      })
    )

    // Trigger content update event after insertion
    window.dispatchEvent(
      new CustomEvent('editor-content-updated', {
        detail: {
          timestamp: new Date().getTime(),
          action: 'content-inserted'
        }
      })
    )
  }

  // Private methods

  _initializeEditor() {
    const variables = this._getVariables()
    this.root = createRoot(this.contentTarget)

    // Get document ID from data attribute
    const documentId = this.contentTarget.dataset.documentId || `doc-${Math.random().toString(36).substr(2, 9)}`

    // Get current user info - you should set these data attributes in your Rails view
    const currentUser = {
      name: this.contentTarget.dataset.userName || 'Anonymous',
      avatar: this.contentTarget.dataset.userAvatar,
      token: this.contentTarget.dataset.collaborationToken
    }

    // Debug content flow
    console.log('Raw content from data attribute:', this.contentTarget.dataset.content)
    console.log('Document ID:', documentId)
    console.log('Variables:', variables)

    this.root.render(
      React.createElement(Editor, {
        content: this.contentTarget.dataset.content,
        variables: variables,
        documentId: documentId,
        currentUser: currentUser,
        onUpdate: this._handleEditorUpdate.bind(this),
        onEditorReady: (editor) => {
          // Store the editor instance on the element for external access
          this.element.editorInstance = editor
          // Dispatch editor ready event
          window.dispatchEvent(new CustomEvent('editor-ready'))
        }
      })
    )
  }

  _setupVariableObserver() {
    const observer = new MutationObserver(() => {
      const variables = this._getVariables()
      window.dispatchEvent(
        new CustomEvent("variables-updated", { detail: variables })
      )
    })

    if (this.hasVariablesTarget) {
      observer.observe(this.variablesTarget, {
        childList: true,
        subtree: true,
        characterData: true
      })
    }
  }

  _setupVariableClickHandlers() {
    this.variableTargets.forEach(variableEl => {
      variableEl.style.cursor = 'pointer'
      variableEl.addEventListener('click', () => this._handleVariableClick(variableEl))
    })
  }

  _getVariables() {
    // First collect all variables
    const allVariables = this.variableTargets
      .map((div, index) => ({
        key: this.variableKeyTargets[index]?.textContent?.trim() || '',
        label: this.variableLabelTargets[index]?.textContent?.trim() || '',
        value: this.variableValueTargets[index]?.textContent?.trim() || ''
      }))
      .filter(v => v.key && v.label && v.value);

    // Then deduplicate by key using a Map
    const uniqueVariablesMap = new Map();
    allVariables.forEach(variable => {
      uniqueVariablesMap.set(variable.key, variable);
    });

    // Convert back to array
    return Array.from(uniqueVariablesMap.values());
  }

  _handleVariableClick(variableEl) {
    const index = this.variableTargets.indexOf(variableEl)
    const key = this.variableKeyTargets[index]?.textContent?.trim()
    const label = this.variableLabelTargets[index]?.textContent?.trim()
    const value = this.variableValueTargets[index]?.textContent?.trim()

    if (key && label && value) {
      window.dispatchEvent(
        new CustomEvent('insert-variable', {
          detail: { key, label, value }
        })
      )
    }
  }

  _handleEditorUpdate(editor) {
    // Get all clause types from the editor
    const clauseTypes = []
    editor.state.doc.descendants((node) => {
      if (node.type.name === 'clauseBlock' && node.attrs.type) {
        clauseTypes.push(node.attrs.type)
      }
    })

    // Dispatch the event with clause types
    window.dispatchEvent(
      new CustomEvent('editor-content-updated', {
        detail: { clauseTypes }
      })
    )
  }

  _setupCommentHandlers() {
    window.addEventListener('editor:create-comment', this._handleCreateComment.bind(this))
    window.addEventListener('editor:show-comment', this._handleShowComment.bind(this))
    window.addEventListener('editor:scroll-to-comment', this._handleScrollToComment.bind(this))
  }

  _handleCreateComment(event) {
    const { selection } = event.detail

    // In real implementation, this would be an API call
    const tempThreadId = `temp-${Date.now()}`

    // Add mark to editor
    this.element.editorInstance.chain()
      .setComment({ threadId: tempThreadId, commentCount: 0 })
      .run()

    // Show comments panel with new thread form
    this._activateCommentsPanel(tempThreadId, selection)
  }

  _handleShowComment(event) {
    const { threadId } = event.detail
    this._activateCommentsPanel(threadId)
  }

  _handleScrollToComment(event) {
    const { threadId } = event.detail;

    if (!this.element.editorInstance) {
      console.warn('Editor instance not available');
      return;
    }

    console.log(`Attempting to scroll to comment with threadId: ${threadId}`);

    // Search for the position of the comment mark with this threadId in the document
    let found = false;

    // Use the ProseMirror API to search for the comment mark
    this.element.editorInstance.state.doc.descendants((node, pos) => {
      if (found) return false; // If we already found the mark, stop the search

      // Search for comment marks in the node
      const commentMarks = node.marks.filter(m => m.type.name === 'comment');

      // Search for the specific mark with the matching threadId
      const mark = commentMarks.find(m => String(m.attrs.threadId) === String(threadId));

      if (mark) {
        console.log(`Found comment mark at position ${pos}`, mark);

        // Calculate the end position of the node to place cursor after the comment
        const endPos = pos + node.nodeSize;
        console.log(`Setting cursor at position ${endPos} (after comment)`);

        try {
          // Get the DOM node at this position
          const view = this.element.editorInstance.view;
          const domInfo = view.domAtPos(pos);

          if (domInfo && domInfo.node) {
            console.log('Found DOM node:', domInfo.node);

            // Get the parent element if it's a text node
            const element = domInfo.node.nodeType === Node.TEXT_NODE
              ? domInfo.node.parentElement
              : domInfo.node;

            // Scroll the element into view
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });

            // Adjust for header after a short delay
            setTimeout(() => {
              window.scrollBy(0, -100);

              // Highlight the comment after scrolling
              this._highlightComment(pos, node);
            }, 300);
          } else {
            // Fallback to the editor's scrollIntoView
            this.element.editorInstance.commands.scrollIntoView();

            // Highlight the comment after scrolling
            setTimeout(() => {
              this._highlightComment(pos, node);
            }, 300);
          }
        } catch (e) {
          console.error('Error scrolling to comment:', e);
          // Fallback to the editor's scrollIntoView
          this.element.editorInstance.commands.scrollIntoView();

          // Highlight the comment after scrolling
          setTimeout(() => {
            this._highlightComment(pos, node);
          }, 300);
        }

        found = true;
        return false;
      }
    });

    if (!found) {
      console.warn(`Comment mark with threadId ${threadId} not found in the document`);
    }
  }

  /**
   * Highlights a comment mark in the editor using Tailwind transition classes
   * @param {number} pos - The position of the node with the comment mark
   * @param {Object} node - The node with the comment mark
   * @private
   */
  _highlightComment(pos, node) {
    try {
      console.log('Highlighting comment at position', pos);

      // Get the DOM node at this position
      const view = this.element.editorInstance.view;
      const domInfo = view.domAtPos(pos);

      if (!domInfo || !domInfo.node) {
        console.warn('Could not find DOM node for highlighting');
        return;
      }

      // Find the element containing the comment mark
      let element = domInfo.node;
      if (element.nodeType === Node.TEXT_NODE) {
        element = element.parentElement;
      }

      // Try to find the comment mark element
      const commentMark = element.closest('.comment-mark') ||
                         element.querySelector('.comment-mark');

      if (!commentMark) {
        console.warn('Could not find comment mark element');
        return;
      }

      console.log('Found comment mark element for highlighting:', commentMark);

      // Add Tailwind transition classes for smooth animation
      commentMark.classList.add('transition', 'duration-700', 'ease-in-out');

      // Create a highlight effect by adding and removing classes
      // First, add the highlight class
      commentMark.classList.add('bg-yellow-300');

      // Use an event listener to remove the highlight class after the transition completes
      const transitionEndHandler = () => {
        // Remove the highlight class to transition back to normal
        commentMark.classList.remove('bg-yellow-300');

        // Remove the event listener
        commentMark.removeEventListener('transitionend', transitionEndHandler);

        // After the transition back completes, remove the transition classes
        const cleanupHandler = () => {
          commentMark.classList.remove('transition', 'duration-700', 'ease-in-out');
          commentMark.removeEventListener('transitionend', cleanupHandler);
        };

        commentMark.addEventListener('transitionend', cleanupHandler);
      };

      // Listen for the transition to complete
      commentMark.addEventListener('transitionend', transitionEndHandler);

    } catch (e) {
      console.error('Error highlighting comment:', e);
    }
  }

  _activateCommentsPanel(threadId, selection = null) {
    // Activate comments tab
    const event = new CustomEvent('sidebar-tabs:activate', {
      detail: { tab: 'comments' }
    })
    window.dispatchEvent(event)

    // Show thread or new thread form
    const threadEvent = new CustomEvent(selection ? 'comments:new' : 'comments:show', {
      detail: {
        threadId,
        selection
      }
    })
    window.dispatchEvent(threadEvent)
  }

  review() {
    // Activate review tab
    const reviewEvent = new CustomEvent('sidebar-tabs:activate', {
      detail: { tab: 'review' }
    })
    window.dispatchEvent(reviewEvent)
  }

  // Method to update comment count for a thread
  updateCommentCount(threadId, count) {
    this.element.editorInstance.commands.updateCommentCount(threadId, count)
  }
}
