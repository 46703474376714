import { generateJSON } from '@tiptap/core'

/**
 * Convert HTML content to TipTap JSON format
 * 
 * @param {string} html - The HTML content to convert
 * @param {Array} extensions - Array of TipTap extensions
 * @returns {Object} - TipTap JSON document
 */
export const htmlToTipTapJSON = (html, extensions) => {
  return generateJSON(html, extensions)
}

/**
 * Process content to replace {{ key }} placeholders with variable nodes.
 * 
 * @param {Object} json - The TipTap JSON content
 * @param {Array} variables - Array of variables with key, label and value
 * @returns {Object} - Processed TipTap JSON content
 */
export const processVariables = (json, variables) => {
  const processNode = (node) => {
    if (node.type === 'text') {
      // Find all variable placeholders in the text
      const matches = node.text.match(/\{\{([^}]+)\}\}/g)
      
      if (matches) {
        // Create an array to hold the processed nodes
        const processedNodes = []
        let lastIndex = 0
        
        matches.forEach(match => {
          const key = match.replace(/[{}]/g, '').trim()
          const variable = variables.find(v => v.key === key)
          const index = node.text.indexOf(match, lastIndex)
          
          // Add text before the variable if any
          if (index > lastIndex) {
            processedNodes.push({
              type: 'text',
              text: node.text.slice(lastIndex, index)
            })
          }
          
          // Add the variable node
          processedNodes.push({
            type: 'variable',
            attrs: variable ? {
              id: variable.key,
              label: variable.label,
              value: variable.value,
              isUnavailable: false
            } : {
              id: key,
              label: key,
              value: key,
              isUnavailable: true
            }
          })
          
          lastIndex = index + match.length
        })
        
        // Add remaining text after last variable if any
        if (lastIndex < node.text.length) {
          processedNodes.push({
            type: 'text',
            text: node.text.slice(lastIndex)
          })
        }
        
        return processedNodes.length > 0 ? processedNodes : node
      }
    }

    if (node.content) {
      const newContent = []
      node.content.forEach(child => {
        const processed = processNode(child)
        if (Array.isArray(processed)) {
          newContent.push(...processed)
        } else {
          newContent.push(processed)
        }
      })
      return { ...node, content: newContent }
    }

    return node
  }

  return processNode(json)
}

/**
 * Reprocesses variables in the document to update their availability status
 * 
 * @param {Object} editor - TipTap editor instance
 * @param {Array} variables - Available variables
 * @returns {boolean} - Whether any variables were updated
 */
export const reprocessVariables = (editor, variables) => {
  if (!editor || !variables?.length) return false

  let hasUpdated = false
  editor.state.doc.descendants((node, pos) => {
    if (node.type.name === 'variable') {
      const variable = variables.find(v => v.key === node.attrs.id)
      const newAttrs = variable
        ? {
            ...node.attrs,
            label: variable.label,
            value: variable.value,
            isUnavailable: false
          }
        : {
            ...node.attrs,
            isUnavailable: true
          }

      editor.chain().focus(false).command(({ tr }) => {
        tr.setNodeMarkup(pos, undefined, newAttrs)
        return true
      }).run()
      
      hasUpdated = true
    }
  })

  return hasUpdated
}

/**
 * Prepare content for the editor, handling both HTML and JSON formats
 * 
 * @param {string|Object} content - The content (HTML string or TipTap JSON)
 * @param {Array} variables - Array of variables available for insertion
 * @param {Array} extensions - Array of TipTap extensions
 * @returns {Object} - TipTap JSON document
 */
export const prepareContent = (content, variables, extensions) => {
  console.log('Preparing content:', {
    type: typeof content,
    rawContent: content,
    hasVariables: Array.isArray(variables) && variables.length > 0,
    variablesCount: variables?.length
  })

  try {
    // Clean up HTML content if needed
    let cleanContent = content
    if (typeof content === 'string') {
      // Remove any extra whitespace and normalize newlines
      cleanContent = content.trim().replace(/\n\s*\n/g, '\n')
    }

    // Convert to JSON if needed
    const initialJson = typeof cleanContent === 'object' 
      ? cleanContent 
      : htmlToTipTapJSON(cleanContent, extensions)

    console.log('Initial JSON:', initialJson)

    // Process variables and return
    const processedContent = processVariables(initialJson, variables)
    console.log('Processed content:', processedContent)

    return processedContent
  } catch (error) {
    console.error('Error preparing content:', error)
    // Return a basic document structure instead of null
    return {
      type: 'doc',
      content: [{
        type: 'paragraph',
        content: [{
          type: 'text',
          text: 'Error loading document content'
        }]
      }]
    }
  }
} 