/**
 * Utility functions for document thread interactions
 */

/**
 * Scrolls to a thread container and adds a blinking animation
 * @param {HTMLElement} threadContainer - The thread container element to animate and scroll to
 */
export function scrollAndAnimateThread(threadContainer) {
  // Add blinking background effect
  threadContainer.classList.add('animate-blink-bg');

  // Remove the animation class after it completes
  setTimeout(() => {
    threadContainer.classList.remove('animate-blink-bg');
  }, 1000);

  // Scroll the thread into view with smooth behavior
  threadContainer.scrollIntoView({
    behavior: 'smooth',
    block: 'start'
  });
}
