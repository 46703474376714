import React, { useState, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { 
  CheckIcon, 
  XMarkIcon, 
  ArrowPathIcon,
  PaperAirplaneIcon,
  SparklesIcon
} from '@heroicons/react/24/outline'

const LlmSuggestionView = ({ editor }) => {
  const [suggestions, setSuggestions] = useState({})
  const [errors, setErrors] = useState({})
  const [loadingStates, setLoadingStates] = useState({})
  const [furtherInstructions, setFurtherInstructions] = useState({})
  const [instructionInputs, setInstructionInputs] = useState({})
  const [portalContainer, setPortalContainer] = useState(null)
  const containerRef = useRef(null)

  // Create portal container when editor is available
  useEffect(() => {
    if (!editor) return

    // Find or create container for the portal
    let container = document.querySelector('.llm-suggestion-container')
    if (!container) {
      container = document.createElement('div')
      container.className = 'llm-suggestion-container'
      
      // Append to editor parent if available, otherwise to body
      const parent = editor.view.dom.parentElement || document.body
      parent.appendChild(container)
    }
    
    setPortalContainer(container)
    
    // Cleanup function
    return () => {
      // Only remove if we created it
      if (container && container.parentElement && !document.querySelector('.llm-suggestion-container:not(:last-child)')) {
        try {
          container.parentElement.removeChild(container)
        } catch (e) {
          console.warn('Could not remove suggestion container:', e)
        }
      }
    }
  }, [editor])

  useEffect(() => {
    if (!editor) return

    // Listen for loading events
    const handleLoadingStart = (event) => {
      const { id, from, to } = event.detail
      console.log('Loading started:', id, from, to)
      setLoadingStates(prev => ({
        ...prev,
        [id]: { from, to }
      }))
    }

    // Listen for suggestion ready events
    const handleSuggestionReady = (event) => {
      const { id, suggestion, originalText, promptType } = event.detail
      
      // Remove loading state
      setLoadingStates(prev => {
        const newLoadingStates = { ...prev }
        delete newLoadingStates[id]
        return newLoadingStates
      })
      
      // Add suggestion
      setSuggestions(prev => ({
        ...prev,
        [id]: { suggestion, originalText, promptType }
      }))
      
      // Remove from errors if it was there
      setErrors(prev => {
        const newErrors = { ...prev }
        delete newErrors[id]
        return newErrors
      })
    }

    // Listen for suggestion error events
    const handleSuggestionError = (event) => {
      const { id, error, originalText } = event.detail
      
      // Remove loading state
      setLoadingStates(prev => {
        const newLoadingStates = { ...prev }
        delete newLoadingStates[id]
        return newLoadingStates
      })
      
      // Add error
      setErrors(prev => ({
        ...prev,
        [id]: { error, originalText }
      }))
    }

    // Listen for custom events from the llm-commands extension
    window.addEventListener('editor:llm-loading-start', handleLoadingStart)
    window.addEventListener('editor:llm-suggestion-ready', handleSuggestionReady)
    window.addEventListener('editor:llm-suggestion-error', handleSuggestionError)

    return () => {
      window.removeEventListener('editor:llm-loading-start', handleLoadingStart)
      window.removeEventListener('editor:llm-suggestion-ready', handleSuggestionReady)
      window.removeEventListener('editor:llm-suggestion-error', handleSuggestionError)
    }
  }, [editor])

  // Handle accepting a suggestion
  const handleAccept = (id) => {
    if (!editor) return
    
    editor.commands.acceptSuggestion(id)
    
    // Remove from local state
    setSuggestions(prev => {
      const newSuggestions = { ...prev }
      delete newSuggestions[id]
      return newSuggestions
    })
    
    setFurtherInstructions(prev => {
      const newInstructions = { ...prev }
      delete newInstructions[id]
      return newInstructions
    })
    
    setInstructionInputs(prev => {
      const newInputs = { ...prev }
      delete newInputs[id]
      return newInputs
    })
  }

  // Handle discarding a suggestion
  const handleDiscard = (id) => {
    if (!editor) return
    
    editor.commands.discardSuggestion(id)
    
    // Remove from local state
    setSuggestions(prev => {
      const newSuggestions = { ...prev }
      delete newSuggestions[id]
      return newSuggestions
    })
    
    setErrors(prev => {
      const newErrors = { ...prev }
      delete newErrors[id]
      return newErrors
    })
    
    setFurtherInstructions(prev => {
      const newInstructions = { ...prev }
      delete newInstructions[id]
      return newInstructions
    })
    
    setInstructionInputs(prev => {
      const newInputs = { ...prev }
      delete newInputs[id]
      return newInputs
    })
  }

  // Handle retrying a failed suggestion
  const handleRetry = (id) => {
    if (!editor) return
    
    editor.commands.retrySuggestion(id)
    
    // Remove from errors
    setErrors(prev => {
      const newErrors = { ...prev }
      delete newErrors[id]
      return newErrors
    })
  }

  // Toggle further instruction input
  const toggleFurtherInstructions = (id) => {
    setFurtherInstructions(prev => ({
      ...prev,
      [id]: !prev[id]
    }))
    
    // Initialize input if not already
    if (!instructionInputs[id]) {
      setInstructionInputs(prev => ({
        ...prev,
        [id]: ''
      }))
    }
  }

  // Handle instruction input change
  const handleInstructionChange = (id, value) => {
    setInstructionInputs(prev => ({
      ...prev,
      [id]: value
    }))
  }

  // Submit further instructions
  const submitFurtherInstructions = (id) => {
    if (!editor || !instructionInputs[id]) return
    
    const instruction = instructionInputs[id]
    
    // Set loading state for this suggestion
    setLoadingStates(prev => ({
      ...prev,
      [id]: { isRefining: true }
    }))
    
    // Remove from suggestions temporarily
    const originalSuggestion = suggestions[id]
    setSuggestions(prev => {
      const newSuggestions = { ...prev }
      delete newSuggestions[id]
      return newSuggestions
    })
    
    // TODO: In a real implementation, this would send the instruction to the LLM
    // For now, we'll just simulate a delay and then update the suggestion
    setTimeout(() => {
      // Remove loading state
      setLoadingStates(prev => {
        const newLoadingStates = { ...prev }
        delete newLoadingStates[id]
        return newLoadingStates
      })
      
      // Add updated suggestion
      setSuggestions(prev => ({
        ...prev,
        [id]: {
          ...originalSuggestion,
          suggestion: `${originalSuggestion.suggestion} [Further instruction: ${instruction}]`
        }
      }))
      
      // Clear the input and hide the form
      setInstructionInputs(prev => ({
        ...prev,
        [id]: ''
      }))
      
      setFurtherInstructions(prev => ({
        ...prev,
        [id]: false
      }))
    }, 1500)
  }

  // Find all active elements in the document
  const findActiveElements = () => {
    if (!editor) return []
    
    const elements = []
    
    // Process loading states
    Object.entries(loadingStates).forEach(([id, data]) => {
      // If it's a refinement, use the original suggestion's position
      if (data.isRefining && suggestions[id]) {
        const suggestionElement = document.querySelector(`.llm-suggestion[data-suggestion-id="${id}"]`)
        if (suggestionElement) {
          const rect = suggestionElement.getBoundingClientRect()
          const editorRect = editor.view.dom.getBoundingClientRect()
          
          elements.push({
            id,
            type: 'loading',
            rect,
            editorRect,
            isRefining: true
          })
        }
        return
      }
      
      // Otherwise, find the loading decoration
      const loadingElement = document.querySelector(`.llm-loading[data-suggestion-id="${id}"]`)
      console.log('Looking for loading element:', id, !!loadingElement)
      if (loadingElement) {
        const rect = loadingElement.getBoundingClientRect()
        const editorRect = editor.view.dom.getBoundingClientRect()
        
        elements.push({
          id,
          type: 'loading',
          rect,
          editorRect
        })
      }
    })
    
    // Process suggestion elements
    Object.entries(suggestions).forEach(([id, data]) => {
      const suggestionElement = document.querySelector(`.llm-suggestion[data-suggestion-id="${id}"]`)
      if (suggestionElement) {
        const rect = suggestionElement.getBoundingClientRect()
        const editorRect = editor.view.dom.getBoundingClientRect()
        
        elements.push({
          id,
          type: 'suggestion',
          rect,
          editorRect,
          data
        })
      }
    })
    
    // Process error elements
    Object.entries(errors).forEach(([id, data]) => {
      const errorElement = document.querySelector(`.llm-error[data-suggestion-id="${id}"]`)
      if (errorElement) {
        const rect = errorElement.getBoundingClientRect()
        const editorRect = editor.view.dom.getBoundingClientRect()
        
        elements.push({
          id,
          type: 'error',
          rect,
          editorRect,
          data
        })
      }
    })
    
    return elements
  }

  // Render UI for all active elements
  const renderUI = () => {
    const elements = findActiveElements()
    
    return elements.map(element => {
      const { id, type, rect, editorRect, data, isRefining } = element
      
      // Calculate position
      const top = rect.top - editorRect.top + editor.view.dom.scrollTop
      const left = rect.left - editorRect.left + editor.view.dom.scrollLeft
      
      // Style for the floating UI
      const style = {
        position: 'absolute',
        top: `${top + rect.height}px`,
        left: `${left}px`,
        zIndex: 50
      }
      
      // Use a single UI component for both loading and suggestion states
      if (type === 'loading') {
        return (
          <div key={id || 'loading'} style={style} className="bg-blue-50 border border-blue-200 rounded-md p-2 shadow-md max-w-md">
            <div className="flex items-center text-blue-700 mb-2">
              <SparklesIcon className="w-4 h-4 mr-2 animate-pulse" />
              <span className="text-xs font-medium">{isRefining ? 'Mejorando sugerencia...' : 'Procesando con IA...'}</span>
            </div>
            <div className="text-xs text-gray-500 italic">
              Generando sugerencia...
            </div>
          </div>
        )
      }
      
      if (type === 'error') {
        return (
          <div key={id} style={style} className="bg-red-50 border border-red-200 rounded-md p-2 shadow-md max-w-md">
            <div className="flex items-center text-red-700 mb-2">
              <XMarkIcon className="w-4 h-4 mr-2" />
              <span className="text-xs font-medium">Error: {data.error}</span>
            </div>
            <div className="flex justify-between">
              <button 
                onClick={() => handleRetry(id)}
                className="text-xs bg-red-100 hover:bg-red-200 text-red-700 px-2 py-1 rounded flex items-center"
              >
                <ArrowPathIcon className="w-3 h-3 mr-1" />
                Reintentar
              </button>
              <button 
                onClick={() => handleDiscard(id)}
                className="text-xs bg-gray-100 hover:bg-gray-200 text-gray-700 px-2 py-1 rounded"
              >
                Descartar
              </button>
            </div>
          </div>
        )
      }
      
      return (
        <div key={id} style={style} className="bg-blue-50 border border-blue-200 rounded-md p-2 shadow-md max-w-md">
          <div className="text-xs text-blue-700 mb-2 font-medium">
            Sugerencia de IA
          </div>
          
          <div className="text-xs text-gray-700 mb-2 max-h-32 overflow-y-auto">
            {data.suggestion}
          </div>
          
          {furtherInstructions[id] ? (
            <div className="mb-2">
              <input
                type="text"
                value={instructionInputs[id] || ''}
                onChange={(e) => handleInstructionChange(id, e.target.value)}
                placeholder="Añade instrucciones adicionales..."
                className="w-full text-xs border border-blue-200 rounded p-1"
              />
              <div className="flex justify-end mt-1">
                <button
                  onClick={() => submitFurtherInstructions(id)}
                  disabled={!instructionInputs[id]}
                  className={`text-xs px-2 py-1 rounded flex items-center ${
                    instructionInputs[id] 
                      ? 'bg-blue-100 hover:bg-blue-200 text-blue-700' 
                      : 'bg-gray-100 text-gray-400'
                  }`}
                >
                  <PaperAirplaneIcon className="w-3 h-3 mr-1" />
                  Enviar
                </button>
              </div>
            </div>
          ) : (
            <div className="flex justify-between items-center">
              <div>
                <button 
                  onClick={() => toggleFurtherInstructions(id)}
                  className="text-xs bg-blue-100 hover:bg-blue-200 text-blue-700 px-2 py-1 rounded mr-2"
                >
                  Mejorar
                </button>
              </div>
              <div className="flex">
                <button 
                  onClick={() => handleDiscard(id)}
                  className="text-xs bg-gray-100 hover:bg-gray-200 text-gray-700 px-2 py-1 rounded mr-2"
                >
                  Descartar
                </button>
                <button 
                  onClick={() => handleAccept(id)}
                  className="text-xs bg-green-100 hover:bg-green-200 text-green-700 px-2 py-1 rounded flex items-center"
                >
                  <CheckIcon className="w-3 h-3 mr-1" />
                  Aceptar
                </button>
              </div>
            </div>
          )}
        </div>
      )
    })
  }

  // Force re-render when editor updates
  useEffect(() => {
    if (!editor) return
    
    const updateListener = () => {
      // Force re-render to update positions
      setSuggestions(prev => ({ ...prev }))
    }
    
    editor.on('update', updateListener)
    
    return () => {
      editor.off('update', updateListener)
    }
  }, [editor])

  if (!editor || !portalContainer) return null

  return createPortal(
    <div ref={containerRef} className="llm-suggestion-ui-container">
      {renderUI()}
    </div>,
    portalContainer
  )
}

export default LlmSuggestionView 