import { Controller } from "@hotwired/stimulus"
import { scrollAndAnimateThread } from "../utils/document_threads_utils"

/**
 * Handle the interaction between the editor's comment marks
 * and the comments sidebar.
 */
export default class extends Controller {
  static values = {
    threadId: String,
    commentsCount: Number,
    destroyThreadUrl: String
  }

  connect() {
    // Listen for thread/comment-related events
    this.boundHandleShowThread = this._handleShowThread.bind(this)
    this.boundHandleCommentAdded = this._handleCommentAdded.bind(this)
    this.boundHandleDestroyThread = this._handleDestroyThread.bind(this)
    window.addEventListener('comments:show', this.boundHandleShowThread)
    window.addEventListener('comments:destroy', this.boundHandleDestroyThread)
    document.addEventListener('turbo:before-stream-render', this.boundHandleCommentAdded)

    // Always sync the editor comment when connecting in case there was an error when updating
    this._updateEditorComment(this.threadIdValue, this.threadIdValue, this.commentsCountValue);
  }

  disconnect() {
    window.removeEventListener('comments:show', this.boundHandleShowThread)
    window.removeEventListener('comments:destroy', this.boundHandleDestroyThread)
    document.removeEventListener('turbo:before-stream-render', this.boundHandleCommentAdded)
  }

  threadClicked(event) {
    // If the click is on a link, open it in a new tab
    if (event.target.tagName === 'A') {
      event.preventDefault();
      window.open(event.target.href, '_blank');
      return;
    }

    console.log("Thread clicked:", event)

    // Only proceed if the click was directly on the thread container
    // and not on any internal elements like a button or form
    if (event.target.closest('.comment-content')) {
      // Dispatch a custom event to trigger the editor to scroll to the comment mark
      window.dispatchEvent(new CustomEvent('editor:scroll-to-comment', {
        detail: { threadId: this.threadIdValue }
      }));

      // Update the URL with the thread URL only if the thread in the event is the same as the thread we're tracking
      if (event.detail.threadId === this.threadIdValue) {
        this._updateUrl();
      }
    }
  }

  // private

  _updateUrl() {
    const threadUrl = this.element.dataset.threadUrl;
    if (threadUrl) {
      // Use replaceState to update URL without creating a new history entry
      history.replaceState({}, '', threadUrl);
    }
  }

  _handleCommentAdded(event) {

    // Check if this is a replace action to a comment thread
    if (event.target.target && event.target.action === "replace") {
      const targetElement = document.getElementById(event.target.target);
      if (targetElement && targetElement.id.includes("writer_document_thread")) {
        // Extract the thread ID from the target element ID
        const threadId = targetElement.id.split("_").pop();
        if (!threadId) return;

        // Only update if this is the thread we're tracking
        if (threadId !== this.threadIdValue) return;

        // Update the comment count
        this.commentsCountValue += 1;

        // Update the comment count in the editor
        this._updateEditorComment(threadId, threadId, this.commentsCountValue);
      }
    }
  }

  /**
   * Handles showing/highlighting an existing comment thread
   * @param {CustomEvent} event - Contains threadId to show
   */
  _handleShowThread(event) {
    const threadId = event.detail.threadId;

    // Find the thread container with the matching thread ID
    const threadContainer = document.querySelector(`.comment-thread[data-thread-id="${threadId}"]`);
    if (!threadContainer) return;

    // Find the toggle controller within this thread
    const toggleController = threadContainer.closest('[data-controller*="toggle"]');
    if (!toggleController) return;

    // Find the toggle container within this thread
    const toggleContainer = threadContainer.querySelector('[data-toggle-target="toggleContainer"]');
    if (!toggleContainer) return;

    // Find the toggle button within this thread
    const toggleButton = threadContainer.querySelector('[data-toggle-target="button"]');
    if (!toggleButton) return;

    // Dispatch a custom event to trigger the toggle
    const toggleEvent = new CustomEvent('toggle:show', {
      detail: {
        container: toggleContainer,
        button: toggleButton
      }
    });

    toggleController.dispatchEvent(toggleEvent);

    scrollAndAnimateThread(threadContainer);

    // Update the URL with the thread URL only if the thread in the event is the same as the thread we're tracking
    if (threadId === this.threadIdValue) {
      this._updateUrl();
    }
  }

  /**
   * Updates the comment count in the editor
   * @param {String} oldThreadId - The ID of the thread to update
   * @param {String} newThreadId - The new ID for the thread (usually the same)
   * @param {Number} commentCount - The new comment count
   * @private
   */
  _updateEditorComment(oldThreadId, newThreadId, commentCount) {
    window.dispatchEvent(new CustomEvent('editor:update-comment', {
      detail: {
        oldThreadId: String(oldThreadId),
        thread: {
          id: String(newThreadId),
          commentCount: commentCount
        }
      }
    }));
  }

  /**
   * Handles the destruction of a comment thread
   * @param {CustomEvent} event - Contains threadId to destroy
   * @private
   */
  _handleDestroyThread(event) {
    const threadId = event.detail.threadId;

    // Only proceed if this controller handles this specific thread
    if (this.threadIdValue !== threadId) return;

    console.log("Destroying thread:", threadId)

    const url = new URL(this.destroyThreadUrlValue, window.location.origin)

    const headers = {
      Accept: "text/vnd.turbo-stream.html",
      "Content-Type": "application/json",
      "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content,
    };

    const requestOptions = {
      method: "DELETE",
      headers: headers
    };

    fetch(url, requestOptions)
      .then((response) => response.text())
      .then(html => {
        Turbo.renderStreamMessage(html);
      })
  }
}
