import { useCallback, useEffect } from 'react'

/**
 * Custom hook for handling variable-related events
 * 
 * @param {Object} editor - TipTap editor instance
 * @returns {Object} Variable event handlers
 */
export const useVariableHandlers = (editor) => {
  const handleVariablesUpdate = useCallback((event) => {
    if (editor) {
      requestAnimationFrame(() => {
        editor.commands.updateVariables(event.detail)
      })
    }
  }, [editor])

  const handleVariableInsert = useCallback((event) => {
    if (editor) {
      requestAnimationFrame(() => {
        editor.commands.insertVariable({
          id: event.detail.key,
          label: event.detail.label,
          value: event.detail.value
        })
      })
    }
  }, [editor])

  useEffect(() => {
    window.addEventListener('variables-updated', handleVariablesUpdate)
    window.addEventListener('insert-variable', handleVariableInsert)
    
    return () => {
      window.removeEventListener('variables-updated', handleVariablesUpdate)
      window.removeEventListener('insert-variable', handleVariableInsert)
    }
  }, [handleVariablesUpdate, handleVariableInsert])

  return {
    handleVariablesUpdate,
    handleVariableInsert
  }
} 