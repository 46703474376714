import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { HEADING_LEVELS, BUTTON_VARIANTS } from '../../constants/editor-config'
import { EditorContext } from '../../context/EditorContext'

// SVG icons for toolbar buttons
const ICONS = {
  bold: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" className="w-5 h-5">
      <path fill="none" d="M0 0h24v24H0z"/>
      <path d="M8 11h4.5a2.5 2.5 0 1 0 0-5H8v5zm10 4.5a4.5 4.5 0 0 1-4.5 4.5H6V4h6.5a4.5 4.5 0 0 1 3.256 7.606A4.498 4.498 0 0 1 18 15.5zM8 13v5h5.5a2.5 2.5 0 1 0 0-5H8z"/>
    </svg>
  ),
  italic: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" className="w-5 h-5">
      <path fill="none" d="M0 0h24v24H0z"/>
      <path d="M15 20H7v-2h2.927l2.116-12H9V4h8v2h-2.927l-2.116 12H15z"/>
    </svg>
  ),
  strike: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" className="w-5 h-5">
      <path fill="none" d="M0 0h24v24H0z"/>
      <path d="M17.154 14c.23.516.346 1.09.346 1.72 0 1.342-.524 2.392-1.571 3.147C14.88 19.622 13.433 20 11.586 20c-1.64 0-3.263-.381-4.87-1.144V16.6c1.52.877 3.075 1.316 4.666 1.316 2.551 0 3.83-.732 3.839-2.197a2.21 2.21 0 0 0-.648-1.603l-.12-.117H3v-2h18v2h-3.846zm-4.078-3H7.629a4.086 4.086 0 0 1-.481-.522C6.716 9.92 6.5 9.246 6.5 8.452c0-1.236.466-2.287 1.397-3.153C8.83 4.433 10.271 4 12.222 4c1.471 0 2.879.328 4.222.984v2.152c-1.2-.687-2.515-1.03-3.946-1.03-2.48 0-3.719.782-3.719 2.346 0 .42.218.786.654 1.099.436.313.974.562 1.613.75.62.18 1.297.414 2.03.699z"/>
    </svg>
  ),
  code: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" className="w-5 h-5">
      <path fill="none" d="M0 0h24v24H0z"/>
      <path d="M24 12l-5.657 5.657-1.414-1.414L21.172 12l-4.243-4.243 1.414-1.414L24 12zM2.828 12l4.243 4.243-1.414 1.414L0 12l5.657-5.657L7.07 7.757 2.828 12zm6.96 9H7.66l6.552-18h2.128L9.788 21z"/>
    </svg>
  ),
  bulletList: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" className="w-5 h-5">
      <path fill="none" d="M0 0h24v24H0z"/>
      <path d="M8 4h13v2H8V4zM4.5 6.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 7a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 6.9a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM8 11h13v2H8v-2zm0 7h13v2H8v-2z"/>
    </svg>
  ),
  orderedList: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" className="w-5 h-5">
      <path fill="none" d="M0 0h24v24H0z"/>
      <path d="M8 4h13v2H8V4zM5 3v3h1v1H3V6h1V4H3V3h2zm2 8h13v2H7v-2zm-2-1v3h1v1H3v-1h1v-1H3v-2h2zm2 8h13v2H7v-2zm-2-1v3h1v1H3v-1h1v-1H3v-2h2z"/>
    </svg>
  ),
  alignLeft: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" className="w-5 h-5">
      <path fill="none" d="M0 0h24v24H0z"/>
      <path d="M3 4h18v2H3V4zm0 15h14v2H3v-2zm0-5h18v2H3v-2zm0-5h14v2H3V9z"/>
    </svg>
  ),
  alignCenter: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" className="w-5 h-5">
      <path fill="none" d="M0 0h24v24H0z"/>
      <path d="M3 4h18v2H3V4zm2 15h14v2H5v-2zm-2-5h18v2H3v-2zm2-5h14v2H5V9z"/>
    </svg>
  ),
  alignRight: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" className="w-5 h-5">
      <path fill="none" d="M0 0h24v24H0z"/>
      <path d="M3 4h18v2H3V4zm4 15h14v2H7v-2zm-4-5h18v2H3v-2zm4-5h14v2H7V9z"/>
    </svg>
  ),
  alignJustify: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" className="w-5 h-5">
      <path fill="none" d="M0 0h24v24H0z"/>
      <path d="M3 4h18v2H3V4zm0 15h18v2H3v-2zm0-5h18v2H3v-2zm0-5h18v2H3V9z"/>
    </svg>
  ),
  link: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" className="w-5 h-5">
      <path fill="none" d="M0 0h24v24H0z"/>
      <path d="M18.364 15.536L16.95 14.12l1.414-1.414a5 5 0 1 0-7.071-7.071L9.879 7.05 8.464 5.636 9.88 4.222a7 7 0 0 1 9.9 9.9l-1.415 1.414zm-2.828 2.828l-1.415 1.414a7 7 0 0 1-9.9-9.9l1.415-1.414L7.05 9.88l-1.414 1.414a5 5 0 1 0 7.071 7.071l1.414-1.414 1.415 1.414zm-.708-10.607l1.415 1.415-7.071 7.07-1.415-1.414 7.071-7.07z"/>
    </svg>
  ),
  undo: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" className="w-5 h-5">
      <path fill="none" d="M0 0h24v24H0z"/>
      <path d="M5.828 7l2.536 2.536L6.95 10.95 2 6l4.95-4.95 1.414 1.414L5.828 5H13a8 8 0 1 1 0 16H4v-2h9a6 6 0 1 0 0-12H5.828z"/>
    </svg>
  ),
  redo: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" className="w-5 h-5">
      <path fill="none" d="M0 0h24v24H0z"/>
      <path d="M18.172 7H11a6 6 0 1 0 0 12h9v2h-9a8 8 0 1 1 0-16h7.172l-2.536-2.536L17.05 1.05 22 6l-4.95 4.95-1.414-1.414L18.172 7z"/>
    </svg>
  ),
  save: (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
      <path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z" clipRule="evenodd" />
    </svg>
  ),
  versions: (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
      <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
    </svg>
  ),
  check: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" className="w-5 h-5">
      <path fill="none" d="M0 0h24v24H0z"/>
      <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/>
    </svg>
  ),
  download: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" className="w-5 h-5">
      <path fill="none" d="M0 0h24v24H0z"/>
      <path d="M3 19h18v2H3v-2zm10-5.828L19.071 7.1l1.414 1.414L12 17 3.515 8.515 4.929 7.1 11 13.17V2h2v11.172z"/>
    </svg>
  ),
  clause: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" className="w-5 h-5" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"/>
    </svg>
  ),
  help: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-5 h-5" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <circle cx="12" cy="12" r="10"></circle>
      <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
      <line x1="12" y1="17" x2="12.01" y2="17"></line>
    </svg>
  )
}

/**
 * Toolbar component that provides formatting options and variable insertion
 * @param {Object} editor - The TipTap editor instance
 * @param {Array} variables - Available variables that can be inserted
 * @param {Function} onSaveVersion - Callback to save current version
 * @param {Function} onVersionSelect - Callback to select a version
 * @param {Array} versions - List of available versions
 * @param {number} currentVersion - ID of the current version
 */
export default function Toolbar({ 
  editor, 
  variables, 
  onSaveVersion, 
  onVersionSelect, 
  versions = [], 
  currentVersion,
  showVariableDropdown = false
}) {
  const [linkUrl, setLinkUrl] = useState('')
  const [showLinkInput, setShowLinkInput] = useState(false)
  const [showVersions, setShowVersions] = useState(false)
  const { showHelp, setShowHelp } = useContext(EditorContext)

  if (!editor) {
    return null
  }

  // Helper function to insert a variable at the current cursor position
  const _insertVariable = (variable) => {
    editor.commands.insertVariable({
      id: variable.label.toLowerCase().replace(/\s+/g, '-'),
      label: variable.label,
      value: variable.value
    })
  }

  const setLink = () => {
    if (linkUrl === '') {
      editor.chain().focus().unsetLink().run()
      return
    }

    editor.chain().focus().setLink({ href: linkUrl }).run()
    setLinkUrl('')
    setShowLinkInput(false)
  }

  const formatDate = (date) => {
    return new Intl.DateTimeFormat('default', {
      dateStyle: 'medium',
      timeStyle: 'short'
    }).format(new Date(date))
  }

  const renderButton = (props) => {
    const { icon, title, action, isActive = null } = props
    const active = isActive !== null ? isActive : editor.isActive(title.toLowerCase())

    return (
      <button
        onClick={action}
        className={`editor-button ${active ? 'editor-button-active' : ''}`}
        title={title}
      >
        <span className="button-icon">{icon}</span>
      </button>
    )
  }

  return (
    <div className="editor-toolbar">
      <div className="flex justify-between">
        <div className="flex space-x-2">

          {/* Text formatting */}
          <div className="editor-button-group">
            <select
            onChange={(e) => {
              const level = e.target.value
              if (level === 'paragraph') {
                editor.chain().focus().setParagraph().run()
              } else {
                editor.chain().focus().toggleHeading({ level: parseInt(level) }).run()
              }
              e.target.value = '' // Reset select
            }}
            value=""
            className="toolbar-input"
            >
              <option value="">Formato</option>
              <option value="paragraph">Párrafo</option>
              {HEADING_LEVELS.map(level => (
                <option key={level} value={level}>H{level}</option>
              ))}
            </select>
          </div>

          {/* Undo/Redo */}
          <div className="editor-button-group">
            {renderButton({
              title: 'Undo',
              action: () => editor.chain().focus().undo().run(),
              isActive: false,
              icon: ICONS.undo
            })}

            {renderButton({
              title: 'Redo',
              action: () => editor.chain().focus().redo().run(),
              isActive: false,
              icon: ICONS.redo
            })}
          </div>

          {/* Basic formatting */}
          <div className="editor-button-group">
            {renderButton({
              title: 'Bold',
              action: () => editor.chain().focus().toggleBold().run(),
              icon: ICONS.bold
            })}

            {renderButton({
              title: 'Italic',
              action: () => editor.chain().focus().toggleItalic().run(),
              icon: ICONS.italic
            })}

            {renderButton({
              title: 'Strike',
              action: () => editor.chain().focus().toggleStrike().run(),
              icon: ICONS.strike
            })}

            {renderButton({
              title: 'Code',
              action: () => editor.chain().focus().toggleCode().run(),
              icon: ICONS.code
            })}
          </div>

          {/* Lists */}
          <div className="editor-button-group">
            {renderButton({
              title: 'Bullet List',
              action: () => editor.chain().focus().toggleBulletList().run(),
              icon: ICONS.bulletList
            })}

            {renderButton({
              title: 'Ordered List',
              action: () => editor.chain().focus().toggleOrderedList().run(),
              icon: ICONS.orderedList
            })}
          </div>

          {/* Alignment */}
          <div className="editor-button-group">
            {renderButton({
              title: 'Left',
              action: () => editor.chain().focus().setTextAlign('left').run(),
              isActive: editor.isActive({ textAlign: 'left' }),
              icon: ICONS.alignLeft
            })}

            {renderButton({
              title: 'Center',
              action: () => editor.chain().focus().setTextAlign('center').run(),
              isActive: editor.isActive({ textAlign: 'center' }),
              icon: ICONS.alignCenter
            })}

            {renderButton({
              title: 'Right',
              action: () => editor.chain().focus().setTextAlign('right').run(),
              isActive: editor.isActive({ textAlign: 'right' }),
              icon: ICONS.alignRight
            })}

            {renderButton({
              title: 'Justify',
              action: () => editor.chain().focus().setTextAlign('justify').run(),
              isActive: editor.isActive({ textAlign: 'justify' }),
              icon: ICONS.alignJustify
            })}
          </div>

          {/* Links 
          <div className="editor-button-group">
            {showLinkInput ? (
              <div className="flex items-center gap-1">
                <input
                  type="text"
                  placeholder="Enter URL"
                  value={linkUrl}
                  onChange={(e) => setLinkUrl(e.target.value)}
                  className="toolbar-input"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setLink()
                    }
                  }}
                />
                <button
                  onClick={setLink}
                  className="p-1 rounded hover:bg-gray-100"
                  title="Save"
                >
                  {ICONS.check}
                </button>
              </div>
            ) : (
              renderButton({
                title: 'Link',
                action: () => setShowLinkInput(true),
                isActive: editor.isActive('link'),
                icon: ICONS.link
              })
            )}
          </div>
          */}

          {/* Variable insertion dropdown */}
          <select 
            onChange={(e) => {
              const variable = variables.find(v => v.label === e.target.value)
              if (variable) {
                _insertVariable(variable)
              }
              e.target.value = '' // Reset select after insertion
            }}
            className="toolbar-input"
            value=""
          >
            <option value="">Insert variable...</option>
            {/* Filter out duplicate variables by creating a Map with key as the unique identifier */}
            {Array.from(
              new Map(variables.map(variable => [variable.key, variable])).values()
            ).map((variable) => (
              <option key={variable.key} value={variable.label}>
                {variable.label}
              </option>
            ))}
          </select>
          
          {/* Convert to Clause */}
          <div className="editor-button-group">
            {renderButton({
              title: 'Convert to Clause',
              action: () => editor.commands.convertToClause(),
              isActive: editor.isActive('clauseBlock'),
              icon: ICONS.clause
            })}

            {/* 
            {editor.isActive('clauseBlock') && (
              <select
                value={editor.getAttributes('clauseBlock').type || ''}
                onChange={e => editor.commands.setClauseType(e.target.value || null)}
                className="toolbar-input"
              >
                <option value="">Select type</option>
                {editor.extensionManager.extensions
                  .find(ext => ext.name === 'clauseBlock')
                  ?.options?.types?.map(type => (
                    <option key={type.slug} value={type.slug}>
                      {type.literal}
                    </option>
                  ))}
              </select>
            )}
            */}
          </div>
        </div>
      </div>

      {/* Help toggle */}
      <div className="editor-button-group">
        {renderButton({
          title: showHelp ? 'Hide Help' : 'Show Help',
          action: () => setShowHelp(!showHelp),
          isActive: showHelp,
          icon: ICONS.help
        })}
      </div>
    </div>
  )
}

Toolbar.propTypes = {
  editor: PropTypes.object,
  variables: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })).isRequired,
  onSaveVersion: PropTypes.func.isRequired,
  onVersionSelect: PropTypes.func.isRequired,
  versions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    content: PropTypes.object.isRequired,
    timestamp: PropTypes.instanceOf(Date).isRequired,
    label: PropTypes.string.isRequired
  })),
  currentVersion: PropTypes.number,
  showVariableDropdown: PropTypes.bool
} 