import { Mark, mergeAttributes } from '@tiptap/core'
import { Plugin } from 'prosemirror-state'

const COMMENT_SELECTORS = {
  THREAD_ID: 'data-thread-id',
  COMMENT_COUNT: 'data-comment-count',
  TAB: '[data-tabs-target="tabItem"][data-tabs-controls-param="comments"]'
}

/**
 * Activates the comments tab in the sidebar
 */
const activateCommentsTab = () => {
  window.dispatchEvent(new CustomEvent('sidebar-tabs:activate', {
    detail: { tab: 'comments' }
  }))
}

export const CommentMark = Mark.create({
  name: 'comment',

  addOptions() {
    return {
      HTMLAttributes: {
        class: 'comment-mark'
      }
    }
  },

  addAttributes() {
    return {
      threadId: {
        default: null,
        parseHTML: element => element.getAttribute(COMMENT_SELECTORS.THREAD_ID),
        renderHTML: attributes => {
          if (!attributes.threadId) return {}
          return { [COMMENT_SELECTORS.THREAD_ID]: attributes.threadId }
        }
      },
      commentCount: {
        default: 0,
        parseHTML: element => parseInt(element.getAttribute(COMMENT_SELECTORS.COMMENT_COUNT) || '0', 10),
        renderHTML: attributes => {
          if (!attributes.commentCount) return {}
          return { [COMMENT_SELECTORS.COMMENT_COUNT]: attributes.commentCount }
        }
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: `span[${COMMENT_SELECTORS.THREAD_ID}]`
      }
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },

  addCommands() {
    return {
      setComment: attributes => ({ commands }) => {
        return commands.setMark(this.name, attributes)
      },

      unsetComment: () => ({ commands, editor }) => {
        // Store the threadId before removing the mark
        let threadId = null
        if (editor.isActive('comment')) {
          threadId = editor.getAttributes('comment').threadId
        }

        if (threadId) {
          // Add confirmation dialog
          if (!confirm(I18n.are_you_sure_writer_document_threads)) {
            return false
          }

          console.log("Dispatching comments:destroy for thread:", threadId)
          window.dispatchEvent(new CustomEvent('comments:destroy', {
            detail: { threadId }
          }))
        }

        return commands.unsetMark(this.name)
      },

      toggleComment: () => ({ commands, editor }) => {
        if (editor.isActive('comment')) {
          return commands.unsetComment()
        }

        if (!editor.state.selection.empty) {
          // Generate temporary ID for the new thread
          const tempThreadId = `temp-${Date.now()}`

          // Create mark with temporary ID and 0 comments
          commands.setMark('comment', {
            threadId: tempThreadId,
            commentCount: 0
          })

          // Dispatch event to create thread UI
          window.dispatchEvent(new CustomEvent('comments:new', {
            detail: {
              threadId: tempThreadId,
              selection: {
                from: editor.state.selection.from,
                to: editor.state.selection.to,
                text: editor.state.doc.textBetween(
                  editor.state.selection.from,
                  editor.state.selection.to,
                  ' '
                )
              }
            }
          }))

          // Activate comments tab
          activateCommentsTab()

          return true
        }
      },

      /**
       * Updates an existing comment mark with new thread ID and/or comment count
       */
      updateComment: (oldThreadId, attrs) => ({ tr, state, dispatch }) => {
        let updated = false

        // Ensure we're working with string IDs for comparison
        const searchThreadId = String(oldThreadId);

        state.doc.descendants((node, pos) => {
          // Find marks of this type on the node
          const commentMarks = node.marks.filter(m => m.type.name === this.name);

          // Find the specific mark with matching threadId
          const mark = commentMarks.find(m => String(m.attrs.threadId) === searchThreadId);

          if (mark) {
            const newMark = this.type.create({
              ...mark.attrs,
              threadId: attrs.threadId || mark.attrs.threadId,
              commentCount: attrs.commentCount || mark.attrs.commentCount
            });

            tr.addMark(pos, pos + node.nodeSize, newMark);
            updated = true;
          }
        });

        if (updated && dispatch) {
          dispatch(tr);
        }

        return updated;
      }
    }
  },

  addKeyboardShortcuts() {
    return {
      'Mod-Alt-m': () => this.editor.commands.toggleComment()
    }
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          handleClick(view, pos) {
            const { state } = view
            const marks = state.doc.resolve(pos).marks()
            const commentMark = marks.find(mark => mark.type.name === 'comment')

            if (commentMark) {
              // Dispatch event to show thread
              console.log("dispatching comments:show", commentMark.attrs.threadId)
              window.dispatchEvent(new CustomEvent('comments:show', {
                detail: { threadId: commentMark.attrs.threadId }
              }))

              // Activate comments tab
              activateCommentsTab()

              return true
            }

            return false
          }
        }
      })
    ]
  }
})

export default CommentMark
