import { useCallback, useEffect } from 'react'

/**
 * Custom hook for handling comment-related events in the editor
 *
 * @param {Object} editor - TipTap editor instance
 * @returns {Object} Comment event handlers
 */
export const useCommentHandlers = (editor) => {
  const handleCommentUpdate = useCallback((event) => {
    if (editor) {
      const { oldThreadId, thread } = event.detail

      // Make sure we have all the required data
      if (!oldThreadId || !thread || !thread.id || typeof thread.commentCount !== 'number') {
        return;
      }

      // Use requestAnimationFrame to ensure DOM is ready
      requestAnimationFrame(() => {
        editor.commands.updateComment(oldThreadId, {
          threadId: thread.id,
          commentCount: thread.commentCount,
          selection: thread.selection
        })
      })
    }
  }, [editor])

  useEffect(() => {
    window.addEventListener('editor:update-comment', handleCommentUpdate);

    return () => {
      window.removeEventListener('editor:update-comment', handleCommentUpdate);
    }
  }, [handleCommentUpdate])

  return {
    handleCommentUpdate
  }
}
