/**
 * Compare two nodes for equality
 * 
 * @param {Object} node1 - First node to compare
 * @param {Object} node2 - Second node to compare
 * @returns {boolean} - Whether the nodes are equal
 */
export const compareNodes = (node1, node2) => {
  if (!node1 || !node2) return false
  if (node1.type !== node2.type) return false
  
  // Compare text nodes
  if (node1.type === 'text') {
    return node1.text === node2.text
  }
  
  // Compare variable nodes
  if (node1.type === 'variable') {
    return node1.attrs.id === node2.attrs.id
  }
  
  // Compare other attributes if they exist
  if (node1.attrs && node2.attrs) {
    if (JSON.stringify(node1.attrs) !== JSON.stringify(node2.attrs)) {
      return false
    }
  }
  
  // Compare content arrays
  if (node1.content && node2.content) {
    if (node1.content.length !== node2.content.length) return false
    return node1.content.every((child, index) => compareNodes(child, node2.content[index]))
  }
  
  return true
}

/**
 * Mark node differences with diff marks
 * 
 * @param {Object} oldNode - Original node
 * @param {Object} newNode - New node to compare against
 * @param {boolean} isDeleted - Whether the node was deleted
 * @returns {Object} - Node with diff marks applied
 */
export const markNodeDiff = (oldNode, newNode, isDeleted = false) => {
  if (!newNode) return oldNode // Return old node for deletions
  if (!oldNode) return { ...newNode } // Return new node as is for additions

  let node = JSON.parse(JSON.stringify(newNode))
  
  // Mark text nodes with changes
  if (node.type === 'text' && oldNode.type === 'text' && node.text !== oldNode.text) {
    node.marks = node.marks || []
    node.marks.push({
      type: 'diffMark',
      attrs: {
        type: isDeleted ? 'deletion' : 'addition'
      }
    })
  }
  
  // Handle variable nodes
  if (node.type === 'variable') {
    return node // Keep variables as they are
  }
  
  // Recursively process content
  if (node.content && oldNode.content) {
    node.content = node.content.map((child, index) => {
      const oldChild = oldNode.content[index]
      if (!oldChild) return child // New node
      return markNodeDiff(oldChild, child, isDeleted)
    })
  }
  
  return node
}

/**
 * Generate diff content by comparing old and new content
 * 
 * @param {Object} oldContent - Original content
 * @param {Object} newContent - New content to compare against
 * @returns {Object} - Content with diff marks applied
 */
export const generateDiffContent = (oldContent, newContent) => {
  // Create a new document with the same structure
  const diffContent = {
    type: 'doc',
    content: []
  }

  // Get the longer content array to iterate through
  const maxLength = Math.max(
    oldContent.content?.length || 0,
    newContent.content?.length || 0
  )

  // Process all nodes
  for (let i = 0; i < maxLength; i++) {
    const oldNode = oldContent.content?.[i]
    const newNode = newContent.content?.[i]

    if (!newNode) {
      // Node was deleted
      diffContent.content.push(markNodeDiff(oldNode, oldNode, true))
    } else if (!oldNode) {
      // Node was added
      diffContent.content.push(markNodeDiff(null, newNode, false))
    } else {
      // Node exists in both versions
      diffContent.content.push(markNodeDiff(oldNode, newNode, false))
    }
  }

  return diffContent
} 