import { Controller } from "@hotwired/stimulus"

/**
 * Shortcut Submitable Form Controller
 *
 * This controller allows submitting a form using Cmd+Enter (or Ctrl+Enter).
 *
 * Example usage:
 *
 * <form data-controller="shortcut-submitable-form" data-shortcut-submitable-form-target="form">
 *   <textarea></textarea>
 * </form>
 */
export default class extends Controller {
  static targets = ["form"]

  connect() {
    this.boundKeydownHandler = this._handleKeydown.bind(this)
    document.addEventListener("keydown", this.boundKeydownHandler)
  }

  disconnect() {
    document.removeEventListener("keydown", this.boundKeydownHandler)
  }

  // private

  _handleKeydown(event) {
    // Check if Cmd+Enter (Mac) or Ctrl+Enter (Windows) was pressed
    if (event.key === "Enter" && (event.metaKey || event.ctrlKey)) {
      // Check if the active element is a textarea within our form
      const activeElement = document.activeElement
      if (activeElement.tagName === "TEXTAREA" && this.element.contains(activeElement)) {
        event.preventDefault()
        this._submitForm()
      }
    }
  }

  _submitForm() {
    if (this.hasFormTarget) {
      // Verificar si el formulario es válido antes de enviarlo
      if (this.formTarget.checkValidity()) {
        this.formTarget.requestSubmit()
      } else {
        // Activar las validaciones visuales del navegador
        this.formTarget.reportValidity()
      }
    }
  }
}
