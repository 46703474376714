/**
 * Editor configuration constants
 */

export const HEADING_LEVELS = [1, 2, 3, 4, 5]

export const LINK_CONFIG = {
  openOnClick: false,
  HTMLAttributes: {
    class: 'text-blue-600 hover:text-blue-800 underline'
  }
}

export const TEXT_ALIGN_TYPES = ['heading', 'paragraph']

// export const EDITOR_CLASSES = 'prose prose-sm max-w-none focus:outline-none'
export const EDITOR_CLASSES = 'editor-text focus:outline-none'

export const BUTTON_VARIANTS = {
  primary: 'px-3 py-1 rounded text-sm bg-blue-600 text-white hover:bg-blue-700',
  secondary: 'px-3 py-1 rounded text-sm bg-gray-100 text-gray-700 hover:bg-gray-200',
  help: {
    active: 'px-3 py-1 rounded text-sm bg-blue-100 text-blue-700 hover:bg-blue-200',
    inactive: 'px-3 py-1 rounded text-sm bg-gray-100 text-gray-700 hover:bg-gray-200'
  }
}

export const VERSION_LABELS = {
  initial: 'Initial Version',
  preRestore: '(pre-restore)'
} 