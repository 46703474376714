import React, { forwardRef, useImperativeHandle, useState } from 'react'
import Dropdown from './editor/Dropdown'

export default forwardRef(function SlashMenuSuggestions({ items, command, clientRect }, ref) {
  const [selectedIndex, setSelectedIndex] = useState(0)

  const options = items.map((item, index) => ({
    value: index.toString(),
    label: item.title,
    description: item.description
  }))

  useImperativeHandle(ref, () => ({
    onKeyDown: () => false
  }))

  if (!items?.length) return null

  return (
    <Dropdown
      trigger={<div style={{ display: 'none' }} />}
      options={options}
      value={options[selectedIndex]?.value}
      onSelect={(value) => {
        const item = items[parseInt(value)]
        if (item) command(item)
      }}
      showSearch={false}
      showHeader={false}
      placement="bottom-start"
      maxHeight="300px"
      className="slash-menu-suggestions"
      forceShow={true}
      getReferenceClientRect={clientRect}
      renderOption={(option) => (
        <div className="flex flex-col py-1">
          <span className="font-medium">{option.label}</span>
          <span className="text-sm text-gray-500">{option.description}</span>
        </div>
      )}
    />
  )
}) 