import React, { useState, useEffect, useRef, memo } from 'react'
import { DocumentTextIcon } from '@heroicons/react/24/solid'

const STYLES = {
  lineIndicator: "fixed z-50 left-2 top-1/2 -translate-y-1/2 w-6 cursor-pointer transition bg-white p-2 rounded-md border hover:bg-gray-50",
  tocContainer: "fixed z-50 left-10 top-32 w-72 bg-white rounded-lg shadow-lg border border-gray-200 animate-in slide-in-from-right",
  nav: "py-3 px-5 max-h-[calc(100vh-200px)] overflow-y-auto",
  tocList: "space-y-1",
  button: (isActive, textStyle) => 
    `group w-full p-1 leading-tight flex text-tiny items-center rounded transition-colors ${textStyle} ${
      isActive ? 'bg-blue-100' : 'hover:bg-blue-50'
    }`,
  icon: "w-3 h-3 mr-1 text-blue -ml-4",
  childList: "mt-1 space-y-0.5"
}

const TEXT_STYLES = {
  clauseHeading: "font-medium",
  heading: {
    1: "text-gray-800 font-medium",
    2: "text-gray-700",
    3: "text-gray-600 text-sm",
    4: "text-gray-500 text-sm",
    5: "text-gray-500 text-sm",
    6: "text-gray-500 text-sm",
    default: "text-gray-400"
  }
}

const LineIndicator = memo(({ toc, onClick }) => (
  <div 
    className={STYLES.lineIndicator}
    onClick={onClick}
    role="button"
    aria-label="Toggle table of contents"
    tabIndex={0}
  >
    <div className="space-y-1.5">
      {toc.map(item => (
        <div key={item.id} className="flex justify-end space-x-0.5">
          {[...Array(item.type === 'heading' ? item.level : 1)].map((_, i) => (
            <div
              key={i}
              className={`h-0.5 bg-gray-300 transition-all duration-200 ${
                i === 0 ? 'w-8' : 'w-4'
              }`}
            />
          ))}
        </div>
      ))}
    </div>
  </div>
))

const TocItem = memo(({ item, onHeadingClick, visibleHeadingPos }) => {
  const itemRef = useRef(null)
  const indentStyle = item.type === 'heading' ? 
    { paddingLeft: `${(item.level - 1) * 16}px` } : {}

  const getTextStyle = () => {
    if (item.isClauseHeading) return TEXT_STYLES.clauseHeading
    return TEXT_STYLES.heading[item.level] || TEXT_STYLES.heading.default
  }

  const isActive = Math.abs(item.pos - visibleHeadingPos) <= 1

  useEffect(() => {
    if (isActive && itemRef.current) {
      itemRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      })
    }
  }, [isActive])

  return (
    <li ref={itemRef} style={indentStyle} role="none">
      <button 
        onClick={() => onHeadingClick(item.pos)} 
        className={STYLES.button(isActive, getTextStyle())}
        role="menuitem"
        aria-current={isActive ? 'true' : undefined}
        aria-label={`Go to section: ${item.text}`}
      >
        {item.isClauseHeading && (
          <DocumentTextIcon 
            className={STYLES.icon}
            title={item.clauseLabel}
            aria-hidden="true"
          />
        )}
        <span className="truncate">{item.text}</span>
      </button>

      {item.children?.length > 0 && (
        <ul className={STYLES.childList} role="menu">
          {item.children.map(child => (
            <TocItem 
              key={child.id} 
              item={child} 
              onHeadingClick={onHeadingClick}
              visibleHeadingPos={visibleHeadingPos}
            />
          ))}
        </ul>
      )}
    </li>
  )
})

const FullToc = memo(({ toc, onHeadingClick, onClose, visibleHeadingPos }) => {
  const tocRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tocRef.current && 
          !tocRef.current.contains(event.target) && 
          !event.target.closest('.toc-line-indicator')) {
        onClose()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [onClose])

  return (
    <div 
      ref={tocRef}
      className={STYLES.tocContainer}
      role="dialog"
      aria-label="Table of contents"
    >
      <nav className={STYLES.nav} aria-label="Document sections">
        <ul className={STYLES.tocList} role="menu">
          {toc.map(item => (
            <TocItem 
              key={item.id} 
              item={item} 
              onHeadingClick={onHeadingClick}
              visibleHeadingPos={visibleHeadingPos}
            />
          ))}
        </ul>
      </nav>
    </div>
  )
})

const TableOfContents = ({ toc, onHeadingClick, visibleHeadingPos }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  if (toc.length === 0) return null

  return (
    <>
      <LineIndicator 
        toc={toc} 
        onClick={() => setIsExpanded(!isExpanded)} 
      />
      {isExpanded && (
        <FullToc 
          toc={toc} 
          onHeadingClick={onHeadingClick}
          onClose={() => setIsExpanded(false)}
          visibleHeadingPos={visibleHeadingPos}
        />
      )}
    </>
  )
}

export default memo(TableOfContents) 