import { useState, useEffect, useCallback } from 'react'
import { generateDiffContent } from '../utils/diff'

/**
 * Custom hook for managing document versions and diff previews
 * 
 * @param {Object} editor - TipTap editor instance
 * @returns {Object} Version control methods and state
 */
export const useVersionControl = (editor) => {
  const [versions, setVersions] = useState([])
  const [currentVersion, setCurrentVersion] = useState(null)
  const [selectedVersion, setSelectedVersion] = useState(null)
  const [isPreviewingDiff, setIsPreviewingDiff] = useState(false)
  const [originalContent, setOriginalContent] = useState(null)

  // Initialize version history
  useEffect(() => {
    if (editor && !versions.length) {
      const initialVersion = {
        id: Date.now(),
        content: editor.getJSON(),
        timestamp: new Date(),
        label: 'Initial Version'
      }
      setVersions([initialVersion])
      setCurrentVersion(initialVersion.id)
    }
  }, [editor, versions.length])

  const saveVersion = useCallback(() => {
    if (editor && !isPreviewingDiff) {
      const newVersion = {
        id: Date.now(),
        content: editor.getJSON(),
        timestamp: new Date(),
        label: `Version ${versions.length + 1}`
      }
      setVersions(prev => [...prev, newVersion])
      setCurrentVersion(newVersion.id)
    }
  }, [editor, versions.length, isPreviewingDiff])

  const handleVersionSelect = useCallback((version) => {
    if (editor) {
      if (!isPreviewingDiff) {
        setOriginalContent(editor.getJSON())
      }

      const oldContent = originalContent || editor.getJSON()
      const newContent = version.content
      
      try {
        const diffContent = generateDiffContent(oldContent, newContent)
        console.log('Diff content:', diffContent) // For debugging
        
        editor.commands.setContent(diffContent)
        editor.setEditable(false)
        setSelectedVersion(version)
        setIsPreviewingDiff(true)
      } catch (error) {
        console.error('Error generating diff:', error)
        // Fallback to just showing the new version
        editor.commands.setContent(version.content)
      }
    }
  }, [editor, isPreviewingDiff, originalContent])

  const handleConfirmRestore = useCallback(() => {
    if (editor && selectedVersion) {
      // Save current state before restoring
      const currentState = {
        id: Date.now(),
        content: originalContent,
        timestamp: new Date(),
        label: `Version ${versions.length + 1} (pre-restore)`
      }
      setVersions(prev => [...prev, currentState])
      
      // Restore selected version
      editor.commands.setContent(selectedVersion.content)
      editor.setEditable(true)
      setCurrentVersion(selectedVersion.id)
      setSelectedVersion(null)
      setIsPreviewingDiff(false)
      setOriginalContent(null)
    }
  }, [editor, selectedVersion, versions.length, originalContent])

  const handleCancelRestore = useCallback(() => {
    if (editor && originalContent) {
      editor.commands.setContent(originalContent)
      editor.setEditable(true)
      setSelectedVersion(null)
      setIsPreviewingDiff(false)
      setOriginalContent(null)
    }
  }, [editor, originalContent])

  return {
    versions,
    currentVersion,
    selectedVersion,
    isPreviewingDiff,
    saveVersion,
    handleVersionSelect,
    handleConfirmRestore,
    handleCancelRestore
  }
} 