import { Controller } from "@hotwired/stimulus"
import hotkeys from 'hotkeys-js';

/*
Scopes, there can only be one active scope besides 'all'.

We've four scopes(at the moment):
 - Global for navigation shortcuts
 - '-g' which we use to activate the navigation sequences, i.e. ('g a')

Scopes are used to activate or desactivate shortcuts in different contexts. We don't need to have modal shortcuts active when no modal exists, the same for comments. And when we write, we don't need to have the navigation shortcuts active either.

To enable key combinations like 'g i', we need three small functions that we use, extracted from this issue: https://github.com/jaywcjlove/hotkeys/issues/26

Now we have sequences with g, but as I enable other combinations with the 'j' key, i.e. ('j p', 'j g')

First shortcut for the j key:

hotkeys('j', 'global', function(event, handler) {
  //We set the scope for combinations with j
  hotkeys.setScope('j-')
  chord.handler = handler
  chord.timeout = setTimeout(function() {
  //If the user has not used the combination we reset the scope
   resetScope()
  }, 2000)
})

//The p key will only work in the j- scope, which is activated when the j key is used.
hotkeys('p', 'j-', function(event) {
  //Your function only works if user press 'j p'
});

hotkeys('g', 'j-', function(event) {
  //Your function only works if user press 'j g'
});

//Function to reset the scope
const resetScope = function() {
  hotkeys.setScope(SCOPES.ALL)
  clearTimeout(chord.timeout)
  chord.handler = null
  chord.timeout = null
}
*/

const SCOPES = {
  MODAL: 'modal',
  COMBINATION_G: 'g-',
  ALL: 'all'
}

const htmlTags = ['TEXTAREA', 'INPUT']
const TIMEOUT_UNTIL_USER_PRESS_ANOTHER_KEY = 2000

// Helpers function to enable sequences
// https://github.com/jaywcjlove/hotkeys/issues/26
const chord = {
  handler: null,
  timeout: null
}

// Helper to check if element is within TipTap editor
function isWithinTipTap(element) {
  return element.closest('.ProseMirror') !== null
}

function resetScope() {
  hotkeys.setScope(SCOPES.ALL)
  clearTimeout(chord.timeout)
  chord.handler = null
  chord.timeout = null
}

export default class extends Controller {

  connect() {
    this.initShortcuts();
  }

  disconnect() {
    hotkeys.deleteScope(SCOPES.MODAL)
  }

  initShortcuts() {
    // By default hotkeys are not enabled for INPUT SELECT TEXTAREA elements. Hotkeys.filter to return to the true shortcut
    // keys set to play a role, false shortcut keys set up failure. https://github.com/jaywcjlove/hotkeys#filter
    hotkeys.filter = function(event) {
      const { target } = event
      // Return false if element is within TipTap editor
      if (isWithinTipTap(target)) {
        return false
      }
      // Return false if element is contenteditable
      if (target.getAttribute('contenteditable') === 'true') {
        return false
      }
      return true
    }

    hotkeys('g', function(event, handler) {
      const { target: { tagName } } = event
      if (!htmlTags.includes(tagName)) {
        event.preventDefault()
        hotkeys.setScope(SCOPES.COMBINATION_G)
        chord.handler = handler
        chord.timeout = setTimeout(() => {
          resetScope()
        }, TIMEOUT_UNTIL_USER_PRESS_ANOTHER_KEY)
      }
    })

    hotkeys('i', SCOPES.COMBINATION_G, (event) => this._goToLocation(event, '/'));

    hotkeys('c', SCOPES.COMBINATION_G, (event) => this._goToLocation(event, '/control'));

    hotkeys('p', SCOPES.COMBINATION_G, (event) => this._goToLocation(event, '/plans'));

    hotkeys('t', SCOPES.COMBINATION_G, (event) => this._goToLocation(event, '/tasks'));

    hotkeys('e', SCOPES.COMBINATION_G, (event) => this._goToLocation(event, '/explorador/guardados'));

    hotkeys('esc', SCOPES.MODAL, function(event) {
      event.preventDefault()
      if (document.body.classList.contains('form-modal')) {
        window.dispatchEvent(new Event("shortcutEscape"))
      }
    });

    hotkeys('command+enter, ctrl+enter', SCOPES.MODAL, function(event) {
      event.preventDefault()
      const { target: { tagName } } = event
      if (document.body.classList.contains('form-modal') && tagName !== 'TEXTAREA') {
        window.dispatchEvent(new Event("shortcutSaveModal"))
        //Fire the event only if the target is a TEXTAREA
      } else if (tagName === 'TEXTAREA' && document.body.classList.contains('form-mentionable')) {
        window.dispatchEvent(new Event("shortcutPostComment"))
      }
    });

    hotkeys('e', function(event) {
      const { target: { tagName } } = event
      const editBtn = document.querySelector('.js-modal-edit-btn')
      if (!htmlTags.includes(tagName) && editBtn) {
        event.preventDefault()
        editBtn.click()
        hotkeys.setScope(SCOPES.MODAL)
      }
    });

    hotkeys.setScope(SCOPES.MODAL)

    hotkeys('b', function(event) {
      const { target: { tagName } } = event
      const searchElement = document.getElementById('global-search-input');

      if (!htmlTags.includes(tagName) && searchElement) {
        event.preventDefault();
        searchElement.focus();
      }
    });
  }

  _goToLocation(event, route) {
    event.preventDefault()
    const { target: { tagName } } = event

    if (!htmlTags.includes(tagName)) {
      window.location.href = route
      resetScope()
    }
  }

}
