import { Controller } from "@hotwired/stimulus";
import { loadPreferences } from "./user_preferences_controller";

/**
 * Tabs Controller
 *
 * Usage:
 *   1. Add an id to the container element
 *      e.g., <article id="control" data-controller="tabs">
 *   2. Add these attributes to tab links:
 *      - data-tabs-target="tabItem"
 *      - data-action="click->tabs#showTab"
 *      - data-tabs-controls-param="anchor_name"
 *   3. Add these attributes to tab contents:
 *      - id="anchor_name"
 *      - data-tabs-target="tabPanel"
 *
 * Options:
 *   - data-tabs-update-url-value="false" (don't update URL)
 *   - data-tabs-all-hidden-value="true" (all tabs hidden initially)
 *   - data-user-preferences="tabs:currentTab" (save the current tab via user_preferences_controller)
 *   - data-tabs-default-tab-value="tab1"
 *
 * Markup Example:
 *
 * <article id="control" data-controller="tabs">
 *   <nav>
 *     <a data-tabs-target="tabItem" data-action="click->tabs#showTab" data-tabs-controls-param="tab1">Tab 1</a>
 *     <a data-tabs-target="tabItem" data-action="click->tabs#showTab" data-tabs-controls-param="tab2">Tab 2</a>
 *   </nav>
 *
 *   <section>
 *     <div id="tab1" data-tabs-target="tabPanel">Content 1</div>
 *     <div id="tab2" data-tabs-target="tabPanel">Content 2</div>
 *   </section>
 * </article>
 */

export default class extends Controller {
  static targets = ["tabItem", "tabPanel"];
  static values = {
    currentTab: String,
    defaultTab: String,
    updateUrl: {
      type: Boolean,
      default: true,
    },
    allHidden: {
      type: Boolean,
      default: false,
    },
    activeClass: { type: String, default: "active" },
    inactiveClass: { type: String, default: "inactive" },
  };

  initialize() {
    loadPreferences(this);
  }

  connect() {
    // Show a warning when HTMLElement has no id tag
    if (this.updateUrlValue && !this.element.id) {
      return console.warn(
        "Add an id to this HTMLElement in order to update the URL",
        this.element
      );
    }

    // a11y
    this.tabItemTargets.forEach((tab) => {
      tab.role = "tab";
      tab.setAttribute("aria-controls", tab.dataset.tabsControlsParam);
    });
    this.tabPanelTargets.forEach((tab) => (tab.role = "tabpanel"));

    this._initTabs();

    // Listen for sidebar-tabs:activate event
    this.boundHandleTabActivation = this._handleTabActivation.bind(this)
    window.addEventListener('sidebar-tabs:activate', this.boundHandleTabActivation)

    // Listen to back button to handle it better
    window.addEventListener("popstate", this._handlePopState.bind(this))
  }

  disconnect() {
    window.removeEventListener('sidebar-tabs:activate', this.boundHandleTabActivation)
    window.removeEventListener("popstate", this._handlePopState.bind(this))
  }

  showTab(event = {}, activeTab = "") {
    const { type, params } = event;

    if (type === "click") {
      event.preventDefault();
    }

    this.currentTabValue = type === "click" ? params.controls : activeTab;

    if (this.allHiddenValue) {
      // Toggle the active panel visibility
      this.tabPanelTargets.forEach((element) => {
        if (element.id === this.currentTabValue) {
          element.hidden = !element.hidden;
        } else {
          element.hidden = true;
        }
      });
    } else {
      // Toggle the active panel
      this.tabPanelTargets.forEach((element) => {
        element.hidden = element.id !== this.currentTabValue;
      });
    }

    // Toggle the active tab
    this.tabItemTargets.forEach((tab) =>
      tab.setAttribute(
        "aria-selected",
        tab.dataset.tabsControlsParam === this.currentTabValue
      )
    );

    if (this.updateUrlValue) {
      this._setUpdateUrl();
    }

    if (document.getElementById(`${this.element.id}_tab`)) {
      document.getElementById(`${this.element.id}_tab`).value =
        this.currentTabValue;
    }
  }

  // Private methods

  _handleTabActivation(event) {
    this.showTab({}, event.detail.tab)
  }

  _setUpdateUrl() {
    const queryParams = new URLSearchParams(window.location.search);
    const oldTab = queryParams.get(`${this.element.id}_tab`);
    queryParams.set(`${this.element.id}_tab`, this.currentTabValue);

    // Only create a new entry in history if it's a different tab
    if (oldTab !== this.currentTabValue) {
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;

      // Add new entry to history
      history.pushState(
        {
          turbo: {},
          tab: this.currentTabValue,
          url: newUrl
        },
        '',
        newUrl
      );

      // Dispatch event for Turbo to know about the change
      const event = new CustomEvent('turbo:load', {
        detail: { url: newUrl }
      });
      window.dispatchEvent(event);
    }
  }

  _showTabSelected(hash) {
    const tab = this.tabItemTargets.find((target) =>
      target.dataset.tabsControlsParam.includes(hash)
    );
    tab && this.showTab(tab, hash);
  }

  _initTabs() {
    // if the URL contains a hash
    const activeTab = new URL(window.location.href).searchParams.get(
      `${this.element.id}_tab`
    );
    if (activeTab) {
      return this._showTabSelected(activeTab);
    }

    // if the tab is explicitly set by the arguments (values)
    if (this.hasCurrentTabValue) {
      return this._showTabSelected(this.currentTabValue);
    }

    // if the tab is specified by data-tabs-default-tab-value
    const defaultTab = this.element.dataset.tabsDefaultTabValue;
    if (defaultTab) {
      return this._showTabSelected(defaultTab);
    }

    // If there are no tabs, do nothing
    if(this.tabPanelTargets.length == 0) {
      return
    }

    // otherwise, the first tab is shown.
    return this.showTab(this.tabPanelTargets[0], this.tabPanelTargets[0].id);
  }

  _handlePopState(event) {
    const queryParams = new URLSearchParams(window.location.search);
    const tabFromUrl = queryParams.get(`${this.element.id}_tab`);

    if (tabFromUrl) {
      this._showTabSelected(tabFromUrl);
    }
  }

  // Activate a tab by clicking on it
  activate(event) {
    event.preventDefault()
    this.activateTab(event.currentTarget)
  }

  // Activate a tab by its parameter
  activateTabByParam(param) {
    const tabItem = this.tabItemTargets.find(item => item.dataset.tabsControlsParam === param)
    if (tabItem) {
      this.activateTab(tabItem)
    }
  }

  // Activate a tab
  activateTab(tabItem) {
    // Get the tab panel to activate
    const tabPanel = this.tabPanelTargets.find(panel => panel.id === tabItem.dataset.tabsControlsParam)

    if (!tabPanel) {
      return
    }

    // Deactivate all tabs
    this.tabItemTargets.forEach(item => {
      item.classList.remove(this.activeClassValue)
      item.classList.add(this.inactiveClassValue)
      item.setAttribute("aria-selected", "false")
    })

    // Deactivate all panels
    this.tabPanelTargets.forEach(panel => {
      panel.classList.remove(this.activeClassValue)
      panel.classList.add(this.inactiveClassValue)
      panel.setAttribute("aria-hidden", "true")
    })

    // Activate the selected tab
    tabItem.classList.add(this.activeClassValue)
    tabItem.classList.remove(this.inactiveClassValue)
    tabItem.setAttribute("aria-selected", "true")

    // Activate the selected panel
    tabPanel.classList.add(this.activeClassValue)
    tabPanel.classList.remove(this.inactiveClassValue)
    tabPanel.setAttribute("aria-hidden", "false")

    // Dispatch a custom event
    this.element.dispatchEvent(
      new CustomEvent("tabs:change", {
        detail: {
          tabId: tabPanel.id,
          tabItem: tabItem,
          tabPanel: tabPanel
        }
      })
    )
  }
}
