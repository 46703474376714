// Import existing extensions
import EditorCommands from './editor-commands'
import LlmCommands from './llm-commands'

// Export all extensions
export default [
  EditorCommands,
  LlmCommands,
  // Add other extensions here
]

// Export the LLM commands extension
// Note: This file doesn't need to export all extensions
// as they are created in useEditorSetup.js
export { LlmCommands } 